var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fields.id
    ? _c(
        "div",
        {
          staticClass: "tab-pane fade",
          attrs: {
            "aria-labelledby": "captain-tab",
            id: "captain",
            role: "tabpanel"
          }
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("input-text", {
                attrs: { options: { id: "id", label: "id", disabled: true } },
                model: {
                  value: _vm.fields.id,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "id", $$v)
                  },
                  expression: "fields.id"
                }
              }),
              _c("input-text", {
                attrs: {
                  options: { id: "name", label: "name", disabled: true }
                },
                model: {
                  value: _vm.fields.name,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "name", $$v)
                  },
                  expression: "fields.name"
                }
              }),
              _c("input-text", {
                attrs: {
                  options: { id: "email", label: "email", disabled: true }
                },
                model: {
                  value: _vm.fields.email,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "email", $$v)
                  },
                  expression: "fields.email"
                }
              }),
              _c("input-text", {
                attrs: {
                  options: {
                    id: "mobile_number",
                    label: "mobile_number",
                    disabled: true
                  }
                },
                model: {
                  value: _vm.fields.mobileNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "mobileNumber", $$v)
                  },
                  expression: "fields.mobileNumber"
                }
              }),
              _c("input-text", {
                attrs: {
                  options: {
                    id: "id_number",
                    transPrefix: "captains.fields",
                    label: "id_number",
                    disabled: true
                  }
                },
                model: {
                  value: _vm.fields.idNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "idNumber", $$v)
                  },
                  expression: "fields.idNumber"
                }
              }),
              _c("select-captain-id-type", {
                attrs: {
                  options: {
                    id: "id_type",
                    transPrefix: "captains.fields",
                    label: "id_type",
                    disabled: true
                  }
                },
                model: {
                  value: _vm.fields.idType,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "idType", $$v)
                  },
                  expression: "fields.idType"
                }
              }),
              _c("select-captain-status", {
                attrs: {
                  options: { id: "status", label: "status", disabled: true }
                },
                model: {
                  value: _vm.fields.status,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "status", $$v)
                  },
                  expression: "fields.status"
                }
              }),
              _c("select-captain-working-status", {
                attrs: {
                  options: {
                    id: "working_status",
                    transPrefix: "captains.fields",
                    label: "working_status",
                    disabled: true
                  }
                },
                model: {
                  value: _vm.fields.workingStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "workingStatus", $$v)
                  },
                  expression: "fields.workingStatus"
                }
              }),
              _c("input-checkbox", {
                attrs: {
                  options: {
                    id: "ready_to_work",
                    transPrefix: "captains.fields",
                    label: "ready_to_work",
                    disabled: true
                  }
                },
                model: {
                  value: _vm.fields.readyToWork,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "readyToWork", $$v)
                  },
                  expression: "fields.readyToWork"
                }
              }),
              _c("input-checkbox", {
                attrs: {
                  options: {
                    id: "connected",
                    transPrefix: "captains.fields",
                    label: "connected",
                    disabled: true
                  }
                },
                model: {
                  value: _vm.fields.connected,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "connected", $$v)
                  },
                  expression: "fields.connected"
                }
              }),
              _c("input-checkbox", {
                attrs: {
                  options: {
                    id: "connected",
                    transPrefix: "captains.fields",
                    label: "suspended",
                    disabled: true
                  }
                },
                model: {
                  value: _vm.fields.suspended,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "suspended", $$v)
                  },
                  expression: "fields.suspended"
                }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }