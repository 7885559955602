var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-container" }, [
    _c("div", { staticClass: "chat-header" }, [
      _c("div", { staticClass: "status" }, [
        _c("span", { staticClass: "timer" }, [
          _vm._v(_vm._s(_vm.ticket.createdAt))
        ]),
        _c("span", { staticClass: "active" }, [
          _vm._v(_vm._s(_vm.ticket.status))
        ])
      ]),
      _c("div", { staticClass: "info" }, [
        _c("span", { staticClass: "message-id" }, [
          _vm._v(_vm._s(_vm.ticket.id))
        ]),
        _c("span", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.ticket.assigneeName))
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "chat-body", attrs: { id: "scrollable-container" } },
      _vm._l(_vm.messages, function(message, index) {
        return _c(
          "div",
          {
            key: index,
            class: ["message", message.sender === "You" ? "you" : "other"]
          },
          [
            _c("div", { staticClass: "sender" }, [
              message.sender !== "You"
                ? _c("span", [
                    _vm._v(" " + _vm._s(message.userName) + " "),
                    _c("span", { staticClass: "message-id" }, [
                      _vm._v(_vm._s(message.id))
                    ])
                  ])
                : _c("span", [_vm._v(_vm._s(message.userName))])
            ]),
            _c("div", { staticClass: "message-row" }, [
              _c(
                "div",
                {
                  staticClass: "text",
                  class: {
                    "text-you": message.userId === _vm.getProfile.id,
                    "text-other": message.userId !== _vm.getProfile.id
                  }
                },
                [_vm._v(" " + _vm._s(message.text) + " ")]
              ),
              _c("div", { staticClass: "timestamp" }, [
                _vm._v(_vm._s(message.createdAt))
              ])
            ])
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "chat-input" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newMessage,
            expression: "newMessage"
          }
        ],
        staticClass: "input",
        attrs: { type: "text", placeholder: "Type your message here." },
        domProps: { value: _vm.newMessage },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.sendMessage($event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.newMessage = $event.target.value
          }
        }
      }),
      _c(
        "button",
        { staticClass: "chat-send-button", on: { click: _vm.sendMessage } },
        [_vm._v("✔")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }