var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("thead", { staticClass: "thead-light" }, [
    _c(
      "tr",
      _vm._l(_vm.headers, function(header) {
        return _c("th", [_vm._v(" " + _vm._s(_vm.$t(header.title)) + " ")])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }