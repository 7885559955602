var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("files-image", {
                      attrs: { options: { id: "image", disabled: true } },
                      model: {
                        value: _vm.image,
                        callback: function($$v) {
                          _vm.image = $$v
                        },
                        expression: "image"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-email", {
                      attrs: {
                        options: { id: "email", label: "email", disabled: true }
                      },
                      model: {
                        value: _vm.email,
                        callback: function($$v) {
                          _vm.email = $$v
                        },
                        expression: "email"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: { id: "name", label: "name", disabled: true }
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }