var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("searchContainer", {
    attrs: { searchHandler: _vm.search, resetHandler: _vm.reset },
    scopedSlots: _vm._u([
      {
        key: "items",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("input-number", {
                  attrs: {
                    options: { id: "wallet_id", step: 1, label: "wallet_id" }
                  },
                  model: {
                    value: _vm.filters.wallet_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "wallet_id", $$v)
                    },
                    expression: "filters.wallet_id"
                  }
                }),
                _c("input-number", {
                  attrs: {
                    options: { id: "owner_id", step: 1, label: "owner_id" }
                  },
                  model: {
                    value: _vm.filters.owner_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "owner_id", $$v)
                    },
                    expression: "filters.owner_id"
                  }
                }),
                _c("select-wallet-owner-types", {
                  attrs: {
                    options: {
                      id: "wallet_owner_types",
                      label: "owner_type",
                      placeholder: "placeholders.all",
                      multiple: true
                    }
                  },
                  model: {
                    value: _vm.filters.owner_types,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "owner_types", $$v)
                    },
                    expression: "filters.owner_types"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("input-number", {
                  attrs: {
                    options: { id: "balance_from", label: "balance_from" }
                  },
                  model: {
                    value: _vm.filters.balance_from,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "balance_from", $$v)
                    },
                    expression: "filters.balance_from"
                  }
                }),
                _c("input-number", {
                  attrs: { options: { id: "balance_to", label: "balance_to" } },
                  model: {
                    value: _vm.filters.balance_to,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "balance_to", $$v)
                    },
                    expression: "filters.balance_to"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }