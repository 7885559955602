var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fields.backend_id
    ? _c(
        "div",
        {
          staticClass: "tab-pane fade",
          attrs: {
            "aria-labelledby": "customers-tab",
            id: "customer",
            role: "tabpanel"
          }
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("input-text", {
                attrs: {
                  options: {
                    id: "backend_id",
                    transPrefix: _vm.transPrefix + ".fields",
                    label: "customer_id",
                    disabled: true
                  }
                },
                model: {
                  value: _vm.fields.backend_id,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "backend_id", $$v)
                  },
                  expression: "fields.backend_id"
                }
              }),
              _c("input-text", {
                attrs: {
                  options: { id: "name", label: "name", disabled: true }
                },
                model: {
                  value: _vm.fields.name,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "name", $$v)
                  },
                  expression: "fields.name"
                }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }