<template>
  <div>
    <tracking />
    <active-orders></active-orders>
    <awaitng-assign-orders></awaitng-assign-orders>
    <assignment-panel></assignment-panel>
    <counters></counters>
    <controls></controls>
    <ticketsView></ticketsView>
    <chatView></chatView>
  </div>
</template>
<script>
import tracking from "./partials/tracking";
import activeOrders from "./partials/activeOrders";
import awaitngAssignOrders from "./partials/awaitngAssignOrders";
import assignmentPanel from "./partials/assignmentPanel";
import counters from "./partials/counters";
import controls from "./partials/controls";
import ticketsView from './partials/ticketsView';
import chatView from './partials/chatView.vue';
import $ from "jquery";

export default {
  name: "TrackingIndex",
  components: {
    tracking,
    activeOrders,
    awaitngAssignOrders,
    assignmentPanel,
    counters,
    controls,
    ticketsView,
    chatView,
  },
  mounted() {
    $(".footer").hide();
  }
};
</script>
