var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c(
            "form",
            {
              attrs: { role: "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.update($event)
                }
              }
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _vm.serverError
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.serverError) + " ")
                        ])
                      : _vm._e()
                  ])
                ]),
                !_vm.serverError
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("files-single", {
                          attrs: {
                            options: {
                              id: "contract",
                              transPrefix: _vm.transPrefix + ".fields",
                              label: "contract",
                              error: _vm.fieldErrors.contract,
                              disabled: !_vm.can(_vm.permissionPrefix, "edit"),
                              containerClass: "col-12 col-md-4"
                            }
                          },
                          model: {
                            value: _vm.contract,
                            callback: function($$v) {
                              _vm.contract = $$v
                            },
                            expression: "contract"
                          }
                        }),
                        _c("files-single", {
                          attrs: {
                            options: {
                              id: "cr",
                              transPrefix: _vm.transPrefix + ".fields",
                              label: "cr",
                              error: _vm.fieldErrors.cr,
                              disabled: !_vm.can(_vm.permissionPrefix, "edit"),
                              containerClass: "col-12 col-md-4"
                            }
                          },
                          model: {
                            value: _vm.cr,
                            callback: function($$v) {
                              _vm.cr = $$v
                            },
                            expression: "cr"
                          }
                        }),
                        _c("files-single", {
                          attrs: {
                            options: {
                              id: "vat_certificate",
                              transPrefix: _vm.transPrefix + ".fields",
                              label: "vat_certificate",
                              error: _vm.fieldErrors.vatCertificate,
                              disabled: !_vm.can(_vm.permissionPrefix, "edit"),
                              containerClass: "col-12 col-md-4"
                            }
                          },
                          model: {
                            value: _vm.vat_certificate,
                            callback: function($$v) {
                              _vm.vat_certificate = $$v
                            },
                            expression: "vat_certificate"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "card-footer" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col text-right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-neutral btn-sm",
                          attrs: { to: { name: "platforms.index" } }
                        },
                        [_c("i", { staticClass: "fa fa-arrow-left" })]
                      ),
                      _vm.isItemFound()
                        ? _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "can",
                                  rawName: "v-can:edit",
                                  value: _vm.permissionPrefix,
                                  expression: "permissionPrefix",
                                  arg: "edit"
                                }
                              ],
                              staticClass: "btn btn-primary btn-sm",
                              attrs: { type: "submit", disabled: _vm.loading }
                            },
                            [
                              _vm.loading
                                ? _c("span", {
                                    staticClass:
                                      "spinner-border spinner-border-sm"
                                  })
                                : _vm._e(),
                              _c("i", { staticClass: "fa fa-save" })
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }