<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`,params:{id} }"  class="btn btn-primary btn-sm" v-can:edit="permissionPrefix">
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <files-image v-model="icon" :options="{ id: 'icon', disabled: true }"/>
            </div>
            <div class="row" v-if="!serverError">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', disabled: true }"/>
              <select-ticket-root-types v-model="type" :options="{ id: 'ticket_type', label: 'type', disabled: true }"/>
              <select-ticket-root-language-codes v-model="languageCode" :options="{ id: 'languageCode', label: 'language_code', disabled: true }"/>
            </div>
            <div class="row" v-if="!serverError">
              <input-checkbox v-model="enabled" :options="{ id: 'enabled', label: 'enabled', disabled: true }"/>
            </div>
            <category-subcategories-list v-if="!serverError" v-model="subcategories" :editable="false"/>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { TICKETS_CATEGORIES_SHOW as CRUD_ACTION_SHOW } from 'actions/tickets';
  import CategorySubcategoriesList from "@/views/tickets/categories/partials/subcategories.vue";
  const TICKETS_CODE="tickets";
  const CRUD_CODE=`${TICKETS_CODE}.categories`;
  const CRUD_PERMISSION_CODE=`${TICKETS_CODE}`;
  export default {
    name: "TicketCategoryShow",
    components: {CategorySubcategoriesList},
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: `${CRUD_PERMISSION_CODE}`,
        ticketsRoutePrefix: TICKETS_CODE,
        ticketsTransPrefix: TICKETS_CODE,
        id:this.$router.currentRoute.params.id,
        name: '',
        languageCode: '',
        type: '',
        enabled: false,
        serverError: '',
        icon:{
          file:'',
          thumbnail:'',
        },
        subcategories: []
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.subcategories = response.data.subcategories;
          this.type=response.data.type;
          this.languageCode=response.data.languageCode;
          this.name=response.data.name;
          this.enabled=response.data.enabled;
          this.icon.thumbnail=response.data.icon?response.data.icon.url:'';
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
