var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "a",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: { animation: false },
            expression: "{ animation: false}",
            modifiers: { hover: true }
          }
        ],
        staticClass: "btn btn-danger btn-sm",
        attrs: {
          "data-target": _vm.modalSelector,
          title: _vm.$t("labels.cancellation_reasons"),
          "data-toggle": "modal",
          href: "javascript:;"
        }
      },
      [_c("i", { staticClass: "fas fa-ban" })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }