var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("card-container", {
          attrs: {
            title: _vm.$router.currentRoute.meta.title,
            headers: _vm.tableHeaders,
            lastPage: _vm.lastPage,
            totalElements: _vm.totalElements,
            currentPage: _vm.getCurrentPage(),
            clickHandler: _vm.pagination
          },
          scopedSlots: _vm._u([
            {
              key: "card-header",
              fn: function() {
                return [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:add",
                          value: _vm.permissionPrefix,
                          expression: "permissionPrefix",
                          arg: "add"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".create" } }
                    },
                    [_c("i", { staticClass: "fa fa-plus" })]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "card-header-search",
              fn: function() {
                return [
                  _c("search", {
                    attrs: { listHandler: _vm.getList },
                    model: {
                      value: _vm.lastPage,
                      callback: function($$v) {
                        _vm.lastPage = $$v
                      },
                      expression: "lastPage"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "table-rows",
              fn: function() {
                return [
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      "tr",
                      { key: item.id },
                      [
                        _c("td", [_vm._v(" " + _vm._s(item.title) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.type) + " ")]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.languageCode || "N/A") + " ")
                        ]),
                        _c(
                          "td",
                          [
                            _c("input-checkbox", {
                              attrs: {
                                options: {
                                  id: "status",
                                  onChangeHandler: _vm.changeStatus,
                                  onChangeParams: {
                                    id: item.id,
                                    value: !item.enabled
                                  },
                                  fullLayout: false,
                                  disabled: !_vm.can(
                                    _vm.permissionPrefix,
                                    "edit"
                                  )
                                }
                              },
                              model: {
                                value: item.enabled,
                                callback: function($$v) {
                                  _vm.$set(item, "enabled", $$v)
                                },
                                expression: "item.enabled"
                              }
                            })
                          ],
                          1
                        ),
                        _c("td", { staticClass: "text-direction-ltr" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  item.createdAt,
                                  "Y-MM-DD hh:mm:ss A"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("table-item-actions", {
                          attrs: {
                            actions: _vm.tableItemActions,
                            params: { id: item.id }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  !_vm.items.length
                    ? _c("tr", { staticClass: "text-center" }, [
                        _c("td", { attrs: { colspan: "999" } }, [
                          _vm._v(_vm._s(_vm.$t("messages.no_result_found")))
                        ])
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }