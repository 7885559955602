var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "nav-item dropdown" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "dropdown-menu  dropdown-menu-right " },
      _vm._l(_vm.locales, function(locale) {
        return _c(
          "a",
          {
            key: locale.code,
            staticClass: "dropdown-item",
            class: { "active disabled": _vm.$i18n.locale === locale.code },
            attrs: { href: "javascript:;" },
            on: {
              click: function($event) {
                return _vm.changeLocale(locale.code)
              }
            }
          },
          [_vm._v(" " + _vm._s(locale.name) + " ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link px-3",
        attrs: {
          href: "#",
          role: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "fa fa-language" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }