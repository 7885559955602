var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c(
            "form",
            {
              attrs: { role: "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.update($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _vm.serverError
                        ? _c("div", { staticClass: "alert alert-danger" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                            ]),
                            _vm._v(" " + _vm._s(_vm.serverError) + " ")
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm.isItemFound()
                    ? _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("files-image", {
                            attrs: {
                              options: {
                                id: "icon",
                                label: "icon",
                                error: _vm.fieldErrors.icon
                              }
                            },
                            model: {
                              value: _vm.icon,
                              callback: function($$v) {
                                _vm.icon = $$v
                              },
                              expression: "icon"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isItemFound()
                    ? _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("input-text", {
                            attrs: {
                              options: {
                                id: "name",
                                label: "name",
                                error: _vm.fieldErrors.name
                              }
                            },
                            model: {
                              value: _vm.name,
                              callback: function($$v) {
                                _vm.name = $$v
                              },
                              expression: "name"
                            }
                          }),
                          _c("select-ticket-root-types", {
                            attrs: {
                              options: {
                                id: "type",
                                label: "type",
                                error: _vm.fieldErrors.type
                              }
                            },
                            model: {
                              value: _vm.type,
                              callback: function($$v) {
                                _vm.type = $$v
                              },
                              expression: "type"
                            }
                          }),
                          _c("select-ticket-root-language-codes", {
                            attrs: {
                              options: {
                                id: "languageCode",
                                label: "language_code",
                                error: _vm.fieldErrors.languageCode
                              }
                            },
                            model: {
                              value: _vm.languageCode,
                              callback: function($$v) {
                                _vm.languageCode = $$v
                              },
                              expression: "languageCode"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isItemFound()
                    ? _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("input-checkbox", {
                            attrs: {
                              options: {
                                id: "enabled",
                                label: "enabled",
                                error: _vm.fieldErrors.enabled
                              }
                            },
                            model: {
                              value: _vm.enabled,
                              callback: function($$v) {
                                _vm.enabled = $$v
                              },
                              expression: "enabled"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("category-subcategories-list", {
                    attrs: { editable: true, fieldErrors: _vm.fieldErrors },
                    model: {
                      value: _vm.subcategories,
                      callback: function($$v) {
                        _vm.subcategories = $$v
                      },
                      expression: "subcategories"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "card-footer" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col text-right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-neutral btn-sm",
                          attrs: { to: { name: _vm.routePrefix + ".index" } }
                        },
                        [_c("i", { staticClass: "fa fa-arrow-left" })]
                      ),
                      _vm.isItemFound()
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-sm",
                              attrs: { type: "submit", disabled: _vm.loading }
                            },
                            [
                              _vm.loading
                                ? _c("span", {
                                    staticClass:
                                      "spinner-border spinner-border-sm"
                                  })
                                : _vm._e(),
                              _c("i", { staticClass: "fa fa-save" })
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }