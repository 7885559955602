import { render, staticRenderFns } from "./captain.vue?vue&type=template&id=359c5633&"
import script from "./captain.vue?vue&type=script&lang=js&"
export * from "./captain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/brisk-frontend/brisk-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('359c5633')) {
      api.createRecord('359c5633', component.options)
    } else {
      api.reload('359c5633', component.options)
    }
    module.hot.accept("./captain.vue?vue&type=template&id=359c5633&", function () {
      api.rerender('359c5633', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/platforms_portal/orders/tabs/captain.vue"
export default component.exports