<template>
  <div aria-labelledby="general-tab" class="tab-pane fade" id="general" role="tabpanel">
    <div class="row">
      <input-text v-model="fields.owner_id" :options="{ id: 'owner_id', label: 'owner_id', disabled: true }"/>
      <select-ticket-owner-types v-model="fields.owner_type" :options="{ id: 'ticket_owner_type', label: 'owner_type', disabled: true }"/>
      <input-text v-model="fields.related_id" :options="{ id: 'related_id', label: 'related_id', disabled: true }"/>
      <select-ticket-related-types v-model="fields.related_type" :options="{ id: 'ticket_related_type', label: 'related_type', disabled: true }"/>
      <input-text v-model="fields.created_at" :options="{ id: 'created_at', label: 'created_at', disabled: true }"/>
    </div>
  </div>
</template>
<script>
export default {
  name: "TicketTabsGeneral",
  props: {
    transPrefix: {
      type: String,
      default: ''
    },
    fields: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {}
  },
};
</script>
