var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "navbar-nav align-items-center" }, [
    _c("li", { staticClass: "nav-item dropdown" }, [
      _c(
        "a",
        {
          staticClass: "nav-link px-0",
          attrs: {
            href: "#",
            role: "button",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [
          _c("div", { staticClass: "media align-items-center" }, [
            _c("span", { staticClass: "avatar avatar-sm rounded-circle" }, [
              _c("img", {
                attrs: { alt: "Image placeholder", src: _vm.getProfileImage() }
              })
            ]),
            _c("div", { staticClass: "media-body  ml-2  d-none d-lg-block" }, [
              _c("span", { staticClass: "mb-0 text-sm  font-weight-bold" }, [
                _vm._v(_vm._s(_vm.getProfile.name))
              ])
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "dropdown-menu  dropdown-menu-right " },
        [
          _c("div", { staticClass: "dropdown-header noti-title" }, [
            _c("h6", { staticClass: "text-overflow m-0" }, [
              _vm._v(
                _vm._s(_vm.$t("labels.welcome")) +
                  " " +
                  _vm._s(_vm.getProfile.name)
              )
            ])
          ]),
          _c(
            "router-link",
            {
              staticClass: "dropdown-item ",
              attrs: { to: { name: "profile.me" } }
            },
            [
              _c("i", { staticClass: "ni ni-single-02" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("profile.title")))])
            ]
          ),
          _c("div", { staticClass: "dropdown-divider" }),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { href: "javascript:;" },
              on: { click: _vm.logout }
            },
            [
              _c("i", { staticClass: "ni ni-user-run" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("labels.logout")))])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }