var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "nav",
      {
        staticClass:
          "navbar navbar-vertical pt-7 navbar-expand-xs navbar-light pb-0 component-sidenav",
        attrs: { id: "sidenav-assignment-candidates-tracking" }
      },
      [
        _c("div", { staticClass: "scrollbar-inner bg-lighter" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-end bg-lighter position-fixed top-0 left-0 w-100"
            },
            [
              _c("div", { staticClass: "pt-3" }, [
                _c("div", { staticClass: "row text-center mx-0 w-100" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("tracking.assign")))])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "pt-2 mx-0 row flex-row-reverse w-100" },
                  [
                    _c("div", { staticClass: "col-4 p-0 text-right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "text-success p-2",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.assign()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-check" })]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "text-danger p-2",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.closeAssignmentPanel()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-times" })]
                      )
                    ]),
                    _c("div", { staticClass: "col-8" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-group position-relative mr--4 mt--1 mb-2",
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.assign()
                            }
                          }
                        },
                        [
                          _c("input-text", {
                            attrs: {
                              options: {
                                id: "selected_candidate_id",
                                fullLayout: false,
                                inputClass:
                                  "form-control form-control-sm border-primary border-2",
                                placeholder: "tracking.captain_id_placeholder"
                              }
                            },
                            model: {
                              value: _vm.selectedCandidateId,
                              callback: function($$v) {
                                _vm.selectedCandidateId = $$v
                              },
                              expression: "selectedCandidateId"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ])
            ]
          ),
          _c("div", { staticClass: "navbar-inner bg-lighter" }, [
            _c("div", { staticClass: "collapse navbar-collapse" }, [
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticClass:
                        "text-center position-fixed overflow-hidden bottom-0 top-0 mt-7 w-100 left-0 right-0 bg-lighter opacity-7 swal-overlay--show-modal"
                    },
                    [
                      _c("span", {
                        staticClass:
                          "spinner-border loader text-dark m-auto position-absolute top-0 right-0 left-0 bottom-0 force-right-0"
                      })
                    ]
                  )
                : _vm._e(),
              _c(
                "ul",
                { staticClass: "navbar-nav" },
                [
                  _vm._l(_vm.assignmentCandidates, function(candidate) {
                    return _c(
                      "li",
                      {
                        key: candidate.id,
                        staticClass:
                          "nav-item nav-link assignment-candidate-item mx-0 px-0",
                        attrs: { id: "captain_" + candidate.id },
                        on: {
                          mouseover: function($event) {
                            return _vm.setHighlightedCandidate(candidate, true)
                          },
                          mouseleave: function($event) {
                            return _vm.setHighlightedCandidate(candidate, false)
                          },
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.selectCandidate(candidate)
                          }
                        }
                      },
                      [
                        _c(
                          "assignment-candidate",
                          _vm._b({}, "assignment-candidate", candidate, false)
                        )
                      ],
                      1
                    )
                  }),
                  _vm.assignmentCandidates.length === 0
                    ? _c(
                        "li",
                        { staticClass: "nav-item nav-link  mx-0 px-0 row" },
                        [
                          _c("div", { staticClass: "col-12 text-center" }, [
                            _c("h2", { staticClass: "order-id" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("messages.no_result_found")) +
                                  " "
                              )
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }