var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    [
      _vm._l(_vm.actions, function(action) {
        return [
          action.code === "delete"
            ? _c("table-item-actions-delete", {
                attrs: { options: action, params: _vm.params }
              })
            : action.code === "show"
            ? _c("table-item-actions-show", {
                attrs: { options: action, params: _vm.params, query: _vm.query }
              })
            : action.code === "edit"
            ? _c("table-item-actions-edit", {
                attrs: { options: action, params: _vm.params, query: _vm.query }
              })
            : action.isPopUp
            ? _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      value: { animation: false },
                      expression: "{ animation: false}",
                      modifiers: { hover: true }
                    },
                    {
                      name: "can",
                      rawName: "v-can",
                      value: action.can,
                      expression: "action.can"
                    }
                  ],
                  staticClass: "px-1",
                  class: [action.color_class],
                  attrs: {
                    to: {
                      name: action.route_name,
                      params: Object.assign({}, _vm.params),
                      query: Object.assign({}, _vm.query)
                    },
                    title: _vm.$t(action.title ? action.title : "")
                  }
                },
                [_c("i", { class: [action.icon] })]
              )
            : _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      value: { animation: false },
                      expression: "{ animation: false}",
                      modifiers: { hover: true }
                    },
                    {
                      name: "can",
                      rawName: "v-can",
                      value: action.can,
                      expression: "action.can"
                    }
                  ],
                  staticClass: "px-1",
                  class: [action.color_class],
                  attrs: {
                    to: {
                      name: action.route_name,
                      params: Object.assign({}, _vm.params),
                      query: Object.assign({}, _vm.query)
                    },
                    title: _vm.$t(action.title ? action.title : "")
                  }
                },
                [_c("i", { class: [action.icon] })]
              )
        ]
      }),
      _vm._t("additional-items")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }