var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-responsive" }, [
    _c(
      "table",
      { staticClass: "table align-items-center table-flush" },
      [
        _c("table-header", { attrs: { headers: _vm.headers } }),
        _c("tbody", { staticClass: "list" }, [_vm._t("default")], 2)
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }