var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c(
            "form",
            {
              attrs: { role: "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.create($event)
                }
              }
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _vm.serverError
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.serverError) + " ")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("select-app-device-types", {
                      attrs: {
                        options: {
                          id: "app_device_type",
                          label: "type",
                          action: _vm.action,
                          error: _vm.fieldErrors.type
                        }
                      },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "name",
                          label: "name",
                          error: _vm.fieldErrors.name
                        }
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "bundle_id",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "bundle_id",
                          error: _vm.fieldErrors.bundleId
                        }
                      },
                      model: {
                        value: _vm.bundle_id,
                        callback: function($$v) {
                          _vm.bundle_id = $$v
                        },
                        expression: "bundle_id"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "firebase_api_key",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "firebase_api_key",
                          error: _vm.fieldErrors.firebaseApiKey
                        }
                      },
                      model: {
                        value: _vm.firebase_api_key,
                        callback: function($$v) {
                          _vm.firebase_api_key = $$v
                        },
                        expression: "firebase_api_key"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "card-footer" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col text-right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-neutral btn-sm",
                          attrs: { to: { name: _vm.routePrefix + ".index" } }
                        },
                        [_c("i", { staticClass: "fa fa-arrow-left" })]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { type: "submit", disabled: _vm.loading }
                        },
                        [
                          _vm.loading
                            ? _c("span", {
                                staticClass: "spinner-border spinner-border-sm"
                              })
                            : _vm._e(),
                          _c("i", { staticClass: "fa fa-save" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }