var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c(
            "card-header",
            { attrs: { title: _vm.$router.currentRoute.meta.title } },
            [
              !_vm.serverError
                ? _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:edit",
                          value: _vm.permissionPrefix,
                          expression: "permissionPrefix",
                          arg: "edit"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".edit" } }
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "legalName",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "legal_name",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.legalName,
                        callback: function($$v) {
                          _vm.legalName = $$v
                        },
                        expression: "legalName"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "legalNameAr",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "legal_name_ar",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.legalNameAr,
                        callback: function($$v) {
                          _vm.legalNameAr = $$v
                        },
                        expression: "legalNameAr"
                      }
                    }),
                    _c("input-email", {
                      attrs: {
                        options: {
                          id: "billingEmail",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "billing_email",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.billingEmail,
                        callback: function($$v) {
                          _vm.billingEmail = $$v
                        },
                        expression: "billingEmail"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "vatNumber",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "vat_number",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.vatNumber,
                        callback: function($$v) {
                          _vm.vatNumber = $$v
                        },
                        expression: "vatNumber"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "crNumber",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "cr_number",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.crNumber,
                        callback: function($$v) {
                          _vm.crNumber = $$v
                        },
                        expression: "crNumber"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "zipCode",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "zip_code",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.zipCode,
                        callback: function($$v) {
                          _vm.zipCode = $$v
                        },
                        expression: "zipCode"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "poBox",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "po_box",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.poBox,
                        callback: function($$v) {
                          _vm.poBox = $$v
                        },
                        expression: "poBox"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "addressLine1",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "address_line_1",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.addressLine1,
                        callback: function($$v) {
                          _vm.addressLine1 = $$v
                        },
                        expression: "addressLine1"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "addressLine2",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "address_line_2",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.addressLine2,
                        callback: function($$v) {
                          _vm.addressLine2 = $$v
                        },
                        expression: "addressLine2"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "invoiceDueDateAfter",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "invoice_due_date_after",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.invoiceDueDateAfter,
                        callback: function($$v) {
                          _vm.invoiceDueDateAfter = $$v
                        },
                        expression: "invoiceDueDateAfter"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }