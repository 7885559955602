var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "header bg-gradient-primary py-5 pb-6" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "header-body text-center mb-7" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-xl-5 col-lg-6 col-md-8 px-5 text-center" },
              [
                _c("h1", { staticClass: "text-white" }, [
                  _vm._v(_vm._s(_vm.$t("labels.welcome")))
                ]),
                _c("p", { staticClass: "text-lead text-white" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("auth.password.reset.welcome")) + " "
                  )
                ])
              ]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "separator separator-bottom separator-skew zindex-100" },
        [
          _c(
            "svg",
            {
              attrs: {
                x: "0",
                y: "0",
                viewBox: "0 0 2560 100",
                preserveAspectRatio: "none",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("polygon", {
                staticClass: "fill-default",
                attrs: { points: "2560 0 2560 100 0 100" }
              })
            ]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "container mt--8 pb-5" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-lg-5 col-md-7" }, [
          _c("div", { staticClass: "card bg-secondary border-0 mb-0" }, [
            _c("div", { staticClass: "card-body px-lg-5 py-lg-5" }, [
              _c("div", { staticClass: "text-center text-muted mb-4" }, [
                _c("small", [
                  _vm._v(_vm._s(_vm.$t("auth.password.reset.with_credentials")))
                ])
              ]),
              _c(
                "form",
                {
                  attrs: { role: "form" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.reset($event)
                    }
                  }
                },
                [
                  _vm.serverError
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                        ]),
                        _vm._v(" " + _vm._s(_vm.serverError) + " ")
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-group input-group-merge input-group-alternative"
                      },
                      [
                        _vm._m(0),
                        _c("input-password", {
                          attrs: {
                            options: {
                              id: "new_password",
                              fullLayout: false,
                              placeholder: "fields.new_password"
                            }
                          },
                          model: {
                            value: _vm.new_password,
                            callback: function($$v) {
                              _vm.new_password = $$v
                            },
                            expression: "new_password"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.fieldErrors.newPassword
                      ? _c(
                          "span",
                          {
                            staticClass: "invalid-feedback d-block",
                            attrs: { role: "alert" }
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.fieldErrors.newPassword))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-group input-group-merge input-group-alternative"
                      },
                      [
                        _vm._m(1),
                        _c("input-password", {
                          attrs: {
                            options: {
                              id: "new_password_confirmed",
                              fullLayout: false,
                              placeholder: "fields.new_password_confirmed"
                            }
                          },
                          model: {
                            value: _vm.new_password_confirmed,
                            callback: function($$v) {
                              _vm.new_password_confirmed = $$v
                            },
                            expression: "new_password_confirmed"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.fieldErrors.newPasswordConfirmed
                      ? _c(
                          "span",
                          {
                            staticClass: "invalid-feedback d-block",
                            attrs: { role: "alert" }
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.fieldErrors.newPasswordConfirmed)
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary my-4",
                        attrs: { type: "submit", disabled: _vm.loading }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("labels.submit")) + " "),
                        _vm.loading
                          ? _c("span", {
                              staticClass: "spinner-border spinner-border-sm"
                            })
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              )
            ])
          ]),
          _c("div", { staticClass: "row mt-3" }, [
            _c(
              "div",
              { staticClass: "col-6 text-left" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "text-light",
                    attrs: { to: { name: "auth.login" } }
                  },
                  [
                    _c("small", [
                      _vm._v(" " + _vm._s(_vm.$t("auth.login.title")))
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6 text-right" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "text-light",
                    attrs: { to: { name: "auth.password.forget" } }
                  },
                  [
                    _c("small", [
                      _vm._v(" " + _vm._s(_vm.$t("auth.password.forget.title")))
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "ni ni-lock-circle-open" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "ni ni-lock-circle-open" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }