var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-0 px-0 row" }, [
    _c("div", { staticClass: "col-6" }, [_c("h4", [_vm._v(_vm._s(_vm.id))])]),
    _c("div", { staticClass: "col-6 text-right" }, [
      _c(
        "span",
        {
          staticClass: "btn badge mb-2",
          class: {
            "bg-green text-white": _vm.getCaptainOrders.length === 0,
            "bg-yellow": _vm.getCaptainOrders.length === 1,
            "bg-orange text-white": _vm.getCaptainOrders.length > 1
          },
          staticStyle: { "font-size": "0.75rem" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.showHideCaptainOrders()
            }
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.getCaptainOrders.length === 0
                  ? _vm.$t(
                      _vm.captainTransPrefix + ".lists.working_status.FREE"
                    )
                  : _vm.$t(
                      _vm.captainTransPrefix + ".lists.working_status.BUSY"
                    ) +
                      " (" +
                      _vm.getCaptainOrders.length +
                      ")"
              ) +
              " "
          )
        ]
      )
    ]),
    _c("div", { staticClass: "col-6" }, [
      _c("span", [_vm._v(_vm._s(_vm.name))])
    ]),
    _c("div", { staticClass: "col-6 text-right" }, [
      _c("span", [
        _vm._v(
          _vm._s(_vm._f("twoDigitsFraction")(_vm.distanceToTargetPoint)) + " KM"
        )
      ])
    ]),
    _c("div", { staticClass: "col-12" }, [
      _c("span", [_vm._v(_vm._s(_vm.supplier))])
    ]),
    _vm.showOrders && _vm.getCaptainOrders.length > 0
      ? _c(
          "div",
          { staticClass: "mt-2 p-2 bg-lighter" },
          [
            _c("div", { staticClass: "col-12 text-center" }, [
              _c("span", [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$t(_vm.captainTransPrefix + ".fields.orders"))
                  )
                ])
              ])
            ]),
            _vm._l(_vm.getCaptainOrders, function(item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "assignment-candidate-order mx-0 px-0 row",
                  class: { "too-late": _vm.isTooLate(item) }
                },
                [
                  _c("div", { staticClass: "col-6" }, [
                    _c("h4", { staticClass: "order-id mb-0" }, [
                      _vm._v(_vm._s(item.backendId))
                    ])
                  ]),
                  _c("div", { staticClass: "col-6 text-right" }, [
                    _c(
                      "span",
                      {
                        staticClass: "payment-type badge pb-1",
                        class: {
                          "bg-danger text-white": item.paymentType == "PREPAID",
                          "bg-green text-white":
                            item.paymentType == "CASH_ON_DELIVERY"
                        }
                      },
                      [_vm._v(" " + _vm._s(item.paymentType) + " ")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "span",
                      {
                        staticClass: "status font-size-12",
                        style: { color: item.statusColor }
                      },
                      [_c("strong", [_vm._v(" " + _vm._s(item.status) + " ")])]
                    )
                  ]),
                  _c("div", { staticClass: "col-6 text-right" }, [
                    _c("span", { staticClass: "total-distance" }, [
                      _vm._v(" " + _vm._s(item.totalDistance) + " ")
                    ])
                  ]),
                  _c("div", { staticClass: "col-6 text-left" }, [
                    _c("span", { staticClass: "platform" }, [
                      _vm._v(" " + _vm._s(item.brand || item.platform) + " ")
                    ])
                  ]),
                  _c("div", { staticClass: "col-6 text-right" }, [
                    _c("span", { staticClass: "city" }, [
                      _vm._v(" " + _vm._s(item.city) + " ")
                    ])
                  ]),
                  _c("div", { staticClass: "col-12" }, [
                    _c("span", { staticClass: "created-at font-size-12" }, [
                      _c("span", { staticClass: "text-direction-ltr" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("moment")(
                                item.createdAt,
                                "Y-MM-DD hh:mm:ss A"
                              )
                            ) +
                            " "
                        )
                      ])
                    ])
                  ])
                ]
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }