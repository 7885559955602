var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "app_build",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "app_build",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.appBuild,
                        callback: function($$v) {
                          _vm.appBuild = $$v
                        },
                        expression: "appBuild"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "app_version",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "app_version",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.appVersion,
                        callback: function($$v) {
                          _vm.appVersion = $$v
                        },
                        expression: "appVersion"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "bundle_id",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "bundle_id",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.bundleId,
                        callback: function($$v) {
                          _vm.bundleId = $$v
                        },
                        expression: "bundleId"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "deviceManufacturer",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "manufacturer",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.deviceManufacturer,
                        callback: function($$v) {
                          _vm.deviceManufacturer = $$v
                        },
                        expression: "deviceManufacturer"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "deviceModel",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "device_model",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.deviceModel,
                        callback: function($$v) {
                          _vm.deviceModel = $$v
                        },
                        expression: "deviceModel"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "deviceType",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "device_type",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.deviceType,
                        callback: function($$v) {
                          _vm.deviceType = $$v
                        },
                        expression: "deviceType"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "language",
                          label: "language",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.language,
                        callback: function($$v) {
                          _vm.language = $$v
                        },
                        expression: "language"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "os",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "os",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.os,
                        callback: function($$v) {
                          _vm.os = $$v
                        },
                        expression: "os"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "opened_at",
                          label: "opened_at",
                          disabled: true,
                          inputClass:
                            "form-control text-direction-ltr text-left"
                        }
                      },
                      model: {
                        value: _vm.openedAt,
                        callback: function($$v) {
                          _vm.openedAt = $$v
                        },
                        expression: "openedAt"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-checkbox", {
                      attrs: {
                        options: {
                          id: "enableNotifications",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "enable_notifications",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.enableNotifications,
                        callback: function($$v) {
                          _vm.enableNotifications = $$v
                        },
                        expression: "enableNotifications"
                      }
                    }),
                    _c("input-checkbox", {
                      attrs: {
                        options: {
                          id: "locationPermissionAllowed",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "location_permission_allowed",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.locationPermissionAllowed,
                        callback: function($$v) {
                          _vm.locationPermissionAllowed = $$v
                        },
                        expression: "locationPermissionAllowed"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }