var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("select-envelope-type", {
                  attrs: {
                    options: {
                      id: "envelope_type",
                      label: "envelope_type",
                      containerClass: "col-12",
                      disabled: true
                    }
                  },
                  model: {
                    value: _vm.envelope_type,
                    callback: function($$v) {
                      _vm.envelope_type = $$v
                    },
                    expression: "envelope_type"
                  }
                })
              ],
              1
            ),
            !_vm.serverError && _vm.envelope_type === "ENVELOPE"
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("files-image", {
                      attrs: { options: { id: "image", disabled: true } },
                      model: {
                        value: _vm.image,
                        callback: function($$v) {
                          _vm.image = $$v
                        },
                        expression: "image"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.serverError && _vm.envelope_type
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("select-countries", {
                      attrs: {
                        options: {
                          id: "country_id",
                          label: "country",
                          placeholder: "placeholders.all",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.country_id,
                        callback: function($$v) {
                          _vm.country_id = $$v
                        },
                        expression: "country_id"
                      }
                    }),
                    _c("select-cities", {
                      attrs: {
                        country_id: _vm.country_id,
                        options: {
                          id: "city_id",
                          label: "city",
                          placeholder: "placeholders.all",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.city_id,
                        callback: function($$v) {
                          _vm.city_id = $$v
                        },
                        expression: "city_id"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.serverError && _vm.envelope_type === "ENVELOPE"
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-text", {
                      attrs: {
                        value: _vm.captain_name
                          ? _vm.captain_name
                          : _vm.$t("placeholders.all"),
                        options: {
                          id: "captain_name",
                          label: "captain",
                          disabled: true
                        }
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "language_code",
                          label: "language_code",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.language_code,
                        callback: function($$v) {
                          _vm.language_code = $$v
                        },
                        expression: "language_code"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.serverError && _vm.envelope_type
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "title",
                          label: "title",
                          containerClass: "col-12",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.title,
                        callback: function($$v) {
                          _vm.title = $$v
                        },
                        expression: "title"
                      }
                    }),
                    _c("input-textarea", {
                      attrs: {
                        options: {
                          id: "message",
                          label: "message",
                          disabled: true,
                          containerClass: "col-12"
                        }
                      },
                      model: {
                        value: _vm.message,
                        callback: function($$v) {
                          _vm.message = $$v
                        },
                        expression: "message"
                      }
                    }),
                    _c("input-checkbox", {
                      attrs: {
                        options: {
                          id: "enabled",
                          label: "enabled",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.enabled,
                        callback: function($$v) {
                          _vm.enabled = $$v
                        },
                        expression: "enabled"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }