var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("abstract-input-container", {
    attrs: { options: Object.assign({}, _vm.defaultOptions, _vm.options) },
    scopedSlots: _vm._u(
      [
        {
          key: "hint",
          fn: function() {
            return [_vm._t("hint")]
          },
          proxy: true
        }
      ],
      null,
      true
    ),
    model: {
      value: _vm.selectedValue,
      callback: function($$v) {
        _vm.selectedValue = $$v
      },
      expression: "selectedValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }