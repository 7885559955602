var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c(
            "card-header",
            { attrs: { title: _vm.$router.currentRoute.meta.title } },
            [
              !_vm.serverError && _vm.fintech_id
                ? _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      attrs: {
                        to: {
                          name: _vm.fintechsRoutePrefix + ".edit",
                          params: { fintech_id: _vm.fintech_id }
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "account_type",
                          transPrefix: _vm.fintechsTransPrefix + ".fields",
                          label: "account_type",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.account_type,
                        callback: function($$v) {
                          _vm.account_type = $$v
                        },
                        expression: "account_type"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "account_id",
                          transPrefix: _vm.fintechsTransPrefix + ".fields",
                          label: "account_id",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.account_id,
                        callback: function($$v) {
                          _vm.account_id = $$v
                        },
                        expression: "account_id"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }