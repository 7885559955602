var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("card-container", {
          attrs: {
            title: _vm.$router.currentRoute.meta.title,
            headers: _vm.tableHeaders,
            lastPage: _vm.lastPage,
            totalElements: _vm.totalElements,
            currentPage: _vm.getCurrentPage(),
            clickHandler: _vm.pagination
          },
          scopedSlots: _vm._u([
            {
              key: "card-header",
              fn: function() {
                return [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:report",
                          value: _vm.permissionPrefix,
                          expression: "permissionPrefix",
                          arg: "report"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm",
                      attrs: {
                        to: {
                          name:
                            _vm.routePrefix + ".dispatcher_performance_report"
                        }
                      }
                    },
                    [
                      _c("span", [
                        _c("i", { staticClass: "fa fa-file-export" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                _vm.transPrefix +
                                  ".dispatcher_performance_report"
                              )
                            )
                        )
                      ])
                    ]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "card-header-search",
              fn: function() {
                return [
                  _c("search", {
                    attrs: { listHandler: _vm.getList },
                    model: {
                      value: _vm.lastPage,
                      callback: function($$v) {
                        _vm.lastPage = $$v
                      },
                      expression: "lastPage"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "table-rows",
              fn: function() {
                return [
                  _vm._l(_vm.items, function(item) {
                    return _c("tr", { key: item.id }, [
                      _c("td", [_vm._v(" " + _vm._s(item.userId) + " ")]),
                      _c("td", [_vm._v(" " + _vm._s(item.userName) + " ")]),
                      _c("td", [_vm._v(" " + _vm._s(item.userRole) + " ")]),
                      _c("td", [_vm._v(" " + _vm._s(item.itemType) + " ")]),
                      _c("td", [_vm._v(" " + _vm._s(item.itemId) + " ")]),
                      _c("td", [_vm._v(" " + _vm._s(item.eventType) + " ")]),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              value: { animation: false },
                              expression: "{ animation: false}",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: { title: JSON.stringify(item.objectChanges) }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("shorten")(
                                  JSON.stringify(item.eventDetails),
                                  50
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("td", { staticClass: "text-direction-ltr" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("moment")(
                                item.eventAt,
                                "Y-MM-DD hh:mm:ss A"
                              )
                            ) +
                            " "
                        )
                      ])
                    ])
                  }),
                  !_vm.items.length
                    ? _c("tr", { staticClass: "text-center" }, [
                        _c("td", { attrs: { colspan: "999" } }, [
                          _vm._v(_vm._s(_vm.$t("messages.no_result_found")))
                        ])
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "card-footer",
              fn: function() {
                return [
                  _c("export-action", {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:report",
                        value: _vm.permissionPrefix,
                        expression: "permissionPrefix",
                        arg: "report"
                      }
                    ],
                    attrs: { crudCode: _vm.routePrefix }
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }