import {
    TICKETS_LIST as CRUD_ACTION_LIST,
    TICKETS_SHOW as CRUD_ACTION_SHOW,
    TICKETS_CREATE as CRUD_ACTION_CREATE,
    TICKETS_CLOSE as CRUD_ACTION_CLOSE,
    TICKETS_GET_ACTIVE_COUNT as CRUD_GET_ACTIVE_COUNT,
    TICKETS_REASSIGN as CRUD_ACTION_REASSIGN,
    TICKETS_OWNER_TYPE_LIST as CRUD_ACTION_OWNER_TYPE_LIST,
    TICKETS_STATUS_LIST as CRUD_ACTION_TICTET_STATUS_LLIST,
    TICKETS_RELATED_TYPE_LIST as CRUD_ACTION_RELATED_TYPE_LIST,
    TICKETS_REFERENCE_TYPE_LIST as CRUD_ACTION_REFERENCE_TYPE_LIST,
    TICKETS_CATEGORIES_LIST as CATEGORIES_CRUD_ACTION_LIST,
    TICKETS_CATEGORIES_SHOW as CATEGORIES_CRUD_ACTION_SHOW,
    TICKETS_CATEGORIES_CREATE as CATEGORIES_CRUD_ACTION_CREATE,
    TICKETS_CATEGORIES_UPDATE as CATEGORIES_CRUD_ACTION_UPDATE,
    TICKETS_CATEGORIES_DELETE as CATEGORIES_CRUD_ACTION_DELETE,
    TICKETS_CATEGORIES_UPDATE_STATUS as CATEGORIES_CRUD_ACTION_UPDATE_STATUS,
    TICKETS_CATEGORIES_TYPE_LIST as CATEGORIES_CRUD_ACTION_TYPE_LIST,
    TICKETS_CATEGORIES_LANGUAGE_CODE_LIST as CATEGORIES_CRUD_ACTION_LANGUAGE_CODE_LIST,
    TICKETS_CATEGORIES_SUBCATEGORIES_LIST as SUBCATEGORIES_CRUD_ACTION_LIST,
    TICKETS_CATEGORIES_SUBCATEGORIES_CREATE as SUBCATEGORIES_CRUD_ACTION_CREATE,
    TICKETS_CATEGORIES_SUBCATEGORIES_SHOW as SUBCATEGORIES_CRUD_ACTION_SHOW,
    TICKETS_CATEGORIES_SUBCATEGORIES_UPDATE as SUBCATEGORIES_CRUD_ACTION_UPDATE,
    TICKETS_CATEGORIES_SUBCATEGORIES_UPDATE_STATUS as SUBCATEGORIES_CRUD_ACTION_UPDATE_STATUS,
    TICKETS_COMMENTS_LIST,
    TICKETS_COMMENTS_CREATE,

} from "../actions/tickets";
import axioses from "@/axios/config";
import qs from "qs"
const additionalHeaders={
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};
const routePrefix='tickets';
const categoryRoutePrefix='ticket/categories';
const subcategoriesRoutePrefix='subcategories';
const commentsRoutePrefix='comments';

const state = {};

const getters = {};

const actions = {
  [CRUD_GET_ACTIVE_COUNT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/count/active`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_REASSIGN]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_CLOSE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.id}/close`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', null, { root: true });
          axioses.base
              .post(`/${routePrefix}`, params)
              .then(response => {
                  resolve(response);
                  commit('STOP_LOADING', null, { root: true });
              })
              .catch(error => {
                  commit('STOP_LOADING', null, { root: true });
                  reject(error);
              });
      });
  },
  [CRUD_ACTION_OWNER_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, {root: true});
        axioses.base
          .get(`/${routePrefix}/ownerTypes`, {params})
          .then(response => {
              resolve(response);
              commit('STOP_LOADING', params.withLoading, {root: true});
          })
          .catch(error => {
              commit('STOP_LOADING', params.withLoading, {root: true});
              reject(error);
          });
    });
  },
  [CRUD_ACTION_TICTET_STATUS_LLIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, {root: true});
        axioses.base
          .get(`/${routePrefix}/statuses`, {params})
          .then(response => {
              resolve(response);
              commit('STOP_LOADING', params.withLoading, {root: true});
          })
          .catch(error => {
              commit('STOP_LOADING', params.withLoading, {root: true});
              reject(error);
          });
    });
  },
  [CRUD_ACTION_RELATED_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, {root: true});
        axioses.base
            .get(`/${routePrefix}/relatedTypes`, {params})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, {root: true});
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, {root: true});
                reject(error);
            });
    });
  },
  [CRUD_ACTION_REFERENCE_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, {root: true});
        axioses.base
            .get(`/${routePrefix}/referenceTypes`, {params})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, {root: true});
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, {root: true});
                reject(error);
            });
    });
  },
  [CATEGORIES_CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${categoryRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CATEGORIES_CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          let fData= new FormData();
          Object.keys(params).forEach(key=>{
              if(typeof params[key] !== "undefined"  && params[key] !==null){
                  if(typeof params[key] == "object" && key !== "icon") {
                      for( let arrayIndex = 0; arrayIndex < params[key].length; arrayIndex++ )
                      {
                          let fieldName = key + "[" + arrayIndex + "]";
                          for ( let objectKey in params[key][arrayIndex]) {
                              fData.append(fieldName + "." + objectKey, params[key][arrayIndex][objectKey])
                          }
                      }
                  } else {
                      fData.append(key,params[key]);
                  }
              }
          });
          axioses.base
              .post(`/${categoryRoutePrefix}/create`, fData, additionalHeaders)
              .then(response => {
                  resolve(response);
              })
              .catch(error => {
                  reject(error);
              });
      });
  },
  [CATEGORIES_CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${categoryRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CATEGORIES_CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData= new FormData();
        let data = params.data;
        Object.keys(data).forEach(key=>{
            if(typeof data[key] !== "undefined"  && data[key] !==null){
                if(typeof data[key] == "object" && key !== "icon") {
                    for( let arrayIndex = 0; arrayIndex < data[key].length; arrayIndex++ )
                    {
                        let fieldName = key + "[" + arrayIndex + "]";
                        for ( let objectKey in data[key][arrayIndex]) {
                            fData.append(fieldName + "." + objectKey, data[key][arrayIndex][objectKey])
                        }
                    }
                } else {
                    fData.append(key,data[key]);
                }
            }
        });
        axioses.base
            .put(`/${categoryRoutePrefix}/${params.id}`, fData, additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CATEGORIES_CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${categoryRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CATEGORIES_CRUD_ACTION_UPDATE_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let statusList={false:'DISABLE',true:'ENABLE'};

        axioses.base
            .patch(`/${categoryRoutePrefix}/${params.id}/${statusList[params.value]}`, {...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CATEGORIES_CRUD_ACTION_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${categoryRoutePrefix}/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [CATEGORIES_CRUD_ACTION_LANGUAGE_CODE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${categoryRoutePrefix}/supported_languages`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [SUBCATEGORIES_CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${categoryRoutePrefix}/${params.ticketId}/${subcategoriesRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [SUBCATEGORIES_CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .post(`/${categoryRoutePrefix}/${params.ticketId}/${subcategoriesRoutePrefix}/create`, fData,additionalHeaders)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [SUBCATEGORIES_CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${categoryRoutePrefix}/${params.ticketId}/${subcategoriesRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUBCATEGORIES_CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });
        axioses.base
            .put(`/${categoryRoutePrefix}/${params.ticketId}/${subcategoriesRoutePrefix}/${params.id}`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUBCATEGORIES_CRUD_ACTION_UPDATE_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let statusList={false:'DISABLE',true:'ENABLE'};

        axioses.base
            .patch(`/${categoryRoutePrefix}/${params.ticketId}/${subcategoriesRoutePrefix}/${params.id}/${statusList[params.value]}`, {...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [TICKETS_COMMENTS_LIST]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', params.withLoading, { root: true });
          axioses.base
              .get(`/${routePrefix}/${params.ticketId}/${commentsRoutePrefix}`,{ params })
              .then(response => {
                  resolve(response);
                  commit('STOP_LOADING', params.withLoading, { root: true });
              })
              .catch(error => {
                  commit('STOP_LOADING', params.withLoading, { root: true });
                  reject(error);
              });
      });
  },
  [TICKETS_COMMENTS_CREATE]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          axioses.base
              .post(`/${routePrefix}/${params.ticketId}/${commentsRoutePrefix}`, params)
              .then(response => {
                  resolve(response);
              })
              .catch(error => {
                  reject(error);
              });
      });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
