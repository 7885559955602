var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c(
            "form",
            {
              attrs: { role: "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.create($event)
                }
              }
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _vm.serverError
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.serverError) + " ")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-date", {
                      attrs: {
                        options: {
                          id: "from_date",
                          label: "from_date",
                          error: _vm.fieldErrors.fromDate
                        }
                      },
                      model: {
                        value: _vm.from_date,
                        callback: function($$v) {
                          _vm.from_date = $$v
                        },
                        expression: "from_date"
                      }
                    }),
                    _c("input-date", {
                      attrs: {
                        options: {
                          id: "to_date",
                          label: "to_date",
                          error: _vm.fieldErrors.toDate
                        }
                      },
                      model: {
                        value: _vm.to_date,
                        callback: function($$v) {
                          _vm.to_date = $$v
                        },
                        expression: "to_date"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "row justify-content-end" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "card-title" }, [
                      _c("h3", { staticClass: "text-primary d-inline" }, [
                        _vm._v(_vm._s(_vm.$t("labels.add_items_to_invoice")))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-sm mx-2",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.addInvoiceItemRow($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("labels.add_new_item")) + " "
                        ),
                        _c("i", { staticClass: "fa fa-plus" })
                      ]
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "row card-body mb-5 pt-0" },
                  [
                    _vm._l(_vm.extraAmounts, function(invoiceItem, index) {
                      return [
                        _c("div", { staticClass: "col-12" }, [
                          _c("h3", { staticClass: "text-primary d-inline" }, [
                            _vm._v(
                              _vm._s(_vm.$t("labels.item")) +
                                " " +
                                _vm._s(index + 1)
                            )
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "text-danger mx-2",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeInvoiceItemRow(index)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-trash" })]
                          )
                        ]),
                        _c("input-text", {
                          attrs: {
                            options: {
                              id: "key_" + index,
                              label: "data",
                              containerClass: "col-12 col-md-10"
                            }
                          },
                          model: {
                            value: invoiceItem.key,
                            callback: function($$v) {
                              _vm.$set(invoiceItem, "key", $$v)
                            },
                            expression: "invoiceItem.key"
                          }
                        }),
                        _c("input-number", {
                          attrs: {
                            options: {
                              id: "value_" + index,
                              label: "amount",
                              containerClass: "col-12 col-md-2"
                            }
                          },
                          model: {
                            value: invoiceItem.value,
                            callback: function($$v) {
                              _vm.$set(invoiceItem, "value", $$v)
                            },
                            expression: "invoiceItem.value"
                          }
                        })
                      ]
                    })
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "card-footer" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col text-right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-neutral btn-sm",
                          attrs: {
                            to: {
                              name: _vm.routePrefix + ".index",
                              params: { id: _vm.id }
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-arrow-left" })]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { type: "submit", disabled: _vm.loading }
                        },
                        [
                          _vm.loading
                            ? _c("span", {
                                staticClass: "spinner-border spinner-border-sm"
                              })
                            : _vm._e(),
                          _c("i", { staticClass: "fa fa-save" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }