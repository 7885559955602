var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-header border-0" },
    [
      _c("div", { staticClass: "row justify-content-end" }, [
        _c("div", { staticClass: "col" }, [
          _c("h3", { staticClass: "mb-0" }, [
            _vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " "),
            _c("span", { staticClass: "text-warning" }, [
              _vm._v(_vm._s(_vm.$t(_vm.hint)))
            ])
          ])
        ]),
        _c("div", { staticClass: "col text-right" }, [_vm._t("default")], 2)
      ]),
      _vm._t("search")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }