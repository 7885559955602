var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("searchContainer", {
    attrs: { searchHandler: _vm.search, resetHandler: _vm.reset },
    scopedSlots: _vm._u([
      {
        key: "items",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("input-number", {
                  attrs: {
                    options: {
                      id: "captain_id",
                      step: 1,
                      transPrefix: _vm.transPrefix + ".fields",
                      label: "captain_id"
                    }
                  },
                  model: {
                    value: _vm.filters.captain_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "captain_id", $$v)
                    },
                    expression: "filters.captain_id"
                  }
                }),
                _c("input-text", {
                  attrs: { options: { id: "captain_name", label: "name" } },
                  model: {
                    value: _vm.filters.captain_name,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "captain_name", $$v)
                    },
                    expression: "filters.captain_name"
                  }
                }),
                _c("input-text", {
                  attrs: { options: { id: "captain_email", label: "email" } },
                  model: {
                    value: _vm.filters.captain_email,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "captain_email", $$v)
                    },
                    expression: "filters.captain_email"
                  }
                }),
                _c("input-text", {
                  attrs: {
                    options: {
                      id: "captain_mobile_number",
                      label: "mobile_number"
                    }
                  },
                  model: {
                    value: _vm.filters.captain_mobile_number,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "captain_mobile_number", $$v)
                    },
                    expression: "filters.captain_mobile_number"
                  }
                }),
                _c("input-text", {
                  attrs: {
                    options: {
                      id: "captain_id_number",
                      transPrefix: _vm.transPrefix + ".fields",
                      label: "id_number"
                    }
                  },
                  model: {
                    value: _vm.filters.captain_id_number,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "captain_id_number", $$v)
                    },
                    expression: "filters.captain_id_number"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }