export const TICKETS_LIST = "TICKETS_LIST";
export const TICKETS_SHOW = "TICKETS_SHOW";
export const TICKETS_CREATE = "TICKETS_CREATE";
export const TICKETS_CLOSE = "TICKETS_CLOSE";
export const TICKETS_REASSIGN = "TICKETS_REASSIGN"
export const TICKETS_GET_ACTIVE_COUNT = "TICKETS_GET_ACTIVE_COUNT";
export const TICKETS_OWNER_TYPE_LIST = "TICKETS_OWNER_TYPE_LIST";
export const TICKETS_STATUS_LIST = "TICKETS_STATUS_LIST";
export const TICKETS_RELATED_TYPE_LIST = "TICKETS_RELATED_TYPE_LIST";
export const TICKETS_REFERENCE_TYPE_LIST = "TICKETS_REFERENCE_TYPE_LIST";
export const TICKETS_CATEGORIES_LIST = "TICKETS_CATEGORIES_LIST";
export const TICKETS_CATEGORIES_CREATE = "TICKETS_CATEGORIES_CREATE";
export const TICKETS_CATEGORIES_UPDATE = "TICKETS_CATEGORIES_UPDATE";
export const TICKETS_CATEGORIES_DELETE = "TICKETS_CATEGORIES_DELETE";
export const TICKETS_CATEGORIES_SHOW = "TICKETS_CATEGORIES_SHOW";
export const TICKETS_CATEGORIES_TYPE_LIST = "TICKETS_CATEGORIES_TYPE_LIST";
export const TICKETS_CATEGORIES_LANGUAGE_CODE_LIST = "TICKETS_CATEGORIES_LANGUAGE_CODE_LIST";
export const TICKETS_CATEGORIES_UPDATE_STATUS= "TICKETS_CATEGORIES_UPDATE_STATUS";
export const TICKETS_CATEGORIES_SUBCATEGORIES_LIST = "TICKETS_CATEGORIES_SUBCATEGORIES_LIST";
export const TICKETS_CATEGORIES_SUBCATEGORIES_CREATE = "TICKETS_CATEGORIES_SUBCATEGORIES_CREATE";
export const TICKETS_CATEGORIES_SUBCATEGORIES_UPDATE = "TICKETS_CATEGORIES_SUBCATEGORIES_UPDATE";
export const TICKETS_CATEGORIES_SUBCATEGORIES_SHOW = "TICKETS_CATEGORIES_SUBCATEGORIES_SHOW";
export const TICKETS_CATEGORIES_SUBCATEGORIES_UPDATE_STATUS= "TICKETS_CATEGORIES_SUBCATEGORIES_UPDATE_STATUS";
export const TICKETS_COMMENTS_LIST = "TICKETS_COMMENTS_LIST";
export const TICKETS_COMMENTS_CREATE = "TICKETS_COMMENTS_CREATE";

