var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      attrs: {
        "aria-labelledby": "customers-tab",
        id: "customers",
        role: "tabpanel"
      }
    },
    [
      _vm._l(_vm.items, function(item) {
        return _c(
          "div",
          { key: item.id, staticClass: "row" },
          [
            _c("div", { staticClass: "col-12 font-weight-bold" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      _vm.transPrefix +
                        ".lists.customer_type." +
                        item.customerType
                    )
                  )
                )
              ])
            ]),
            _c("input-text", {
              attrs: {
                options: {
                  id: "backend_id",
                  transPrefix: _vm.transPrefix + ".fields",
                  label: "customer_id",
                  disabled: true
                }
              },
              model: {
                value: item.backendId,
                callback: function($$v) {
                  _vm.$set(item, "backendId", $$v)
                },
                expression: "item.backendId"
              }
            }),
            _c("input-text", {
              attrs: { options: { id: "name", label: "name", disabled: true } },
              model: {
                value: item.name,
                callback: function($$v) {
                  _vm.$set(item, "name", $$v)
                },
                expression: "item.name"
              }
            })
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("input-textarea", {
            attrs: {
              options: {
                id: "notes",
                transPrefix: _vm.transPrefix + ".fields",
                label: "notes",
                disabled: true,
                containerClass: "col-12"
              }
            },
            model: {
              value: _vm.notes,
              callback: function($$v) {
                _vm.notes = $$v
              },
              expression: "notes"
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }