var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("abstract-dropdown-container", {
    attrs: {
      items: _vm.includeFreelancers ? [_vm.freelancersItem] : [],
      options: Object.assign({}, _vm.defaultOptions, _vm.options)
    },
    scopedSlots: _vm._u(
      [
        {
          key: "hint-top",
          fn: function() {
            return [_vm._t("hint-top")]
          },
          proxy: true
        },
        {
          key: "hint-bottom",
          fn: function() {
            return [_vm._t("hint-bottom")]
          },
          proxy: true
        }
      ],
      null,
      true
    ),
    model: {
      value: _vm.selectedValue,
      callback: function($$v) {
        _vm.selectedValue = $$v
      },
      expression: "selectedValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }