var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "multiselect",
    {
      ref: "multiSelect",
      class: { "is-invalid": _vm.defaultOptions.error },
      attrs: {
        id: _vm.defaultOptions.id,
        disabled: _vm.defaultOptions.disabled,
        multiple: _vm.defaultOptions.multiple,
        "track-by": _vm.defaultOptions.keyField,
        options: _vm.showSelectAll
          ? [{ label: "labels.select_all", items: _vm.items }]
          : _vm.items,
        searchable: true,
        "custom-label": _vm.customLabel,
        "close-on-select": true,
        "show-labels": false,
        loading: _vm.defaultOptions.isLoading,
        "allow-empty": _vm.defaultOptions.allowEmpty,
        "hide-selected": _vm.defaultOptions.hideSelected,
        "group-values": _vm.groupValuesKey,
        "group-label": _vm.groupLabelKey,
        "group-select": _vm.showSelectAll,
        placeholder: _vm.$t(_vm.defaultOptions.placeholder)
      },
      on: {
        input: _vm.updateValue,
        select: _vm.onSelectHandler,
        remove: _vm.defaultOptions.onRemoveHandler
      },
      scopedSlots: _vm._u([
        {
          key: "option",
          fn: function(props) {
            return [
              _vm._v(
                _vm._s(
                  props.option.$isLabel
                    ? _vm.$t(props.option.$groupLabel)
                    : _vm.customLabel(props.option)
                )
              )
            ]
          }
        },
        {
          key: "tag",
          fn: function(ref) {
            var option = ref.option
            var remove = ref.remove
            return [
              _c(
                "span",
                {
                  staticClass: "multiselect__tag",
                  class: { disabled: _vm.defaultOptions.disabled }
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.customLabel(option)))]),
                  !_vm.defaultOptions.disabled
                    ? _c("i", {
                        staticClass: "multiselect__tag-icon",
                        attrs: { "aria-hidden": "true", tabindex: "1" },
                        on: {
                          click: function($event) {
                            return remove(option)
                          }
                        }
                      })
                    : _vm._e()
                ]
              )
            ]
          }
        },
        {
          key: "clear",
          fn: function(props) {
            return [
              !_vm.isEmpty(_vm.selectedValue) &&
              _vm.defaultOptions.allowEmpty &&
              !_vm.defaultOptions.disabled
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    on: {
                      mousedown: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.clearSelected(props.search)
                      }
                    }
                  })
                : _vm._e()
            ]
          }
        }
      ]),
      model: {
        value: _vm.selectedValue,
        callback: function($$v) {
          _vm.selectedValue = $$v
        },
        expression: "selectedValue"
      }
    },
    [
      _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
        _vm._v(_vm._s(_vm.$t(_vm.defaultOptions.noResultMessage)))
      ]),
      _c("template", { slot: "afterList" }, [
        _c("div", {
          directives: [
            {
              name: "observe-visibility",
              rawName: "v-observe-visibility",
              value: _vm.pagination,
              expression: "pagination"
            }
          ]
        })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }