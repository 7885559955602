var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "captain_id",
                          label: "captain_id",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.captain_id,
                        callback: function($$v) {
                          _vm.captain_id = $$v
                        },
                        expression: "captain_id"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "allocation_window_id",
                          label: "allocation_window_id",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.allocation_window_id,
                        callback: function($$v) {
                          _vm.allocation_window_id = $$v
                        },
                        expression: "allocation_window_id"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "earnings",
                          label: "earnings",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.earnings,
                        callback: function($$v) {
                          _vm.earnings = $$v
                        },
                        expression: "earnings"
                      }
                    }),
                    _c("select-allocation-window-statuses", {
                      attrs: {
                        options: {
                          id: "status",
                          label: "status",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "captain_name",
                          label: "captain_name",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.captain_name,
                        callback: function($$v) {
                          _vm.captain_name = $$v
                        },
                        expression: "captain_name"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "captain_mobile",
                          label: "captain_mobile",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.captain_mobile,
                        callback: function($$v) {
                          _vm.captain_mobile = $$v
                        },
                        expression: "captain_mobile"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "order_id",
                          label: "order_id",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.order_id,
                        callback: function($$v) {
                          _vm.order_id = $$v
                        },
                        expression: "order_id"
                      }
                    }),
                    _c("select-order-status", {
                      attrs: {
                        options: {
                          id: "order_status",
                          label: "order_status",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.order_status,
                        callback: function($$v) {
                          _vm.order_status = $$v
                        },
                        expression: "order_status"
                      }
                    }),
                    _c("input-datetime", {
                      attrs: {
                        options: {
                          id: "created_at",
                          label: "created_at",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.created_at,
                        callback: function($$v) {
                          _vm.created_at = $$v
                        },
                        expression: "created_at"
                      }
                    }),
                    _vm.expired_at
                      ? _c("input-datetime", {
                          attrs: {
                            options: {
                              id: "expired_at",
                              label: "expired_at",
                              disabled: true
                            }
                          },
                          model: {
                            value: _vm.expired_at,
                            callback: function($$v) {
                              _vm.expired_at = $$v
                            },
                            expression: "expired_at"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }