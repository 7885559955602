var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("files-image", {
                      attrs: { options: { id: "image", disabled: true } },
                      model: {
                        value: _vm.image,
                        callback: function($$v) {
                          _vm.image = $$v
                        },
                        expression: "image"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-text", {
                      attrs: {
                        options: { id: "name", label: "name", disabled: true }
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    }),
                    _c("select-supplier-types", {
                      attrs: {
                        options: {
                          id: "supplier_type",
                          label: "type",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    }),
                    _c("select-countries", {
                      attrs: {
                        options: {
                          id: "country_id",
                          label: "country",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.country_id,
                        callback: function($$v) {
                          _vm.country_id = $$v
                        },
                        expression: "country_id"
                      }
                    }),
                    _vm.needPlatformID()
                      ? _c("select-platforms", {
                          attrs: {
                            options: {
                              id: "platform_id",
                              label: "platform",
                              disabled: true
                            }
                          },
                          model: {
                            value: _vm.owner_id,
                            callback: function($$v) {
                              _vm.owner_id = $$v
                            },
                            expression: "owner_id"
                          }
                        })
                      : _vm._e(),
                    _vm.needBrands()
                      ? _c("select-platform-brands", {
                          attrs: {
                            platform_id: _vm.owner_id,
                            options: {
                              id: "brands",
                              label: "brands",
                              multiple: true,
                              disabled: true
                            }
                          },
                          model: {
                            value: _vm.brandIds,
                            callback: function($$v) {
                              _vm.brandIds = $$v
                            },
                            expression: "brandIds"
                          }
                        })
                      : _vm._e(),
                    _c("input-checkbox", {
                      attrs: {
                        options: {
                          id: "wallet_enabled",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "wallet_enabled",
                          disabled: true,
                          containerClass: "col-12 col-md-6 mt-5"
                        }
                      },
                      model: {
                        value: _vm.wallet_enabled,
                        callback: function($$v) {
                          _vm.wallet_enabled = $$v
                        },
                        expression: "wallet_enabled"
                      }
                    }),
                    _c("input-checkbox", {
                      attrs: {
                        options: {
                          id: "full_time_drivers",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "full_time_drivers",
                          disabled: true,
                          containerClass: "col-12 col-md-6 mt-5"
                        }
                      },
                      model: {
                        value: _vm.full_time_drivers,
                        callback: function($$v) {
                          _vm.full_time_drivers = $$v
                        },
                        expression: "full_time_drivers"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c("attach-captain-action", {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:add",
                        value: _vm.captainsPermissionPrefix,
                        expression: "captainsPermissionPrefix",
                        arg: "add"
                      }
                    ],
                    attrs: { itemID: _vm.id }
                  }),
                  _c("de-attach-captain-action", {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:add",
                        value: _vm.captainsPermissionPrefix,
                        expression: "captainsPermissionPrefix",
                        arg: "add"
                      }
                    ],
                    attrs: { itemID: _vm.id }
                  }),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }