var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        "aria-hidden": "true",
        "aria-labelledby": "modal-rejection-reasons",
        id: "modal-rejection-reasons",
        role: "dialog",
        tabindex: "-1"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered modal-lg",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h6",
                {
                  staticClass: "modal-title",
                  attrs: { id: "modal-title-default" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("labels.rejection_reasons")) + " ")]
              ),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "ul",
                  { staticClass: "list" },
                  _vm._l(_vm.rejectionReasons, function(reason, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(reason.reason) + " "),
                      _c(
                        "p",
                        {
                          staticStyle: { color: "#1496ba", "font-size": "12px" }
                        },
                        [_vm._v(" - " + _vm._s(reason.rejectedBy))]
                      )
                    ])
                  }),
                  0
                )
              ])
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary ml-auto",
                  attrs: { "data-dismiss": "modal", type: "button" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("labels.close")) + " ")]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }