var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("card-container", {
          attrs: {
            title: _vm.$router.currentRoute.meta.title,
            headers: _vm.tableHeaders,
            lastPage: _vm.lastPage,
            totalElements: _vm.totalElements,
            currentPage: _vm.getCurrentPage(),
            clickHandler: _vm.pagination
          },
          scopedSlots: _vm._u([
            {
              key: "card-header",
              fn: function() {
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      attrs: {
                        to: { name: _vm.platformsRoutePrefix + ".index" }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "table-rows",
              fn: function() {
                return [
                  _vm._l(_vm.items, function(item) {
                    return _c("tr", { key: item.id }, [
                      _c("td", [_vm._v(" " + _vm._s(item.id) + " ")]),
                      _c("td", [_vm._v(" " + _vm._s(item.backendId) + " ")]),
                      _c("td", [_vm._v(" " + _vm._s(item.name) + " ")]),
                      _c("td", [_vm._v(" " + _vm._s(item.phone) + " ")]),
                      _c(
                        "td",
                        [
                          _c("input-checkbox", {
                            attrs: {
                              options: { disabled: true, fullLayout: false }
                            },
                            model: {
                              value: item.isExternal,
                              callback: function($$v) {
                                _vm.$set(item, "isExternal", $$v)
                              },
                              expression: "item.isExternal"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  !_vm.items.length
                    ? _c("tr", { staticClass: "text-center" }, [
                        _c("td", { attrs: { colspan: "999" } }, [
                          _vm._v(_vm._s(_vm.$t("messages.no_result_found")))
                        ])
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }