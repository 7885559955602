var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: { animation: false },
          expression: "{ animation: false}",
          modifiers: { hover: true }
        }
      ],
      staticClass: "text-success px-1",
      attrs: { href: "javascript:;", title: _vm.$t("labels.accept_item") },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.acceptPopup(_vm.index, _vm.itemID)
        }
      }
    },
    [_c("i", { staticClass: "fas fa-check" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }