var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "a",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: { animation: false },
            expression: "{ animation: false}",
            modifiers: { hover: true }
          },
          {
            name: "can",
            rawName: "v-can",
            value: _vm.options.can,
            expression: "options.can"
          }
        ],
        staticClass: " px-1",
        class: [
          _vm.options.color_class ? _vm.options.color_class : "text-danger"
        ],
        attrs: {
          href: "javascript:;",
          title: _vm.$t(_vm.options.title ? _vm.options.title : "crud.delete")
        },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.showDeletePopUp()
          }
        }
      },
      [
        _c("i", {
          class: [_vm.options.icon ? _vm.options.icon : "fas fa-trash"]
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }