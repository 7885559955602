var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "header bg-gradient-primary py-9" }, [
      _c(
        "div",
        { staticClass: "separator separator-bottom separator-skew zindex-100" },
        [
          _c(
            "svg",
            {
              attrs: {
                x: "0",
                y: "0",
                viewBox: "0 0 2560 100",
                preserveAspectRatio: "none",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("polygon", {
                staticClass: "fill-default",
                attrs: { points: "2560 0 2560 100 0 100" }
              })
            ]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "header-body text-center mb-7" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-xl-12 col-lg-12 col-md-12 px-5" }, [
            _c(
              "h1",
              { staticClass: "text-lead text-white" },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("page_unauthorized.title")) + " 403. "
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-white-50",
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.logout }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("labels.logout")))])]
                ),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("labels.or")) +
                    " " +
                    _vm._s(_vm.$t("labels.click")) +
                    " "
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "text-white-50",
                    attrs: { to: { name: "auth.login" } }
                  },
                  [_vm._v(_vm._s(_vm.$t("labels.here")))]
                ),
                _vm._v(" " + _vm._s(_vm.$t("page_not_found.go_to_home")) + ". ")
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }