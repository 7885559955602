<template>
  <div class="map-controls-container">
    <div class="row alert bg-light opacity-8 m-0 p-2 font-size-20" v-bind:class="{ 'controls-disabled': getActiveOrder.id }">
      <div class="col px-2 text-dark">
        <a href="javascript;" @click.prevent="captainSearchOpened = !captainSearchOpened" v-bind:class="{
            'text-blue': captainSearchOpened,
            'text-dark': !captainSearchOpened
          }" v-b-tooltip.hover.bottom="{ animation: false}" :title="$t('labels.find_captain')">
          <i class="fa fa-search-location"></i>
        </a>
      </div>
      <div class="col px-2 text-dark">
        <a href="javascript;" @click.prevent="mapConfig.ticketsEnabled = !mapConfig.ticketsEnabled" v-bind:class="{
            'text-blue': mapConfig.ticketsEnabled,
            'text-dark': !mapConfig.ticketsEnabled
          }" v-b-tooltip.hover.bottom="{ animation: false}" :title="$t('labels.tickets')">
          <i class="fa fa-comments"></i>
        </a>
      </div>
      <div class="col px-2">
        <a href="javascript;" @click.prevent="mapConfig.awaitingOrdersEnabled = !mapConfig.awaitingOrdersEnabled" v-bind:class="{
            'text-blue': mapConfig.awaitingOrdersEnabled,
            'text-dark': !mapConfig.awaitingOrdersEnabled
          }" v-b-tooltip.hover.bottom="{ animation: false}" :title="$t('labels.awaiting_assign_orders')">
          <i class="fas fa-hourglass-half"/>
        </a>
      </div>
      <div class="col px-2">
        <a href="javascript;" @click.prevent="mapConfig.activeOrdersEnabled = !mapConfig.activeOrdersEnabled" v-bind:class="{
            'text-blue': mapConfig.activeOrdersEnabled,
            'text-dark': !mapConfig.activeOrdersEnabled
          }" v-b-tooltip.hover.bottom="{ animation: false}" :title="$t('labels.active_orders')">
          <i class="fas fa-location-arrow"/>
        </a>
      </div>
      <div class="col px-2 text-dark">
        <a href="javascript;" @click.prevent="mapConfig.countersEnabled = !mapConfig.countersEnabled" v-bind:class="{
            'text-blue': mapConfig.countersEnabled,
            'text-dark': !mapConfig.countersEnabled
          }" v-b-tooltip.hover.bottom="{ animation: false}" :title="$t('labels.counters')">
          <i class="fas fa-chart-bar"/>
        </a>
      </div>
      <div class="col px-2 text-dark">
        <a href="javascript;" @click.prevent="filtersOpened = !filtersOpened" v-bind:class="{
            'text-blue': filtersOpened,
            'text-dark': !filtersOpened
          }" v-b-tooltip.hover.bottom="{ animation: false}" :title="$t('labels.settings')">
          <i class="fas fa-cog"/>
        </a>
      </div>
    </div>
    <div v-if="filtersOpened && !getActiveOrder.id" class="map-settings-container">
      <div class="row alert bg-light opacity-8 m-0 p-2">
        <select-countries v-model="mapFilters.countryId" :options="{ id: 'country_id', label: 'country', placeholder: 'placeholders.all', containerClass: 'col-6 p-1' }"/>
        <select-cities v-model="mapFilters.cityIds" :country_id="mapFilters.countryId" :options="{ id: 'city_id', label: 'city', placeholder: 'placeholders.all', disabled: !mapFilters.countryId, multiple: true, containerClass: 'col-6 p-1' }"/>
        <select-platforms v-model="mapFilters.platformId" :options="{ id: 'platform_id', label: 'platform', placeholder: 'placeholders.all', containerClass: 'col-6 p-1' }"/>
        <select-platform-brands v-model="mapFilters.brandIds" :platform_id="mapFilters.platformId" :options="{ id: 'brand_ids', label: 'brands', disabled: !mapFilters.platformId, multiple: true, containerClass: 'col-6 p-1' }"/>
        <div class="col-12 p-1">
          <button @click.stop="resetFilters()" class="text-white btn btn-block btn-sm btn-primary">
            <span><i class="fas fa-sync"></i></span>
            <span class="mr-2">
              {{ $t('labels.reset')}}
            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="captainSearchOpened && !getActiveOrder.id" class="map-captain-search-container">
      <div class="row alert bg-light opacity-8 m-0 p-2 align-items-center">
        <div class="col px-0 mx-1" @keyup.enter.prevent="searchCaptain()">
          <input-number v-model="captainId" :options="{ id: 'search_captain', fullLayout: false, inputClass: 'form-control form-control-sm border-primary border-2', placeholder: 'fields.captain_id'}"/>
        </div>
        <div class="col-auto mx-1 px-0">
          <button @click.stop="searchCaptain()" class="text-white btn btn-sm btn-primary">
            <span class="mx-1"><i class="fas fa-search"></i></span>
            <span class="mx-1">{{ $t('labels.search')}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  TRACKING_SET_ACTIVE_CAPTAIN,
  TRACKING_SET_MAP_CONFIG,
  TRACKING_SET_MAP_FILTERS
} from "actions/sockets/tracking";

const CRUD_CODE = "tracking";
export default {
  name: "TrackingControls",
  data() {
    return {
      transPrefix: CRUD_CODE,
      mapConfig: {
        awaitingOrdersEnabled: true,
        activeOrdersEnabled: true,
        countersEnabled: true,
        ticketsEnabled: true,
      },
      mapFilters: {
        countryId: null,
        cityIds: null,
        platformId: null,
        brandIds: null
      },
      filtersOpened: false,
      captainSearchOpened: false,
      captainId: null
    };
  },
  mounted() {
    this.mapConfig = { ...this.getMapConfig };
    this.mapFilters = { ...this.getMapFilters };
  },
  computed: {
    ...mapGetters([
      "getMapConfig", "getMapFilters", "getActiveOrder"
    ])
  },
  watch: {
    mapConfig: {
      deep: true,
      handler: function(newVal) {
        this.$store.commit(TRACKING_SET_MAP_CONFIG, { ...this.getMapConfig, ...newVal });
      }
    },
    mapFilters: {
      deep: true,
      handler: function(newVal) {
        this.$store.commit(TRACKING_SET_MAP_FILTERS, { ...this.getMapFilters, ...newVal });
      }
    },
    captainSearchOpened: function(newVal) {
      if (!newVal) {
        this.captainId = null;
        this.$store.commit(TRACKING_SET_ACTIVE_CAPTAIN, {});
      }
    }
  },
  methods: {
    resetFilters() {
      this.mapFilters = {
        countryId: null,
        cityIds: null,
        platformId: null,
        brandIds: null
      };
    },
    searchCaptain() {
      this.$store.commit(TRACKING_SET_ACTIVE_CAPTAIN, { id: this.captainId });
    }
  }
};
</script>

<style scoped>
.font-size-20 {
  font-size: 20px;
}

.controls-disabled {
  pointer-events: none;
}
</style>