var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "nav",
      {
        staticClass:
          "navbar navbar-vertical pt-5 navbar-expand-xs navbar-light opened left-0 pb-0 component-sidenav",
        attrs: { id: "sidenav-awaiting-assign-order-tracking" }
      },
      [
        _c("div", { staticClass: "scrollbar-inner bg-lighter" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-end bg-lighter pr-0 position-fixed top-0 left-0 w-100"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "py-2 m-0 row flex-row-reverse align-items-center w-100"
                },
                [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group search m-0" },
                      [
                        _vm._m(0),
                        _c("input-text", {
                          attrs: {
                            options: {
                              id: "filter_order_id",
                              fullLayout: false,
                              inputClass:
                                "form-control form-control-sm border-primary border-2"
                            }
                          },
                          model: {
                            value: _vm.filters.orderId,
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "orderId", $$v)
                            },
                            expression: "filters.orderId"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ]
          ),
          _c("div", { staticClass: "navbar-inner bg-lighter" }, [
            _c("div", { staticClass: "collapse navbar-collapse" }, [
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticClass:
                        "text-center position-fixed overflow-hidden bottom-0 top-0 mt-5 w-100 left-0 right-0 bg-lighter opacity-7 swal-overlay--show-modal"
                    },
                    [
                      _c("span", {
                        staticClass:
                          "spinner-border loader text-dark m-auto position-absolute top-0 right-0 left-0 bottom-0 force-right-0"
                      })
                    ]
                  )
                : _vm._e(),
              _c(
                "ul",
                { staticClass: "navbar-nav" },
                [
                  _vm._l(
                    _vm.getFilteredItems(this.getItems, this.filters),
                    function(item) {
                      return _c(
                        "li",
                        {
                          key: item.id,
                          staticClass: "nav-item nav-link mx-0 p-0"
                        },
                        [
                          _c(
                            "order-card",
                            _vm._b({}, "order-card", item, false)
                          )
                        ],
                        1
                      )
                    }
                  ),
                  _vm.getFilteredItems(this.getItems, this.filters).length === 0
                    ? _c(
                        "li",
                        { staticClass: "nav-item nav-link mx-0 px-0 row" },
                        [
                          _c("div", { staticClass: "col-12 text-center" }, [
                            _c("h2", { staticClass: "order-id" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("messages.no_result_found")) +
                                  " "
                              )
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "position-absolute left-4 top-1 opacity-7",
        attrs: { for: "filter_order_id" }
      },
      [_c("i", { staticClass: "fa fa-search" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }