var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.item.permission
  ? _vm.can(_vm.item.permission)
  : true)
    ? _c(
        "li",
        { staticClass: "nav-item" },
        [
          _vm.item.items
            ? [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    class: { active: _vm.isActiveMenu(_vm.item.code) },
                    attrs: {
                      href:
                        "#side-menu-item-" + _vm.item.code + "-" + _vm.index,
                      "data-toggle": "collapse",
                      role: "button",
                      "aria-expanded": _vm.isActiveMenu(_vm.item.code),
                      "aria-controls": "navbar-maps"
                    }
                  },
                  [
                    _c("i", { class: [_vm.item.icon, _vm.item.color] }),
                    _c("span", { staticClass: "nav-label" }, [
                      _vm._v(_vm._s(_vm.$t(_vm.item.label)))
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "collapse",
                    class: { show: _vm.isActiveMenu(_vm.item.code) },
                    attrs: {
                      id: "side-menu-item-" + _vm.item.code + "-" + _vm.index
                    }
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "nav nav-sm flex-column" },
                      _vm._l(_vm.item.items, function(childItem, childIndex) {
                        return _c("side-menu-item", {
                          attrs: { item: childItem, index: childIndex }
                        })
                      }),
                      1
                    )
                  ]
                )
              ]
            : _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: {
                    "active-class": "active",
                    to: { name: _vm.item.route }
                  }
                },
                [
                  _c("i", { class: [_vm.item.icon, _vm.item.color] }),
                  _c("span", { staticClass: "nav-link-text mr-auto" }, [
                    _vm._v(_vm._s(_vm.$t(_vm.item.label)))
                  ]),
                  _vm.item.badge
                    ? _c(
                        "span",
                        { staticClass: "badge", class: [_vm.item.badge.color] },
                        [_vm._v(_vm._s(_vm.$t(_vm.item.badge.label)))]
                      )
                    : _vm._e()
                ]
              )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }