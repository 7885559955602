var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      attrs: { "aria-labelledby": "map-tab", id: "map", role: "tabpanel" }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("maps-tasks", {
              attrs: {
                id: "tasks-map",
                items: _vm.getTaskMapItems(),
                mode: "show"
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }