import { render, staticRenderFns } from "./manual_assign.vue?vue&type=template&id=23505966&scoped=true&"
import script from "./manual_assign.vue?vue&type=script&lang=js&"
export * from "./manual_assign.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23505966",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/brisk-frontend/brisk-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23505966')) {
      api.createRecord('23505966', component.options)
    } else {
      api.reload('23505966', component.options)
    }
    module.hot.accept("./manual_assign.vue?vue&type=template&id=23505966&scoped=true&", function () {
      api.rerender('23505966', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/orders/actions/manual_assign.vue"
export default component.exports