var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: { animation: false },
          expression: "{ animation: false}",
          modifiers: { hover: true }
        },
        {
          name: "can",
          rawName: "v-can",
          value: _vm.options.can,
          expression: "options.can"
        }
      ],
      staticClass: "px-1",
      class: [
        _vm.options.color_class ? _vm.options.color_class : "text-yellow"
      ],
      attrs: {
        to: {
          name: _vm.options.route_name,
          params: Object.assign({}, _vm.params),
          query: Object.assign({}, _vm.query)
        },
        title: _vm.$t(_vm.options.title ? _vm.options.title : "crud.edit")
      }
    },
    [_c("i", { class: [_vm.options.icon ? _vm.options.icon : "fas fa-edit"] })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }