var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white",
      attrs: { id: "sidenav-main" }
    },
    [
      _c("div", { staticClass: "scrollbar-inner" }, [
        _c(
          "div",
          { staticClass: "sidenav-header  align-items-center" },
          [_c("TopNavbarSideMenu", { attrs: { tag: "div" } }), _vm._m(0)],
          1
        ),
        _c("div", { staticClass: "navbar-inner pt-2" }, [
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "sidenav-collapse-main" }
            },
            [
              _c(
                "ul",
                { staticClass: "navbar-nav" },
                _vm._l(_vm.items, function(item, index) {
                  return _c("side-menu-item", {
                    attrs: { item: item, index: index }
                  })
                }),
                1
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "navbar-brand", attrs: { href: "javascript:void(0)" } },
      [
        _c("img", {
          staticClass: "navbar-brand-img",
          attrs: { src: require("@/assets/logo.png"), alt: "..." }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }