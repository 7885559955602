var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c(
            "form",
            {
              attrs: { role: "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.create($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _vm.serverError
                        ? _c("div", { staticClass: "alert alert-danger" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                            ]),
                            _vm._v(" " + _vm._s(_vm.serverError) + " ")
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "card-title" }, [
                        _c("h1", { staticClass: "text-primary" }, [
                          _vm._v(_vm._s(_vm.$t("labels.basic_information")))
                        ])
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "row card-body" },
                      [
                        _c("input-text", {
                          attrs: {
                            options: {
                              id: "name",
                              transPrefix: _vm.transPrefix + ".fields",
                              label: "backend_id",
                              error: _vm.fieldErrors.backendId
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "hint",
                              fn: function() {
                                return [
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "* (" +
                                        _vm._s(_vm.$t("labels.required")) +
                                        ")"
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.backend_id,
                            callback: function($$v) {
                              _vm.backend_id = $$v
                            },
                            expression: "backend_id"
                          }
                        }),
                        _c("select-payment-types", {
                          attrs: {
                            options: {
                              id: "payment_type",
                              transPrefix: _vm.transPrefix + ".fields",
                              label: "payment_type",
                              error: _vm.fieldErrors.paymentType
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "hint-top",
                              fn: function() {
                                return [
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "* (" +
                                        _vm._s(_vm.$t("labels.required")) +
                                        ")"
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.payment_type,
                            callback: function($$v) {
                              _vm.payment_type = $$v
                            },
                            expression: "payment_type"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.payment_type && _vm.backend_id
                    ? [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "card-title" }, [
                              _c(
                                "h1",
                                { staticClass: "text-primary d-inline" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("labels.pickup_task_details"))
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "row card-body" },
                            [
                              _vm.payment_type === "CASH_ON_DELIVERY"
                                ? _c("input-number", {
                                    attrs: {
                                      options: {
                                        id: "pickupTask_payAtPickup",
                                        transPrefix:
                                          _vm.transPrefix + ".fields",
                                        label: "pay_at_pickup",
                                        error:
                                          _vm.fieldErrors[
                                            "tasks[].payAtPickup"
                                          ],
                                        disabled:
                                          _vm.payment_type !==
                                          "CASH_ON_DELIVERY",
                                        containerClass: "col-12 col-md-4"
                                      }
                                    },
                                    model: {
                                      value: _vm.pickupTask.payAtPickup,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.pickupTask,
                                          "payAtPickup",
                                          $$v
                                        )
                                      },
                                      expression: "pickupTask.payAtPickup"
                                    }
                                  })
                                : _vm._e(),
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", { staticClass: "card-title" }, [
                                  _c(
                                    "h3",
                                    { staticClass: "text-primary d-inline" },
                                    [_vm._v(_vm._s(_vm.$t("labels.address")))]
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "col-12 col-md-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "branches" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("fields.branches"))
                                        )
                                      ]
                                    ),
                                    _c("search-platform-branches", {
                                      attrs: {
                                        placeholder: "placeholders.search",
                                        ajaxSearch: true
                                      },
                                      model: {
                                        value: _vm.branch,
                                        callback: function($$v) {
                                          _vm.branch = $$v
                                        },
                                        expression: "branch"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              !_vm.branch
                                ? _c(
                                    "div",
                                    { staticClass: "row col-12" },
                                    [
                                      _c("input-text", {
                                        attrs: {
                                          options: {
                                            id: "pickupTask_address_phone",
                                            transPrefix:
                                              _vm.transPrefix + ".fields",
                                            label: "address_phone",
                                            containerClass:
                                              "col-12 col-lg-6 col-xl-3"
                                          }
                                        },
                                        model: {
                                          value: _vm.pickupTask.address.phone,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pickupTask.address,
                                              "phone",
                                              $$v
                                            )
                                          },
                                          expression: "pickupTask.address.phone"
                                        }
                                      }),
                                      _c("input-text", {
                                        attrs: {
                                          options: {
                                            id:
                                              "pickupTask_address_description",
                                            transPrefix:
                                              _vm.transPrefix + ".fields",
                                            label: "address_description",
                                            error:
                                              _vm.fieldErrors[
                                                "tasks[].address.description"
                                              ],
                                            containerClass:
                                              "col-12 col-lg-6 col-xl-3"
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.pickupTask.address.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pickupTask.address,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pickupTask.address.description"
                                        }
                                      }),
                                      _c("input-number", {
                                        attrs: {
                                          options: {
                                            id: "pickupTask_address_latitude",
                                            transPrefix:
                                              _vm.transPrefix + ".fields",
                                            label: "address_latitude",
                                            error:
                                              _vm.fieldErrors[
                                                "tasks[].address.latitude"
                                              ],
                                            containerClass:
                                              "col-12 col-lg-6 col-xl-3"
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "hint",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "* (" +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "labels.required"
                                                            )
                                                          ) +
                                                          ")"
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "mx-1 text-blue",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.showPickupMapPicker(
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-map-pin "
                                                      })
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          853566699
                                        ),
                                        model: {
                                          value:
                                            _vm.pickupTask.address.latitude,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pickupTask.address,
                                              "latitude",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pickupTask.address.latitude"
                                        }
                                      }),
                                      _c("input-number", {
                                        attrs: {
                                          options: {
                                            id: "pickupTask_address_longitude",
                                            transPrefix:
                                              _vm.transPrefix + ".fields",
                                            label: "address_longitude",
                                            error:
                                              _vm.fieldErrors[
                                                "tasks[].address.longitude"
                                              ],
                                            containerClass:
                                              "col-12 col-lg-6 col-xl-3"
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "hint",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "* (" +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "labels.required"
                                                            )
                                                          ) +
                                                          ")"
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "mx-1 text-blue",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.showPickupMapPicker(
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-map-pin "
                                                      })
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          853566699
                                        ),
                                        model: {
                                          value:
                                            _vm.pickupTask.address.longitude,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pickupTask.address,
                                              "longitude",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pickupTask.address.longitude"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.pickupMapPicker
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 position-fixed top-9 left-0 p-5",
                                      staticStyle: { "z-index": "99999" }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger btn-sm",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.hidePickupMapPicker(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("labels.close")) +
                                              " "
                                          ),
                                          _c("i", {
                                            staticClass: "fa fa-window-close"
                                          })
                                        ]
                                      ),
                                      _c("maps-pick-address", {
                                        attrs: {
                                          position: {
                                            lat:
                                              _vm.pickupTask.address.latitude,
                                            lng:
                                              _vm.pickupTask.address.longitude
                                          }
                                        },
                                        model: {
                                          value: _vm.pickupMarkerPosition,
                                          callback: function($$v) {
                                            _vm.pickupMarkerPosition = $$v
                                          },
                                          expression: "pickupMarkerPosition"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "div",
                            { staticClass: "row justify-content-end" },
                            [
                              _c("div", { staticClass: "col px-0" }, [
                                _c("div", { staticClass: "card-title" }, [
                                  _c(
                                    "h3",
                                    { staticClass: "text-primary d-inline" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("labels.products_opt"))
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "col text-right" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success btn-sm mx-2",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.addPickupTaskItemRow($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.$t("labels.add_new_product"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("i", { staticClass: "fa fa-plus" })
                                  ]
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _vm._l(_vm.pickupTask.items, function(
                                pickupTaskItem,
                                index
                              ) {
                                return [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "h4",
                                      { staticClass: "text-primary d-inline" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("labels.product")) +
                                            " " +
                                            _vm._s(index + 1)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-danger mx-2",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.removePickupTaskItemRow(
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-trash" })]
                                    )
                                  ]),
                                  _c("input-text", {
                                    attrs: {
                                      options: {
                                        id: "name_" + index,
                                        label: "name",
                                        error:
                                          _vm.fieldErrors[
                                            "tasks[].items[].name"
                                          ],
                                        containerClass: "col-12 col-md-3"
                                      }
                                    },
                                    model: {
                                      value: pickupTaskItem.name,
                                      callback: function($$v) {
                                        _vm.$set(pickupTaskItem, "name", $$v)
                                      },
                                      expression: "pickupTaskItem.name"
                                    }
                                  }),
                                  _c("input-text", {
                                    attrs: {
                                      options: {
                                        id: "description_" + index,
                                        label: "description",
                                        error:
                                          _vm.fieldErrors[
                                            "tasks[].items[].description"
                                          ],
                                        containerClass: "col-12 col-md-3"
                                      }
                                    },
                                    model: {
                                      value: pickupTaskItem.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          pickupTaskItem,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "pickupTaskItem.description"
                                    }
                                  }),
                                  _c("input-number", {
                                    attrs: {
                                      options: {
                                        id: "quantity_" + index,
                                        step: 1,
                                        label: "quantity",
                                        error:
                                          _vm.fieldErrors[
                                            "tasks[].items[].quantity"
                                          ],
                                        containerClass: "col-12 col-md-3"
                                      }
                                    },
                                    model: {
                                      value: pickupTaskItem.quantity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          pickupTaskItem,
                                          "quantity",
                                          $$v
                                        )
                                      },
                                      expression: "pickupTaskItem.quantity"
                                    }
                                  }),
                                  _c("input-number", {
                                    attrs: {
                                      options: {
                                        id: "price_" + index,
                                        label: "price",
                                        error:
                                          _vm.fieldErrors[
                                            "tasks[].items[].price"
                                          ],
                                        containerClass: "col-12 col-md-3"
                                      }
                                    },
                                    model: {
                                      value: pickupTaskItem.price,
                                      callback: function($$v) {
                                        _vm.$set(pickupTaskItem, "price", $$v)
                                      },
                                      expression: "pickupTaskItem.price"
                                    }
                                  }),
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("hr")
                                  ])
                                ]
                              })
                            ],
                            2
                          )
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "card-title" }, [
                              _c(
                                "h1",
                                { staticClass: "text-primary d-inline" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("labels.delivery_task_details")
                                    )
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "row card-body mb-5" },
                            [
                              _vm.payment_type === "CASH_ON_DELIVERY"
                                ? _c("input-number", {
                                    attrs: {
                                      options: {
                                        id: "deliveryTask_collectAtDelivery",
                                        transPrefix:
                                          _vm.transPrefix + ".fields",
                                        label: "collect_at_delivery",
                                        error:
                                          _vm.fieldErrors[
                                            "tasks[].collectAtDelivery"
                                          ],
                                        disabled:
                                          _vm.payment_type !==
                                          "CASH_ON_DELIVERY",
                                        containerClass: "col-12 col-md-4"
                                      }
                                    },
                                    model: {
                                      value: _vm.deliveryTask.collectAtDelivery,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.deliveryTask,
                                          "collectAtDelivery",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveryTask.collectAtDelivery"
                                    }
                                  })
                                : _vm._e(),
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", { staticClass: "card-title" }, [
                                  _c(
                                    "h3",
                                    { staticClass: "text-primary d-inline" },
                                    [_vm._v(_vm._s(_vm.$t("labels.address")))]
                                  )
                                ])
                              ]),
                              _c(
                                "div",
                                { staticClass: "row col-12" },
                                [
                                  _c("input-text", {
                                    attrs: {
                                      options: {
                                        id: "deliveryTask_address_phone",
                                        transPrefix:
                                          _vm.transPrefix + ".fields",
                                        label: "address_phone",
                                        error:
                                          _vm.fieldErrors[
                                            "tasks[].address.phone"
                                          ],
                                        containerClass:
                                          "col-12 col-lg-6 col-xl-3"
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "hint",
                                          fn: function() {
                                            return [
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    "* (" +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "labels.required"
                                                        )
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3320385126
                                    ),
                                    model: {
                                      value: _vm.deliveryTask.address.phone,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.deliveryTask.address,
                                          "phone",
                                          $$v
                                        )
                                      },
                                      expression: "deliveryTask.address.phone"
                                    }
                                  }),
                                  _c("input-text", {
                                    attrs: {
                                      options: {
                                        id: "deliveryTask_address_description",
                                        transPrefix:
                                          _vm.transPrefix + ".fields",
                                        label: "address_description",
                                        error:
                                          _vm.fieldErrors[
                                            "tasks[].address.description"
                                          ],
                                        containerClass:
                                          "col-12 col-lg-6 col-xl-3"
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "hint",
                                          fn: function() {
                                            return [
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    "* (" +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "labels.required"
                                                        )
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3320385126
                                    ),
                                    model: {
                                      value:
                                        _vm.deliveryTask.address.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.deliveryTask.address,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveryTask.address.description"
                                    }
                                  }),
                                  _c("input-number", {
                                    attrs: {
                                      options: {
                                        id: "deliveryTask_address_latitude",
                                        transPrefix:
                                          _vm.transPrefix + ".fields",
                                        label: "address_latitude",
                                        error:
                                          _vm.fieldErrors[
                                            "tasks[].address.latitude"
                                          ],
                                        containerClass:
                                          "col-12 col-lg-6 col-xl-3"
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "hint",
                                          fn: function() {
                                            return [
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    "* (" +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "labels.required"
                                                        )
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "mx-1 text-blue",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.showDeliveryMapPicker(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-map-pin "
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1198058723
                                    ),
                                    model: {
                                      value: _vm.deliveryTask.address.latitude,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.deliveryTask.address,
                                          "latitude",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveryTask.address.latitude"
                                    }
                                  }),
                                  _c("input-number", {
                                    attrs: {
                                      options: {
                                        id: "deliveryTask_address_longitude",
                                        transPrefix:
                                          _vm.transPrefix + ".fields",
                                        label: "address_longitude",
                                        error:
                                          _vm.fieldErrors[
                                            "tasks[].address.longitude"
                                          ],
                                        containerClass:
                                          "col-12 col-lg-6 col-xl-3"
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "hint",
                                          fn: function() {
                                            return [
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    "* (" +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "labels.required"
                                                        )
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "mx-1 text-blue",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.showDeliveryMapPicker(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-map-pin "
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1198058723
                                    ),
                                    model: {
                                      value: _vm.deliveryTask.address.longitude,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.deliveryTask.address,
                                          "longitude",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveryTask.address.longitude"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.deliveryMapPicker
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 position-fixed top-9 left-0 p-5",
                                      staticStyle: { "z-index": "99999" }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-danger btn-sm",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.hideDeliveryMapPicker(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("labels.close")) +
                                              " "
                                          ),
                                          _c("i", {
                                            staticClass: "fa fa-window-close"
                                          })
                                        ]
                                      ),
                                      _c("maps-pick-address", {
                                        attrs: {
                                          id: "deliveryPicker",
                                          position: {
                                            lat:
                                              _vm.deliveryTask.address.latitude,
                                            lng:
                                              _vm.deliveryTask.address.longitude
                                          }
                                        },
                                        model: {
                                          value: _vm.deliveryMarkerPosition,
                                          callback: function($$v) {
                                            _vm.deliveryMarkerPosition = $$v
                                          },
                                          expression: "deliveryMarkerPosition"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "row justify-content-end" }, [
                          _c("div", { staticClass: "col" }, [
                            _c("div", { staticClass: "card-title" }, [
                              _c(
                                "h1",
                                { staticClass: "text-primary d-inline" },
                                [_vm._v(_vm._s(_vm.$t("labels.meta_data_opt")))]
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "col text-right" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success btn-sm mx-2",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.addMetaDataItemRow($event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("labels.add_new_item")) +
                                    " "
                                ),
                                _c("i", { staticClass: "fa fa-plus" })
                              ]
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "row card-body mb-5 pt-0" },
                          [
                            _vm._l(_vm.metaDataItems, function(
                              metaDataItem,
                              index
                            ) {
                              return [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "h3",
                                    { staticClass: "text-primary d-inline" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("labels.item")) +
                                          " " +
                                          _vm._s(index + 1)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-danger mx-2",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.removeMetaDataItemRow(
                                            index
                                          )
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fa fa-trash" })]
                                  )
                                ]),
                                _c("input-text", {
                                  attrs: {
                                    options: {
                                      id: "label_" + index,
                                      label: "label",
                                      error:
                                        _vm.fieldErrors["metaData[].label"],
                                      containerClass: "col-12 col-md-4"
                                    }
                                  },
                                  model: {
                                    value: metaDataItem.label,
                                    callback: function($$v) {
                                      _vm.$set(metaDataItem, "label", $$v)
                                    },
                                    expression: "metaDataItem.label"
                                  }
                                }),
                                _c("input-text", {
                                  attrs: {
                                    options: {
                                      id: "data_" + index,
                                      label: "data",
                                      error: _vm.fieldErrors["metaData[].data"],
                                      containerClass: "col-12 col-md-4"
                                    }
                                  },
                                  model: {
                                    value: metaDataItem.data,
                                    callback: function($$v) {
                                      _vm.$set(metaDataItem, "data", $$v)
                                    },
                                    expression: "metaDataItem.data"
                                  }
                                }),
                                _c("select-language-codes", {
                                  attrs: {
                                    options: {
                                      id: "language_code_" + index,
                                      label: "language_code",
                                      error:
                                        _vm.fieldErrors[
                                          "metaData[].languageCode"
                                        ],
                                      containerClass: "col-12 col-md-4"
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "hint-top",
                                        fn: function() {
                                          return [
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "* (" +
                                                    _vm._s(
                                                      _vm.$t("labels.required")
                                                    ) +
                                                    ")"
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: metaDataItem.languageCode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        metaDataItem,
                                        "languageCode",
                                        $$v
                                      )
                                    },
                                    expression: "metaDataItem.languageCode"
                                  }
                                }),
                                _c("div", { staticClass: "col-12" }, [_c("hr")])
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c("div", { staticClass: "card-footer" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col text-right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-neutral btn-sm",
                          attrs: { to: { name: _vm.routePrefix + ".index" } }
                        },
                        [_c("i", { staticClass: "fa fa-arrow-left" })]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { type: "submit", disabled: _vm.loading }
                        },
                        [
                          _vm.loading
                            ? _c("span", {
                                staticClass: "spinner-border spinner-border-sm"
                              })
                            : _vm._e(),
                          _c("i", { staticClass: "fa fa-save" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }