var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 col-md-6" },
    [
      _c("select-capabilities", {
        attrs: {
          value: _vm.capabilitiesIds,
          options: {
            id: "capabilities",
            label: "capabilities",
            placeholder: _vm.can(
              _vm.permissionPrefix + "_capabilities_can_edit"
            )
              ? "placeholders.dropdown"
              : "messages.no_item_found",
            disabled: !_vm.can(_vm.permissionPrefix + "_capabilities_can_edit"),
            action: _vm.capabilities_list_action,
            onAddHandler: _vm.addCapability,
            onRemoveHandler: _vm.removeCapability,
            multiple: true,
            containerClass: ""
          }
        },
        scopedSlots: _vm._u([
          {
            key: "hint-bottom",
            fn: function() {
              return [
                _vm.loading
                  ? _c("span", [
                      _c("span", {
                        staticClass: "spinner-border spinner-border-sm"
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("labels.saving")) + " ")
                    ])
                  : _vm._e(),
                _vm.message
                  ? _c(
                      "span",
                      {
                        class: {
                          "text-success": _vm.isSuccessMessage,
                          "text-danger": !_vm.isSuccessMessage
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa",
                          class: {
                            "fa-check-circle": _vm.isSuccessMessage,
                            "fa-window-close": !_vm.isSuccessMessage
                          }
                        }),
                        _vm._v(" " + _vm._s(_vm.$t(_vm.message)) + " ")
                      ]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }