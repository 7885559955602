var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container captain-card" }, [
    _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-9" }, [
        _c(
          "h3",
          [
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "can",
                    rawName: "v-can:view",
                    value: _vm.captainPermissionPrefix,
                    expression: "captainPermissionPrefix",
                    arg: "view"
                  }
                ],
                attrs: {
                  to: {
                    name: this.captainRoutePrefix + ".show",
                    params: { id: this.captain.id }
                  },
                  target: "_blank"
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("fields.id")) + ": "),
                _c("u", [_vm._v(_vm._s(_vm.id))])
              ]
            ),
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.copyId($event)
                  }
                }
              },
              [_c("i", { staticClass: "far fa-copy mx-2" })]
            ),
            _c("transition", { attrs: { name: "copied" } }, [
              _vm.id_copied === true
                ? _c(
                    "small",
                    { staticClass: "border border-gray rounded text-body p-1" },
                    [_vm._v(_vm._s(_vm.$t("labels.copied_to_clipboard")))]
                  )
                : _vm._e()
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-3 text-right" }, [
        _c(
          "span",
          {
            staticClass: "badge",
            class: {
              "bg-green text-white": _vm.getCaptainOrders.length === 0,
              "bg-yellow": _vm.getCaptainOrders.length === 1,
              "bg-orange text-white": _vm.getCaptainOrders.length > 1
            },
            staticStyle: { "font-size": "0.75rem" }
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.getCaptainOrders.length === 0
                    ? _vm.$t(
                        _vm.captainTransPrefix + ".lists.working_status.FREE"
                      )
                    : _vm.$t(
                        _vm.captainTransPrefix + ".lists.working_status.BUSY"
                      ) +
                        " (" +
                        _vm.getCaptainOrders.length +
                        ")"
                ) +
                " "
            )
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "row align-items-baseline" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("fields.name")))])
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "name",
                  disabled: true,
                  containerClass: "col-12"
                }
              },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("span", [_vm._v(" " + _vm._s(_vm.$t("fields.mobile_number")) + " ")])
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "phone",
                  disabled: true,
                  containerClass: "col-12"
                }
              },
              model: {
                value: _vm.phone,
                callback: function($$v) {
                  _vm.phone = $$v
                },
                expression: "phone"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.$t(_vm.captainTransPrefix + ".fields.supplier")))
        ])
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "supplier",
                  disabled: true,
                  containerClass: "col-12"
                }
              },
              model: {
                value: _vm.supplier,
                callback: function($$v) {
                  _vm.supplier = $$v
                },
                expression: "supplier"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t(_vm.captainTransPrefix + ".fields.shift_start_time"))
          )
        ])
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "shift_start_time",
                  disabled: true,
                  containerClass: "col-12"
                }
              },
              model: {
                value: _vm.shiftStartTime,
                callback: function($$v) {
                  _vm.shiftStartTime = $$v
                },
                expression: "shiftStartTime"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t(_vm.captainTransPrefix + ".fields.today_deliveries"))
          )
        ])
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "today_deliveries",
                  disabled: true,
                  containerClass: "col-12"
                }
              },
              model: {
                value: _vm.todayDeliveries,
                callback: function($$v) {
                  _vm.todayDeliveries = $$v
                },
                expression: "todayDeliveries"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "row align-items-baseline mt-2" },
      [
        _c("input-checkbox", {
          attrs: {
            options: {
              id: "ready_to_work",
              transPrefix: _vm.captainTransPrefix + ".fields",
              label: "ready_to_work",
              disabled: true
            }
          },
          model: {
            value: _vm.getReadyToWork,
            callback: function($$v) {
              _vm.getReadyToWork = $$v
            },
            expression: "getReadyToWork"
          }
        }),
        _c("input-checkbox", {
          attrs: {
            options: {
              id: "connected",
              transPrefix: _vm.captainTransPrefix + ".fields",
              label: "connected",
              disabled: true
            }
          },
          model: {
            value: _vm.getConnected,
            callback: function($$v) {
              _vm.getConnected = $$v
            },
            expression: "getConnected"
          }
        }),
        _c("input-checkbox", {
          attrs: {
            options: {
              id: "suspended",
              transPrefix: _vm.captainTransPrefix + ".fields",
              label: "suspended",
              onChangeHandler: _vm.changeSuspendedStatus,
              onChangeParams: { id: _vm.id, isSuspended: !_vm.suspended },
              disabled: !_vm.can(_vm.captainPermissionPrefix, "suspend")
            }
          },
          model: {
            value: _vm.suspended,
            callback: function($$v) {
              _vm.suspended = $$v
            },
            expression: "suspended"
          }
        }),
        _c("input-checkbox", {
          attrs: {
            options: {
              id: "deleted",
              transPrefix: _vm.captainTransPrefix + ".fields",
              label: "deleted",
              disabled: true
            }
          },
          model: {
            value: _vm.deleted,
            callback: function($$v) {
              _vm.deleted = $$v
            },
            expression: "deleted"
          }
        })
      ],
      1
    ),
    _vm.getCaptainOrders.length > 0
      ? _c(
          "div",
          { staticClass: "row mt-2" },
          [
            _c("div", { staticClass: "col-12 text-center" }, [
              _c("span", [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$t(_vm.captainTransPrefix + ".fields.orders"))
                  )
                ])
              ])
            ]),
            _vm._l(_vm.getCaptainOrders, function(order) {
              return _c(
                "div",
                {
                  key: order.id,
                  staticClass: "col-12 p-0",
                  on: {
                    click: function($event) {
                      return _vm.showOrderHandler(order)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tracking-order-item-small m-1 py-2 row",
                      class: { "too-late": _vm.isTooLate(order) }
                    },
                    [
                      _c("div", { staticClass: "col-6" }, [
                        _c("h4", { staticClass: "order-id mb-0" }, [
                          _vm._v(_vm._s(order.backendId))
                        ])
                      ]),
                      _c("div", { staticClass: "col-6 text-right" }, [
                        _c(
                          "span",
                          {
                            staticClass: "payment-type badge pb-1",
                            class: {
                              "bg-danger text-white":
                                order.paymentType == "PREPAID",
                              "bg-green text-white":
                                order.paymentType == "CASH_ON_DELIVERY"
                            }
                          },
                          [_vm._v(" " + _vm._s(order.paymentType) + " ")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c(
                          "span",
                          {
                            staticClass: "status",
                            style: { color: order.statusColor }
                          },
                          [
                            _c("strong", [
                              _vm._v(" " + _vm._s(order.status) + " ")
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "col-6 text-right" }, [
                        _c("span", { staticClass: "total-distance" }, [
                          _vm._v(" " + _vm._s(order.totalDistance) + " ")
                        ])
                      ]),
                      _c("div", { staticClass: "col-6 text-left" }, [
                        _c("span", { staticClass: "platform" }, [
                          _vm._v(
                            " " + _vm._s(order.brand || order.platform) + " "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "col-6 text-right" }, [
                        _c("span", { staticClass: "city" }, [
                          _vm._v(" " + _vm._s(order.city) + " ")
                        ])
                      ])
                    ]
                  )
                ]
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }