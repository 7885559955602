import { render, staticRenderFns } from "./TableHeader.vue?vue&type=template&id=63300c19&scoped=true&"
import script from "./TableHeader.vue?vue&type=script&lang=js&"
export * from "./TableHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63300c19",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/brisk-frontend/brisk-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63300c19')) {
      api.createRecord('63300c19', component.options)
    } else {
      api.reload('63300c19', component.options)
    }
    module.hot.accept("./TableHeader.vue?vue&type=template&id=63300c19&scoped=true&", function () {
      api.rerender('63300c19', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/crud/table/TableHeader.vue"
export default component.exports