import { render, staticRenderFns } from "./TopNavbarSideMenu.vue?vue&type=template&id=4199e703&scoped=true&"
import script from "./TopNavbarSideMenu.vue?vue&type=script&lang=js&"
export * from "./TopNavbarSideMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4199e703",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/brisk-frontend/brisk-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4199e703')) {
      api.createRecord('4199e703', component.options)
    } else {
      api.reload('4199e703', component.options)
    }
    module.hot.accept("./TopNavbarSideMenu.vue?vue&type=template&id=4199e703&scoped=true&", function () {
      api.rerender('4199e703', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layout/partials/TopNavbarSideMenu.vue"
export default component.exports