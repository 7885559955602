var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c(
        "card-header",
        {
          attrs: {
            title: _vm.title,
            hint: _vm.headerHint,
            withSearch: _vm.withSearch,
            filtersIsEmpty: _vm.filtersIsEmpty,
            searchHandler: _vm.searchHandler
          },
          scopedSlots: _vm._u(
            [
              {
                key: "search",
                fn: function() {
                  return [_vm._t("card-header-search")]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [_vm._t("card-header")],
        2
      ),
      _c(
        "table-container",
        { attrs: { headers: _vm.headers } },
        [_vm._t("table-rows")],
        2
      ),
      _vm._t("items-container"),
      _c(
        "card-footer",
        {
          attrs: {
            lastPage: _vm.lastPage,
            currentPage: _vm.currentPage,
            clickHandler: _vm.clickHandler,
            totalElements: _vm.totalElements
          },
          scopedSlots: _vm._u(
            [
              {
                key: "bulk-actions",
                fn: function() {
                  return [_vm._t("bulk-actions")]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [_vm._t("card-footer")],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }