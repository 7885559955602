var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-4" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "text-center h3" }, [
            _vm._v(
              " All-In-One Orders Statistics " +
                _vm._s(
                  _vm.selectedCategories.length
                    ? "(" + _vm.selectedCategories.join(" -> ") + ")"
                    : ""
                ) +
                " "
            )
          ])
        ]),
        _c("select-chart-categories", {
          model: {
            value: _vm.selectedCategories,
            callback: function($$v) {
              _vm.selectedCategories = $$v
            },
            expression: "selectedCategories"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row chart-container" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("v-chart", { staticClass: "chart", attrs: { option: _vm.option } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }