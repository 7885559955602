var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade show active",
      attrs: {
        "aria-labelledby": "general-tab",
        id: "general",
        role: "tabpanel"
      }
    },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("input-text", {
            attrs: {
              options: { id: "captain_id", label: "captain_id", disabled: true }
            },
            model: {
              value: _vm.fields.captain.id,
              callback: function($$v) {
                _vm.$set(_vm.fields.captain, "id", $$v)
              },
              expression: "fields.captain.id"
            }
          }),
          _c("input-text", {
            attrs: {
              options: {
                id: "captain_name",
                label: "captain_name",
                disabled: true
              }
            },
            model: {
              value: _vm.fields.captain.user.name,
              callback: function($$v) {
                _vm.$set(_vm.fields.captain.user, "name", $$v)
              },
              expression: "fields.captain.user.name"
            }
          }),
          _c("select-transaction-types", {
            attrs: {
              options: {
                id: "transaction_type",
                transPrefix: _vm.transPrefix + ".fields",
                label: "transaction_type",
                disabled: true
              }
            },
            model: {
              value: _vm.fields.transaction_type,
              callback: function($$v) {
                _vm.$set(_vm.fields, "transaction_type", $$v)
              },
              expression: "fields.transaction_type"
            }
          }),
          _c("select-withdraw-status", {
            attrs: {
              options: { id: "status", label: "status", disabled: true }
            },
            model: {
              value: _vm.fields.status,
              callback: function($$v) {
                _vm.$set(_vm.fields, "status", $$v)
              },
              expression: "fields.status"
            }
          }),
          _c("input-text", {
            attrs: {
              options: { id: "wallet_id", label: "wallet_id", disabled: true }
            },
            model: {
              value: _vm.fields.wallet_id,
              callback: function($$v) {
                _vm.$set(_vm.fields, "wallet_id", $$v)
              },
              expression: "fields.wallet_id"
            }
          }),
          _c("input-text", {
            attrs: {
              options: { id: "amount", label: "amount", disabled: true }
            },
            model: {
              value: _vm.fields.amount,
              callback: function($$v) {
                _vm.$set(_vm.fields, "amount", $$v)
              },
              expression: "fields.amount"
            }
          }),
          _vm.fields.refusal_reason_message
            ? _c("input-text", {
                attrs: {
                  options: {
                    id: "refusal_reason_message",
                    transPrefix: _vm.transPrefix + ".fields",
                    label: "refusal_reason",
                    disabled: true
                  }
                },
                model: {
                  value: _vm.fields.refusal_reason_message,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "refusal_reason_message", $$v)
                  },
                  expression: "fields.refusal_reason_message"
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }