var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c(
            "form",
            {
              attrs: { role: "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.create($event)
                }
              }
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _vm.serverError
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.serverError) + " ")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("select-envelope-type", {
                      attrs: {
                        options: {
                          id: "envelope_type",
                          label: "envelope_type",
                          containerClass: "col-12"
                        }
                      },
                      model: {
                        value: _vm.envelope_type,
                        callback: function($$v) {
                          _vm.envelope_type = $$v
                        },
                        expression: "envelope_type"
                      }
                    })
                  ],
                  1
                ),
                _vm.envelope_type === "ENVELOPE"
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("files-image", {
                          attrs: {
                            options: {
                              id: "image",
                              label: "image",
                              error: _vm.fieldErrors.image
                            }
                          },
                          model: {
                            value: _vm.image,
                            callback: function($$v) {
                              _vm.image = $$v
                            },
                            expression: "image"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.envelope_type
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("select-countries", {
                          attrs: {
                            options: {
                              id: "country_id",
                              label: "country",
                              placeholder: "placeholders.all",
                              error: _vm.fieldErrors.countryId
                            }
                          },
                          model: {
                            value: _vm.country_id,
                            callback: function($$v) {
                              _vm.country_id = $$v
                            },
                            expression: "country_id"
                          }
                        }),
                        _c("select-cities", {
                          attrs: {
                            country_id: _vm.country_id,
                            options: {
                              id: "city_id",
                              label: "city",
                              placeholder: "placeholders.all",
                              disabled: !_vm.country_id,
                              error: _vm.fieldErrors.cityId
                            }
                          },
                          model: {
                            value: _vm.city_id,
                            callback: function($$v) {
                              _vm.city_id = $$v
                            },
                            expression: "city_id"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.envelope_type === "ENVELOPE"
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("input-number", {
                          attrs: {
                            options: {
                              id: "captain_id",
                              step: 1,
                              label: "captain",
                              error: _vm.fieldErrors.captainId
                            }
                          },
                          model: {
                            value: _vm.captain_id,
                            callback: function($$v) {
                              _vm.captain_id = $$v
                            },
                            expression: "captain_id"
                          }
                        }),
                        _c("select-language-codes", {
                          attrs: {
                            options: {
                              id: "language_code",
                              label: "language_code",
                              error: _vm.fieldErrors.languageCode
                            }
                          },
                          model: {
                            value: _vm.language_code,
                            callback: function($$v) {
                              _vm.language_code = $$v
                            },
                            expression: "language_code"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.envelope_type
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("select-envelope-sender-id", {
                          attrs: {
                            options: {
                              id: "sender_id",
                              label: "envelope_sender_id",
                              error: _vm.fieldErrors.senderId
                            }
                          },
                          model: {
                            value: _vm.sender_id,
                            callback: function($$v) {
                              _vm.sender_id = $$v
                            },
                            expression: "sender_id"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.envelope_type
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("input-text", {
                          attrs: {
                            options: {
                              id: "title",
                              label: "title",
                              error: _vm.fieldErrors.title,
                              containerClass: "col-12"
                            }
                          },
                          model: {
                            value: _vm.title,
                            callback: function($$v) {
                              _vm.title = $$v
                            },
                            expression: "title"
                          }
                        }),
                        _c("input-textarea", {
                          attrs: {
                            options: {
                              id: "message",
                              label: "message",
                              error: _vm.fieldErrors.message,
                              containerClass: "col-12"
                            }
                          },
                          model: {
                            value: _vm.message,
                            callback: function($$v) {
                              _vm.message = $$v
                            },
                            expression: "message"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.envelope_type === "ENVELOPE"
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("input-checkbox", {
                          attrs: {
                            options: {
                              id: "cloud_notify",
                              transPrefix: _vm.transPrefix + ".fields",
                              label: "cloud_notify",
                              error: _vm.fieldErrors.cloudNotify
                            }
                          },
                          model: {
                            value: _vm.cloud_notify,
                            callback: function($$v) {
                              _vm.cloud_notify = $$v
                            },
                            expression: "cloud_notify"
                          }
                        }),
                        _c("input-checkbox", {
                          attrs: {
                            options: {
                              id: "sms_notify",
                              transPrefix: _vm.transPrefix + ".fields",
                              label: "sms_notify",
                              error: _vm.fieldErrors.smsNotify
                            }
                          },
                          model: {
                            value: _vm.sms_notify,
                            callback: function($$v) {
                              _vm.sms_notify = $$v
                            },
                            expression: "sms_notify"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "card-footer" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col text-right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-neutral btn-sm",
                          attrs: { to: { name: _vm.routePrefix + ".index" } }
                        },
                        [_c("i", { staticClass: "fa fa-arrow-left" })]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: {
                            type: "submit",
                            disabled: _vm.loading || !_vm.envelope_type
                          }
                        },
                        [
                          _vm.loading
                            ? _c("span", {
                                staticClass: "spinner-border spinner-border-sm"
                              })
                            : _vm._e(),
                          _c("i", { staticClass: "fa fa-save" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }