var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("searchContainer", {
    attrs: { searchHandler: _vm.search, resetHandler: _vm.reset },
    scopedSlots: _vm._u([
      {
        key: "items",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("input-number", {
                  attrs: { options: { id: "id", step: 1, label: "id" } },
                  model: {
                    value: _vm.filters.id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "id", $$v)
                    },
                    expression: "filters.id"
                  }
                }),
                _c("input-number", {
                  attrs: {
                    options: { id: "captain_id", step: 1, label: "captain_id" }
                  },
                  model: {
                    value: _vm.filters.captain_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "captain_id", $$v)
                    },
                    expression: "filters.captain_id"
                  }
                }),
                _c("input-number", {
                  attrs: {
                    options: {
                      id: "allocation_window_id",
                      step: 1,
                      label: "allocation_window_id"
                    }
                  },
                  model: {
                    value: _vm.filters.allocation_window_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "allocation_window_id", $$v)
                    },
                    expression: "filters.allocation_window_id"
                  }
                }),
                _c("select-allocation-window-statuses", {
                  attrs: {
                    options: {
                      id: "status",
                      label: "status",
                      multiple: true,
                      placeholder: "placeholders.all"
                    }
                  },
                  model: {
                    value: _vm.filters.status,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "status", $$v)
                    },
                    expression: "filters.status"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("input-date", {
                  attrs: {
                    options: { id: "created_from", label: "created_from" }
                  },
                  model: {
                    value: _vm.filters.created_from,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "created_from", $$v)
                    },
                    expression: "filters.created_from"
                  }
                }),
                _c("input-date", {
                  attrs: { options: { id: "created_to", label: "created_to" } },
                  model: {
                    value: _vm.filters.created_to,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "created_to", $$v)
                    },
                    expression: "filters.created_to"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }