var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tracking-order-item mx-0 px-0 py-2 row",
      class: { active: _vm.isActive, "too-late": _vm.isTooLate(_vm.createdAt) },
      on: {
        click: function($event) {
          _vm.isClickable ? _vm.showHideOrderDetails() : null
        }
      }
    },
    [
      _c("div", { staticClass: "col-6" }, [
        _c("h4", { staticClass: "order-id mb-0" }, [
          _vm._v(_vm._s(_vm.backendId))
        ])
      ]),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c(
          "span",
          {
            staticClass: "payment-type badge pb-1",
            class: {
              "bg-danger text-white": _vm.paymentType === "PREPAID",
              "bg-green text-white": _vm.paymentType === "CASH_ON_DELIVERY"
            }
          },
          [_vm._v(" " + _vm._s(_vm.paymentType) + " ")]
        ),
        _vm.giftOrder
          ? _c("span", { staticClass: "badge pb-1 bg-danger text-white" }, [
              _c("i", { staticClass: "fas fa-gift" }),
              _vm._v(" " + _vm._s(_vm.$t("tracking.gift_order")) + " ")
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "span",
          {
            staticClass: "status font-size-12",
            style: { color: _vm.statusColor }
          },
          [_c("strong", [_vm._v(" " + _vm._s(_vm.status) + " ")])]
        )
      ]),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c("span", { staticClass: "total-distance" }, [
          _vm._v(" " + _vm._s(_vm.totalDistance) + " ")
        ])
      ]),
      _vm.assignmentStatus !== "NOT_ASSIGNED"
        ? _c(
            "div",
            {
              staticClass: "col-12 text-left text-blue mt--1",
              staticStyle: { "line-height": "12px" }
            },
            [
              _c("span", { staticClass: "font-size-11" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "tracking.assignment_status." + _vm.assignmentStatus
                      )
                    ) +
                    " " +
                    _vm._s(
                      _vm.assignerName
                        ? _vm.$t("tracking.assignment_status.assigner_name", [
                            _vm.assignerName
                          ])
                        : ""
                    ) +
                    " "
                )
              ])
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "col-6 text-left" }, [
        _c("span", { staticClass: "platform" }, [
          _vm._v(" " + _vm._s(_vm.brand || _vm.platform) + " ")
        ])
      ]),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c("span", { staticClass: "city" }, [
          _vm._v(" " + _vm._s(_vm.city) + " ")
        ])
      ]),
      _vm.captainId
        ? _c("div", { staticClass: "col-12 text-left" }, [
            _c("span", { staticClass: "font-size-10" }, [
              _c("i", {
                staticClass: "fas fa-car",
                class: _vm.isConnected(_vm.captainId) ? "text-blue" : "text-red"
              }),
              _vm._v(" " + _vm._s(_vm.captainId) + " ")
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "col-12" }, [
        _c("span", { staticClass: "created-at font-size-12" }, [
          _c("span", { staticClass: "text-direction-ltr" }, [
            _vm._v(
              " " +
                _vm._s(_vm._f("moment")(_vm.createdAt, "Y-MM-DD hh:mm:ss A"))
            )
          ])
        ])
      ]),
      _vm.notes
        ? _c(
            "div",
            { staticClass: "col-12", staticStyle: { "line-height": "14px" } },
            [
              _c("span", { staticClass: "font-size-12 font-italic" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("orders.fields.notes")) +
                    ": " +
                    _vm._s(_vm._f("shorten")(_vm.notes, 100))
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.nonClosedTicket == null || _vm.nonClosedTicket == undefined
        ? _c(
            "router-link",
            {
              staticClass: "btn btn-primary btn-sm",
              attrs: {
                to: { name: "tickets.create", query: { order_id: this.id } }
              }
            },
            [_c("span", [_c("i", { staticClass: "fa fa-plus" })])]
          )
        : _vm._e(),
      _vm.nonClosedTicket != null && _vm.nonClosedTicket != undefined
        ? _c(
            "router-link",
            {
              staticClass: "btn btn-primary btn-sm",
              attrs: {
                to: {
                  name: "tickets.show",
                  params: { id: this.nonClosedTicket.id }
                }
              }
            },
            [
              _c("span", [
                _c("i", {
                  class: {
                    "fa fa-envelope": _vm.nonClosedTicket.status == "ACTIVE",
                    "fa fa-envelope-open": _vm.nonClosedTicket.status == "OPEN"
                  }
                })
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: {
            "col-6 pr-1 order-show-btn":
              _vm.showAssignButton || _vm.showReAssignButton,
            "col-12": !_vm.showAssignButton && !_vm.showReAssignButton
          }
        },
        [
          _c(
            "a",
            {
              directives: [
                {
                  name: "can",
                  rawName: "v-can:view",
                  value: _vm.orderPermissionPrefix,
                  expression: "orderPermissionPrefix",
                  arg: "view"
                }
              ],
              staticClass:
                "text-white btn btn-block btn-sm btn-primary mt-1 font-size-12",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.showOrderInNewWindow()
                }
              }
            },
            [
              _vm._m(0),
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(" " + _vm._s(_vm.$t("crud.show")) + " ")
              ])
            ]
          )
        ]
      ),
      _vm.showAssignButton
        ? _c("div", { staticClass: "col-6 pl-1 order-manual-assign-btn" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "can",
                    rawName: "v-can:manually_assign",
                    value: _vm.orderPermissionPrefix,
                    expression: "orderPermissionPrefix",
                    arg: "manually_assign"
                  }
                ],
                staticClass:
                  "btn btn-block btn-sm btn-info text-white mt-1 font-size-12",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.showHideAssignmentPanel("MANUALLY_ASSIGN")
                  }
                }
              },
              [
                _c("span", { staticClass: "d-none d-lg-inline mr-2" }, [
                  _vm._v(_vm._s(_vm.$t("labels.assign")))
                ]),
                _vm._m(1)
              ]
            )
          ])
        : _vm._e(),
      _vm.showReAssignButton
        ? _c("div", { staticClass: "col-6 pl-1 order-manual-assign-btn" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "can",
                    rawName: "v-can:re_assign",
                    value: _vm.orderPermissionPrefix,
                    expression: "orderPermissionPrefix",
                    arg: "re_assign"
                  }
                ],
                staticClass:
                  "btn btn-block btn-sm btn-warning text-white mt-1 font-size-12",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.showHideAssignmentPanel("RE_ASSIGN")
                  }
                }
              },
              [
                _c("span", { staticClass: "d-none d-lg-inline mr-2" }, [
                  _vm._v(_vm._s(_vm.$t("labels.reassign")))
                ]),
                _vm._m(2)
              ]
            )
          ])
        : _vm._e(),
      _vm.isDisabled
        ? _c("div", { staticClass: "tracking-order-disable-overlay my--2" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "fas fa-eye" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "fas fa-random" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "fas fa-arrows-alt-h" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }