var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: { animation: false },
          expression: "{ animation: false }",
          modifiers: { hover: true }
        }
      ],
      staticClass: "text-orange px-1",
      attrs: {
        href: "javascript:;",
        "data-target": "#modal-retry-one",
        "data-toggle": "modal",
        title: _vm.$t("messages.retry_withdrawal_one_title")
      },
      on: {
        click: function($event) {
          return _vm.openRetryPopup(_vm.item)
        }
      }
    },
    [_c("i", { staticClass: "fas fa-sync" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }