import {
  CRUD_UPDATE_BREAD,
  CRUD_UPDATE_SEARCH,
  CRUD_SEARCH_RESET,
  CRUD_EXPORT_ACTION,
  CRUD_UPDATE_MODAL,
  CRUD_SEARCH_SHOW,
} from "../actions/crud";
import axioses from "@/axios/config";
import qs from "qs";

const state = {
  bread_title: "",
  bread_index_route:"",
  bread_show:false,
  bread_container_fluid:true,
  bread_add_primary_color:false,
  bread_items: [],
  search_filters:{},
  search_show:false,
  search_route_params:{},
  search_filters_map:{},
  modal_params:{}
};

const getters = {
  getBreadTitle: state => state.bread_title,
  getBreadIndexRoute: state => state.bread_index_route,
  getBreadItems: state => state.bread_items,
  isBreadShow: state => !!state.bread_show,
  isContainerFluid: state => !!state.bread_container_fluid,
  addPrimaryColor: state => !!state.bread_add_primary_color,
  isSearchShow: state => !!state.search_show,
  getModalParams:state=>state.modal_params,
  getSearchFilters(state){
    return state.search_filters;
  },
  getSearchFiltersIfExists(state,getters){
    return getters.filtersIsEmpty?{}:getters.getSearchFilters;
  },
  getSearchFiltersSensitized(state,getters){
    let filters={};
    Object.keys(getters.getSearchFilters).forEach(key=>{

      let mapValue=state.search_filters_map[key],
          originValue=getters.getSearchFilters[key];
      if(mapValue!==undefined){
        filters[mapValue]=originValue?originValue:null;
      }else{
        filters[key]=originValue?originValue:null;
      }
    });
    return filters;
  },
  getSubCategoriesSensitized(state,getters){
    let filters={};
    Object.keys(getters.getSubCategories).forEach(key=>{

      let mapValue=state.search_filters_map[key],
          originValue=getters.getSearchFilters[key];
      if(mapValue!==undefined){
        filters[mapValue]=originValue?originValue:null;
      }else{
        filters[key]=originValue?originValue:null;
      }
    });
    return filters;
  },
  filtersIsEmpty(state,getters){
    let isEmpty=true;
    Object.keys(getters.getSearchFilters).forEach(key=>{
      if(state.search_route_params[key]!==undefined){
        isEmpty=false;
      }
    });
    return isEmpty;
  },

};

const actions = {
  [CRUD_UPDATE_BREAD]: ({ commit, dispatch }, breadDetails) => {
    commit(CRUD_UPDATE_BREAD,breadDetails);
  },
  [CRUD_UPDATE_SEARCH]: ({ commit, dispatch }, searchDetails) => {
    commit(CRUD_UPDATE_SEARCH,searchDetails);
  },
  [CRUD_SEARCH_RESET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(CRUD_SEARCH_RESET);
      resolve();
    }).then(()=> {
      commit(CRUD_SEARCH_SHOW);
    });
  },
  [CRUD_SEARCH_SHOW]: ({ commit, dispatch }) => {
    commit(CRUD_SEARCH_SHOW);
  },
  [CRUD_EXPORT_ACTION]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', null, { root: true });

      let axios=axioses.getPortalByType(params.portalType);
      axios.get(`/${params.code}/report`,{ params ,paramsSerializer: params => {return qs.stringify(params.filters, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', null, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', null, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_UPDATE_MODAL]: ({ commit, dispatch }, modalDetails) => {
    commit(CRUD_UPDATE_MODAL,modalDetails);
  },
};

const mutations = {
  [CRUD_UPDATE_BREAD]: (state, breadDetails) => {
    state.bread_title = breadDetails.title;
    state.bread_show = breadDetails.show;
    state.bread_container_fluid = breadDetails.container_fluid??true;
    state.bread_add_primary_color = breadDetails.add_primary_color;
    state.bread_index_route = breadDetails.index_route;
    state.bread_items = breadDetails.items;
  },
  [CRUD_UPDATE_SEARCH]: (state, searchDetails) => {
    state.search_filters = searchDetails.items;
    state.search_route_params=searchDetails.routeParams;
    state.search_filters_map=searchDetails.filtersMap;
    state.search_show = searchDetails.show;
  },
  [CRUD_SEARCH_RESET]: (state) => {
    let items={};
    Object.keys(state.search_filters).forEach(key=>{
      items[key]=null;
    });
    state.search_filters=items;
    state.search_route_params={};
    // state.search_show=false;
  },
  [CRUD_SEARCH_SHOW]: (state) => {
    state.search_show=true;
  },
  [CRUD_UPDATE_MODAL]: (state, modalDetails) => {
    state.modal_params = modalDetails;
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
