<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Tabs -->
        <ul class="nav nav-tabs px-2" role="tablist">
          <li class="nav-item">
            <a href="#general" id="general-tab" aria-controls="general" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.general") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#comments" id="comments-tab"  aria-controls="comments" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.messages") }}
            </a>
          </li>
        </ul>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="tab-content" v-if="!serverError && isItemFound">
              <ticket-tabs-general :fields="getGeneralTabFields()" />
              <ticket-tabs-comments :isActiveTab="activeTab === '#comments'" :fields="getGeneralTabFields()" />
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
              <a class="btn btn-danger btn-sm" v-if="can_close_ticket"
                   data-target="#modal-ticket-close"
                   data-toggle="modal"
                   href="javascript:;"
                   v-b-tooltip.hover="{ animation: false}"
                   :title="$t('labels.close_ticket')"
                >
                  <i class="fas fa-ban"></i>
                </a>
                <a class="btn btn-primary btn-sm" v-if="can_reassign_ticket"
                   data-target="#modal-ticket-reassign"
                   data-toggle="modal"
                   href="javascript:;"
                   v-b-tooltip.hover="{ animation: false}"
                   :title="$t('labels.reassign_ticket')"
                >
                  <i class="fa fa-link"></i>
                </a>
            </div>
          </div>
      </div>
    </div>
    <div
            aria-hidden="true"
            aria-labelledby="modal-ticket-close"
            class="modal fade"
            id="modal-ticket-close"
            role="dialog"
            tabindex="-1"
    >
      <div
              class="modal-dialog modal-dialog-centered modal-lg"
              role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">
              {{ $t("labels.are_you_sure_you_want_to_close_ticket") }}
            </h6>
            <button
                    aria-label="Close"
                    class="close"
                    data-dismiss="modal"
                    type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-footer">
            <button
                    class="btn btn-primary ml-auto"
                    data-dismiss="modal"
                    type="button"
                    @click="closeTicket"
            >
              {{ $t("labels.yes") }}
            </button>
            <button class="btn btn-danger btn-sm ml-auto" data-dismiss="modal" type="button">
                {{ $t('labels.no') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
            aria-hidden="true"
            aria-labelledby="modal-ticket-reassign"
            class="modal fade"
            id="modal-ticket-reassign"
            role="dialog"
            tabindex="-1"
    >
      <div
              class="modal-dialog modal-dialog-centered modal-lg"
              role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">
              {{ $t("labels.are_you_sure_you_want_to_reassign_ticket") }}
            </h6>
            <button
                    aria-label="Close"
                    class="close"
                    data-dismiss="modal"
                    type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-footer">
            <button
                    class="btn btn-primary ml-auto"
                    data-dismiss="modal"
                    type="button"
                    @click="reassignTicket"
            >
              {{ $t("labels.yes") }}
            </button>
            <button class="btn btn-danger btn-sm ml-auto" data-dismiss="modal" type="button">
                {{ $t('labels.no') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { TICKETS_SHOW as CRUD_ACTION_SHOW, TICKETS_CLOSE as CRUD_ACTION_CLOSE, TICKETS_REASSIGN as CRUD_ACTION_REASSIGN } from 'actions/tickets';
  import TicketTabsGeneral from './tabs/general';
  import TicketTabsComments from './tabs/comments';
  import $ from "jquery";
  import {mapGetters} from "vuex";
  
  const CRUD_CODE="tickets";
  const CRUD_PERMISSION_CODE=`${CRUD_CODE}`;

  export default {
    name: "TicketShow",
    components: {
      TicketTabsGeneral,
      TicketTabsComments,
    },
    computed: {
      ...mapGetters(["getProfile"]),
    },
    data(){
      return {
        can_close_ticket: false,
        can_reassign_ticket: false,
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: `${CRUD_PERMISSION_CODE}`,
        id:this.$router.currentRoute.params.id,
        status: '',
        owner_id: '',
        owner_type: '',
        related_id: '',
        related_type: '',
        created_at: '',
        serverError: '',
        isItemFound: false,
        activeTab: "#general"
      }
    },
    mounted() {
      this.getItem()
    },
    methods: {
      reassignTicket() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_REASSIGN,{
          id: this.id
        }).then(response => {
          this.loading = true;
          this.status = response.data.status;
          this.can_close_ticket = response.data.assigneeId == this.getProfile.id && this.status != "CLOSED"
          this.can_reassign_ticket = this.status != "CLOSED"
          this.owner_id = response.data.ownerId;
          this.owner_type = response.data.ownerType;
          this.related_id = response.data.relatedToId;
          this.related_type = response.data.relatedToType;
          this.created_at = response.data.createdAt ? this.$moment(response.data.createdAt).format("Y-MM-DD hh:mm:ss A") : "";
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      closeTicket() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CLOSE,{
          id: this.id
        }).then(response => {
          this.loading = true;
          this.$router.push({ name: `${this.routePrefix}.index` })
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      getItem() {
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id
        }).then(response => {
          this.status = response.data.status;
          this.can_close_ticket = response.data.assigneeId == this.getProfile.id && this.status != "CLOSED"
          this.can_reassign_ticket = this.status != "CLOSED"
          this.owner_id = response.data.ownerId;
          this.owner_type = response.data.ownerType;
          this.related_id = response.data.relatedToId;
          this.related_type = response.data.relatedToType;
          this.created_at = response.data.createdAt ? this.$moment(response.data.createdAt).format("Y-MM-DD hh:mm:ss A") : "";
          this.isItemFound = true
        }).then(() => {
          $("a[data-toggle='tab']").on("shown.bs.tab", e => (this.activeTab = $(e.target).attr("href")));
          const hash = this.$router.currentRoute.hash
          if (hash) {
            $(`a[href="${hash}"]`).tab('show')
            this.$router.replace({ hash: "" });
          } else {
            $(`a[href="#general"]`).tab('show')
          }
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      getGeneralTabFields() {
        return {
          status: this.status,
          owner_id: this.owner_id,
          owner_type: this.owner_type,
          related_id: this.related_id,
          related_type: this.related_type,
          created_at: this.created_at
        }
      }
    }
  };
</script>
