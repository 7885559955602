var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("abstract-input-container", {
    attrs: { options: Object.assign({}, _vm.defaultOptions, _vm.options) },
    scopedSlots: _vm._u(
      [
        {
          key: "hint",
          fn: function() {
            return [
              !_vm.thumbnail && !_vm.defaultOptions.disabled
                ? _c(
                    "div",
                    {
                      staticClass: "custom-theme-file",
                      class: { "is-invalid": _vm.defaultOptions.error }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "btn btn-success btn-sm",
                          attrs: { for: _vm.defaultOptions.id }
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("labels.upload")) + " "),
                          _c("i", { staticClass: "fa fa-upload" })
                        ]
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "custom-theme-file",
                      class: { "is-invalid": _vm.defaultOptions.error }
                    },
                    [
                      _vm.defaultOptions.downloadButton
                        ? _c("img", {
                            staticClass:
                              "img-thumbnail mh-100 mw-100 w-50 h-50 mb-2 d-block",
                            attrs: { src: _vm.getThumbnail() }
                          })
                        : _c(
                            "a",
                            {
                              attrs: { href: _vm.thumbnail, target: "_blank" }
                            },
                            [
                              _vm.thumbnail
                                ? _c("img", {
                                    staticClass:
                                      "img-thumbnail mh-100 mw-100 w-50 h-50 mb-2 d-block",
                                    attrs: { src: _vm.getThumbnail() }
                                  })
                                : _vm._e()
                            ]
                          ),
                      _vm.originalName
                        ? _c("h6", [_vm._v(" " + _vm._s(_vm.originalName))])
                        : _vm._e(),
                      !_vm.defaultOptions.disabled
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-danger btn-sm",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeImage($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("labels.delete_item")) + " "
                              ),
                              _c("i", { staticClass: "fa fa-trash" })
                            ]
                          )
                        : _vm._e(),
                      _vm.defaultOptions.downloadButton
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-primary btn-sm",
                              attrs: {
                                href: _vm.thumbnail,
                                target: "_blank",
                                download: ""
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("labels.download")) + " "
                              ),
                              _c("i", { staticClass: "fa fa-download" })
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
              _vm._t("hint")
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }