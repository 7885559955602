var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tickets-view-container" }, [
    _c(
      "div",
      { staticClass: "tickets-body" },
      _vm._l(_vm.filteredTickets, function(ticket, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "ticket",
            on: {
              click: function($event) {
                return _vm.onTicketClicked(ticket)
              }
            }
          },
          [
            _c("div", { staticClass: "ticket-details" }, [
              _c("div", { staticClass: "ticket-main-info" }, [
                _vm._v("Ticket ID: " + _vm._s(ticket.id))
              ]),
              _c("div", { staticClass: "ticket-name" }, [
                _vm._v("ID/Captain’s Name")
              ]),
              _c("div", { staticClass: "ticket-main-info" }, [
                _vm._v("#" + _vm._s(ticket.number))
              ])
            ]),
            _c("div", { staticClass: "ticket-status" }, [
              _c("div", { staticClass: "status-active" }, [
                _vm._v(_vm._s(ticket.status))
              ]),
              _c("div", { staticClass: "ticket-name" }, [
                _vm._v(_vm._s(ticket.name))
              ]),
              _c(
                "div",
                {
                  staticClass: "ticket-location",
                  class: _vm.getLocationClass(ticket.location)
                },
                [_vm._v(" " + _vm._s(ticket.location) + " ")]
              )
            ])
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "tickets-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn-rounded",
          class: { focus: _vm.currentFilter === "MY_ACTIVE" },
          on: {
            click: function($event) {
              return _vm.setFilter("MY_ACTIVE")
            }
          }
        },
        [_vm._v(" My Active ")]
      ),
      _c(
        "button",
        {
          staticClass: "btn-rounded",
          class: { focus: _vm.currentFilter === "ALL" },
          on: {
            click: function($event) {
              return _vm.setFilter("ALL")
            }
          }
        },
        [_vm._v(" All ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }