var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "multiselect",
    {
      class: { "is-invalid": _vm.error },
      attrs: {
        id: _vm.id,
        disabled: _vm.disabled,
        "track-by": "id",
        searchable: true,
        "custom-label": _vm.customLabel,
        "close-on-select": true,
        "show-labels": false,
        placeholder: _vm.$t(_vm.placeholder),
        "open-direction": "bottom",
        options: _vm.items,
        loading: _vm.isLoading,
        "internal-search": false,
        "hide-selected": false,
        "clear-on-select": false,
        "options-limit": 300,
        limit: 3,
        "max-height": 600,
        "show-no-results": true
      },
      on: {
        open: _vm.open,
        input: _vm.updateValue,
        "search-change": _vm.getList
      },
      scopedSlots: _vm._u([
        {
          key: "option",
          fn: function(props) {
            return [_vm._v(_vm._s(_vm.customLabel(props.option)))]
          }
        },
        {
          key: "clear",
          fn: function(props) {
            return [
              _vm.selectedValue
                ? _c("div", {
                    staticClass: "multiselect__clear",
                    on: {
                      mousedown: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.clearSelected(props.search)
                      }
                    }
                  })
                : _vm._e()
            ]
          }
        }
      ]),
      model: {
        value: _vm.selectedValue,
        callback: function($$v) {
          _vm.selectedValue = $$v
        },
        expression: "selectedValue"
      }
    },
    [
      _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
        _vm._v(_vm._s(_vm.$t(_vm.noResultMessage)))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }