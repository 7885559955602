var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c(
            "card-header",
            { attrs: { title: _vm.$router.currentRoute.meta.title } },
            [
              !_vm.serverError
                ? _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:edit",
                          value: _vm.permissionPrefix,
                          expression: "permissionPrefix",
                          arg: "edit"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm",
                      attrs: {
                        to: {
                          name: _vm.routePrefix + ".edit",
                          params: { id: _vm.id }
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  )
                : _vm._e(),
              _vm.deleted
                ? _c("restore-captain-action", {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:restore",
                        value: _vm.permissionPrefix,
                        expression: "permissionPrefix",
                        arg: "restore"
                      }
                    ],
                    attrs: { "captain-id": _vm.id },
                    on: { success: _vm.getItem }
                  })
                : _vm._e(),
              _c("reset-password-action", {
                directives: [
                  {
                    name: "can",
                    rawName: "v-can:send_reset_password",
                    value: _vm.permissionPrefix,
                    expression: "permissionPrefix",
                    arg: "send_reset_password"
                  }
                ],
                attrs: { itemID: _vm.id }
              })
            ],
            1
          ),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("files-image", {
                      attrs: {
                        options: {
                          id: "image",
                          label: "image",
                          disabled: true,
                          containerClass: "col-12 col-md-4"
                        }
                      },
                      model: {
                        value: _vm.image,
                        callback: function($$v) {
                          _vm.image = $$v
                        },
                        expression: "image"
                      }
                    }),
                    _c("files-image", {
                      attrs: {
                        options: {
                          id: "identity",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "identity",
                          disabled: true,
                          containerClass: "col-12 col-md-4"
                        }
                      },
                      model: {
                        value: _vm.identity,
                        callback: function($$v) {
                          _vm.identity = $$v
                        },
                        expression: "identity"
                      }
                    }),
                    _c("files-image", {
                      attrs: {
                        options: {
                          id: "driving_license",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "driving_license",
                          disabled: true,
                          containerClass: "col-12 col-md-4"
                        }
                      },
                      model: {
                        value: _vm.driving_license,
                        callback: function($$v) {
                          _vm.driving_license = $$v
                        },
                        expression: "driving_license"
                      }
                    }),
                    _c("files-image", {
                      attrs: {
                        options: {
                          id: "registration_license",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "registration_license",
                          disabled: true,
                          containerClass: "col-12 col-md-4"
                        }
                      },
                      model: {
                        value: _vm.registration_license,
                        callback: function($$v) {
                          _vm.registration_license = $$v
                        },
                        expression: "registration_license"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-text", {
                      attrs: {
                        options: { id: "name", label: "name", disabled: true }
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    }),
                    _c("input-email", {
                      attrs: {
                        options: { id: "email", label: "email", disabled: true }
                      },
                      model: {
                        value: _vm.email,
                        callback: function($$v) {
                          _vm.email = $$v
                        },
                        expression: "email"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: { id: "city", label: "city", disabled: true }
                      },
                      model: {
                        value: _vm.city_name,
                        callback: function($$v) {
                          _vm.city_name = $$v
                        },
                        expression: "city_name"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "mobile_number",
                          label: "mobile_number",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.mobile_number,
                        callback: function($$v) {
                          _vm.mobile_number = $$v
                        },
                        expression: "mobile_number"
                      }
                    }),
                    _c("input-date", {
                      attrs: {
                        options: {
                          id: "date_of_birth",
                          label: "date_of_birth",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.date_of_birth,
                        callback: function($$v) {
                          _vm.date_of_birth = $$v
                        },
                        expression: "date_of_birth"
                      }
                    }),
                    _c("div", { staticClass: "col-12 col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-block",
                              attrs: { for: "nationality" }
                            },
                            [_vm._v(_vm._s(_vm.$t("fields.nationality")))]
                          ),
                          _vm.loadNationality()
                            ? _c("vue-country-code", {
                                staticClass: "w-25",
                                attrs: {
                                  id: "nationality",
                                  defaultCountry: _vm.nationality,
                                  dropdownOptions: { disabledDialCode: true },
                                  disabled: true
                                },
                                on: { onSelect: _vm.onSelectNationality }
                              })
                            : _vm._e(),
                          _c("input-text", {
                            attrs: {
                              value: _vm.nationality_label,
                              options: {
                                disabled: true,
                                fullLayout: false,
                                inputClass: "form-control w-75 d-inline-block"
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "id_number",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "id_number",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.id_number,
                        callback: function($$v) {
                          _vm.id_number = $$v
                        },
                        expression: "id_number"
                      }
                    }),
                    _c("select-captain-id-type", {
                      attrs: {
                        options: {
                          id: "id_type",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "id_type",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.id_type,
                        callback: function($$v) {
                          _vm.id_type = $$v
                        },
                        expression: "id_type"
                      }
                    }),
                    _c("select-captain-status", {
                      attrs: {
                        options: {
                          id: "status",
                          label: "status",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    }),
                    _c("select-captain-working-status", {
                      attrs: {
                        options: {
                          id: "working_status",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "working_status",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.working_status,
                        callback: function($$v) {
                          _vm.working_status = $$v
                        },
                        expression: "working_status"
                      }
                    }),
                    _c("capabilities-field", {
                      attrs: {
                        itemID: _vm.id,
                        permissionPrefix: _vm.permissionPrefix,
                        capabilities: _vm.capabilities
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "supplier",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "supplier",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.supplier,
                        callback: function($$v) {
                          _vm.supplier = $$v
                        },
                        expression: "supplier"
                      }
                    }),
                    _vm.referralCode
                      ? _c("input-text", {
                          attrs: {
                            options: {
                              id: "referral_code",
                              transPrefix: _vm.transPrefix + ".fields",
                              label: "referral_code",
                              disabled: true
                            }
                          },
                          model: {
                            value: _vm.referralCode,
                            callback: function($$v) {
                              _vm.referralCode = $$v
                            },
                            expression: "referralCode"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-checkbox", {
                      attrs: {
                        options: {
                          id: "ready_to_work",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "ready_to_work",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.ready_to_work,
                        callback: function($$v) {
                          _vm.ready_to_work = $$v
                        },
                        expression: "ready_to_work"
                      }
                    }),
                    _c("input-checkbox", {
                      attrs: {
                        options: {
                          id: "connected",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "connected",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.connected,
                        callback: function($$v) {
                          _vm.connected = $$v
                        },
                        expression: "connected"
                      }
                    }),
                    _c("input-checkbox", {
                      attrs: {
                        options: {
                          id: "suspended",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "suspended",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.suspended,
                        callback: function($$v) {
                          _vm.suspended = $$v
                        },
                        expression: "suspended"
                      }
                    }),
                    _c("input-checkbox", {
                      attrs: {
                        options: {
                          id: "deleted",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "deleted",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.deleted,
                        callback: function($$v) {
                          _vm.deleted = $$v
                        },
                        expression: "deleted"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  ),
                  _vm.suspensionReasons.length > 0
                    ? _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              value: { animation: false },
                              expression: "{ animation: false}",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "btn btn-danger btn-sm",
                          attrs: {
                            "data-target": "#modal-suspension-reasons",
                            "data-toggle": "modal",
                            href: "javascript:;",
                            title: _vm.$t("labels.cancellation_reasons")
                          }
                        },
                        [_c("i", { staticClass: "fas fa-ban" })]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          "aria-hidden": "true",
          "aria-labelledby": "modal-suspension-reasons",
          id: "modal-suspension-reasons",
          role: "dialog",
          tabindex: "-1"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered modal-lg",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h6",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "modal-title-default" }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("labels.suspension_reasons")) + " "
                    )
                  ]
                ),
                _vm._m(0)
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "ul",
                    { staticClass: "list" },
                    _vm._l(_vm.suspensionReasons, function(
                      suspensionReason,
                      index
                    ) {
                      return _c("li", { key: index }, [
                        _vm._v(" " + _vm._s(suspensionReason.reason) + " "),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#1496ba",
                              "font-size": "12px"
                            }
                          },
                          [_vm._v(" -" + _vm._s(suspensionReason.suspendedBy))]
                        )
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary ml-auto",
                    attrs: { "data-dismiss": "modal", type: "button" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("labels.close")) + " ")]
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }