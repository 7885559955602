var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      attrs: {
        "aria-labelledby": "general-tab",
        id: "general",
        role: "tabpanel"
      }
    },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("input-text", {
            attrs: {
              options: { id: "owner_id", label: "owner_id", disabled: true }
            },
            model: {
              value: _vm.fields.owner_id,
              callback: function($$v) {
                _vm.$set(_vm.fields, "owner_id", $$v)
              },
              expression: "fields.owner_id"
            }
          }),
          _c("select-ticket-owner-types", {
            attrs: {
              options: {
                id: "ticket_owner_type",
                label: "owner_type",
                disabled: true
              }
            },
            model: {
              value: _vm.fields.owner_type,
              callback: function($$v) {
                _vm.$set(_vm.fields, "owner_type", $$v)
              },
              expression: "fields.owner_type"
            }
          }),
          _c("input-text", {
            attrs: {
              options: { id: "related_id", label: "related_id", disabled: true }
            },
            model: {
              value: _vm.fields.related_id,
              callback: function($$v) {
                _vm.$set(_vm.fields, "related_id", $$v)
              },
              expression: "fields.related_id"
            }
          }),
          _c("select-ticket-related-types", {
            attrs: {
              options: {
                id: "ticket_related_type",
                label: "related_type",
                disabled: true
              }
            },
            model: {
              value: _vm.fields.related_type,
              callback: function($$v) {
                _vm.$set(_vm.fields, "related_type", $$v)
              },
              expression: "fields.related_type"
            }
          }),
          _c("input-text", {
            attrs: {
              options: { id: "created_at", label: "created_at", disabled: true }
            },
            model: {
              value: _vm.fields.created_at,
              callback: function($$v) {
                _vm.$set(_vm.fields, "created_at", $$v)
              },
              expression: "fields.created_at"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }