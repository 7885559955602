var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      attrs: {
        "aria-labelledby": "transactions-tab",
        id: "transactions",
        role: "tabpanel"
      }
    },
    [
      _c("table-container", {
        attrs: { headers: _vm.tableHeaders },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm._l(_vm.transactions, function(transaction) {
                  return _c("tr", { key: transaction.id }, [
                    _c("td", [_vm._v(" " + _vm._s(transaction.id) + " ")]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("moment")(
                              transaction.actionAt,
                              "Y-MM-DD hh:mm:ss A"
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("td", [
                      _vm._v(
                        " " + _vm._s(transaction.transactionReference) + " "
                      )
                    ]),
                    _c("td", [
                      _vm._v(" " + _vm._s(transaction.reference) + " ")
                    ]),
                    _c("td", [
                      _vm._v(" " + _vm._s(transaction.merchantReference) + " ")
                    ]),
                    _c("td", [_vm._v(" " + _vm._s(transaction.status) + " ")])
                  ])
                }),
                !_vm.transactions.length
                  ? _c("tr", { staticClass: "text-center" }, [
                      _c("td", { attrs: { colspan: "999" } }, [
                        _vm._v(_vm._s(_vm.$t("messages.no_result_found")))
                      ])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }