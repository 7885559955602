var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("card-container", {
          attrs: {
            title: _vm.$router.currentRoute.meta.title,
            headers: _vm.tableHeaders,
            lastPage: _vm.lastPage,
            totalElements: _vm.totalElements,
            currentPage: _vm.getCurrentPage(),
            clickHandler: _vm.pagination
          },
          scopedSlots: _vm._u([
            {
              key: "card-header",
              fn: function() {
                return [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          value: { animation: false },
                          expression: "{ animation: false }",
                          modifiers: { hover: true }
                        },
                        {
                          name: "can",
                          rawName: "v-can:create",
                          value: _vm.permissionPrefix,
                          expression: "permissionPrefix",
                          arg: "create"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm",
                      attrs: {
                        to: { name: _vm.routePrefix + ".create" },
                        title: _vm.$t(_vm.routePrefix + ".create_title")
                      }
                    },
                    [_c("i", { staticClass: "fa fa-plus" })]
                  ),
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          value: { animation: false },
                          expression: "{ animation: false}",
                          modifiers: { hover: true }
                        },
                        {
                          name: "can",
                          rawName: "v-can:view",
                          value: _vm.permissionPrefix,
                          expression: "permissionPrefix",
                          arg: "view"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm",
                      attrs: {
                        to: { name: _vm.routePrefix + ".categories.index" },
                        title: _vm.$t(_vm.routePrefix + ".categories.title")
                      }
                    },
                    [_c("i", { staticClass: "fa fa-cogs" })]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "card-header-search",
              fn: function() {
                return [
                  _c("search", {
                    attrs: { listHandler: _vm.getList },
                    model: {
                      value: _vm.lastPage,
                      callback: function($$v) {
                        _vm.lastPage = $$v
                      },
                      expression: "lastPage"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "table-rows",
              fn: function() {
                return [
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      "tr",
                      { key: item.id },
                      [
                        _c("td", [_vm._v(" " + _vm._s(item.id) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.status) + " ")]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  _vm.transPrefix +
                                    ".lists.owner_type." +
                                    item.ownerType
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.ownerType == "SYSTEM" ? "" : item.ownerId
                              ) +
                              " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.ownerType == "SYSTEM"
                                  ? "System"
                                  : item.ownerName
                              ) +
                              " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.assigneeName || "") + " ")
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  _vm.transPrefix +
                                    ".lists.related_type." +
                                    item.relatedToType
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.relatedToId) + " ")
                        ]),
                        _c("td", { staticClass: "text-direction-ltr" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  item.createdAt,
                                  "Y-MM-DD hh:mm:ss A"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("table-item-actions", {
                          attrs: {
                            actions: _vm.tableItemActions,
                            params: { id: item.id }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  !_vm.items.length
                    ? _c("tr", { staticClass: "text-center" }, [
                        _c("td", { attrs: { colspan: "999" } }, [
                          _vm._v(_vm._s(_vm.$t("messages.no_result_found")))
                        ])
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }