import {
  TRACKING_ADD_CAPTAINS,
  TRACKING_ADD_ORDERS,
  TRACKING_ADD_UPDATE_ORDER,
  TRACKING_ASSIGNMENT_CANDIDATES_LIST,
  TRACKING_CAPTAIN_SHOW,
  TRACKING_CAPTAINS_LIST,
  TRACKING_DELETE_CAPTAIN,
  TRACKING_DELETE_ORDER,
  TRACKING_ORDERS_LIST,
  TRACKING_RESET_CAPTAINS,
  TRACKING_SET_ACTIVE_ORDER,
  TRACKING_SET_ACTIVE_CAPTAIN,
  TRACKING_SET_HIGHLIGHTED_CANDIDATE,
  TRACKING_SET_MAP_CONFIG,
  TRACKING_ADD_UPDATE_CAPTAIN,
  TRACKING_RESET_ORDERS,
  TRACKING_ORDERS_LOADING,
  TRACKING_SET_MAP_FILTERS,
  TRACKING_SET_SELECTED_TICKET
} from "actions/sockets/tracking";
import { AUTH_LOGOUT } from "actions/auth";
import axioses from "@/axios/config";
import qs from "qs";
import Vue from "vue";

const routePrefix = 'tracking';

const state = {
  captains: {},
  orders: {},
  isOrdersLoading: false,
  activeOrder: {},
  activeCaptain: {},
  highlightedCandidate: null,
  mapConfig: localStorage.getItem("tracking_map_configuration")
      ? JSON.parse(localStorage.getItem("tracking_map_configuration"))
      : { awaitingOrdersEnabled: true, activeOrdersEnabled: true, countersEnabled: true },
  mapFilters: localStorage.getItem("tracking_map_filters")
      ? JSON.parse(localStorage.getItem("tracking_map_filters"))
      : { countryId: null, cityIds: null, platformId: null, brandIds: null },
  selectedTicket: {},
};

const getters = {
  getStompConnectionUrl: function(state, getters, rootState, rootGetters) {
    return rootGetters.isStompLoaded ? rootGetters.getStomp.connectionUrl : "";
  },
  getStompConnectionsTopic: function(state, getters, rootState, rootGetters) {
    return rootGetters.isStompLoaded ? rootGetters.getStomp.connectionsTopic : "";
  },
  getStompLocationsTopic: function(state, getters, rootState, rootGetters) {
    return rootGetters.isStompLoaded ? rootGetters.getStomp.locationsTopic : "";
  },
  getStompReadyToWorkTopic: function(state, getters, rootState, rootGetters) {
    return rootGetters.isStompLoaded ? rootGetters.getStomp.readyToWorkTopic : "";
  },
  getStompOrdersTopic: function(state, getters, rootState, rootGetters) {
    return rootGetters.isStompLoaded ? rootGetters.getStomp.ordersTopic : "";
  },
  getCaptains: function(state) {
    return state.captains;
  },
  getActiveOrder: function(state) {
    return state.activeOrder;
  },
  getActiveCaptain: function(state) {
    return state.activeCaptain;
  },
  getMapConfig: function(state) {
    return state.mapConfig;
  },
  getMapFilters: function(state) {
    return state.mapFilters;
  },
  getHighlightedCandidate: function(state) {
    return state.highlightedCandidate;
  },
  getOrders: function(state) {
    return state.orders;
  },
  isOrdersLoading: function(state) {
    return state.isOrdersLoading;
  },
  getSelectedTicket: function(ticket) {
    return state.selectedTicket;
  }
};

const actions = {
  [TRACKING_ASSIGNMENT_CANDIDATES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      const type = { type: params.type };
      axioses.base
          .get(`/${routePrefix}/orders/${params.id}/assignment_candidates`,{ params: type })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [TRACKING_CAPTAINS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
          .get(`/${routePrefix}/captains`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [TRACKING_CAPTAIN_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', null, { root: true });
      axioses.base
          .get(`/${routePrefix}/captains/${params.id}`)
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', null, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', null, { root: true });
            reject(error);
          });
    });
  },
  [TRACKING_ORDERS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit(TRACKING_ORDERS_LOADING, true);
      axioses.base
          .get(`/${routePrefix}/orders`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit(TRACKING_ORDERS_LOADING, false);
          })
          .catch(error => {
            reject(error);
            commit(TRACKING_ORDERS_LOADING, false);
          });
    });
  },
};

const mutations = {
  [TRACKING_ADD_CAPTAINS]: (state, captains) => {
    state.captains = { ...state.captains, ...captains };
  },
  [TRACKING_ADD_UPDATE_CAPTAIN]: (state, captain) => {
    if (state.captains[captain.id]) {
      state.captains[captain.id] = { ...state.captains[captain.id], ...captain };
    } else {
      Vue.set(state.captains, captain.id, captain);
    }
  },
  [TRACKING_DELETE_CAPTAIN]: (state, captain) => {
    if (state.captains[captain.id]) {
      Vue.delete(state.captains, captain.id);
    }
  },
  [TRACKING_RESET_CAPTAINS]: (state) => {
    state.captains = {};
  },
  [AUTH_LOGOUT]: state => {
    state.captains = {};
  },
  [TRACKING_SET_ACTIVE_ORDER]: (state, activeOrder) => {
    state.activeOrder = activeOrder;
  },
  [TRACKING_SET_ACTIVE_CAPTAIN]: (state, activeCaptain) => {
    state.activeCaptain = activeCaptain;
  },
  [TRACKING_SET_MAP_CONFIG]: (state, mapConfig) => {
    state.mapConfig = mapConfig;
    localStorage.setItem("tracking_map_configuration", JSON.stringify(state.mapConfig));
  },
  [TRACKING_SET_MAP_FILTERS]: (state, mapFilters) => {
    state.mapFilters = mapFilters;
    localStorage.setItem("tracking_map_filters", JSON.stringify(state.mapFilters));
  },
  [TRACKING_SET_HIGHLIGHTED_CANDIDATE]: (state, highlightedCandidate) => {
    state.highlightedCandidate = highlightedCandidate;
  },
  [TRACKING_ADD_ORDERS]: (state, orders) => {
    state.orders = { ...state.orders, ...orders };
  },
  [TRACKING_ADD_UPDATE_ORDER]: (state, order) => {
    if (state.orders[order.id]) {
      state.orders[order.id] = { ...state.orders[order.id], ...order };
    } else {
      Vue.set(state.orders, order.id, order);
    }
  },
  [TRACKING_DELETE_ORDER]: (state, order) => {
    if (state.orders[order.id]) {
      Vue.delete(state.orders, order.id);
    }
  },
  [TRACKING_RESET_ORDERS]: (state) => {
    state.orders = {};
  },
  [TRACKING_ORDERS_LOADING]: (state, isOrdersLoading) => {
    state.isOrdersLoading = isOrdersLoading;
  },
  [TRACKING_SET_SELECTED_TICKET]: (state, ticket) => {
    state.selectedTicket = ticket;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
