var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom"
    },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "navbarSupportedContent  ml-md-0 " }
          },
          [
            _c(
              "ul",
              { staticClass: "navbar-nav align-items-center" },
              [_c("TopNavbarSideMenu"), _c("TopNavbarNotifications")],
              1
            ),
            _c(
              "ul",
              { staticClass: "navbar-nav align-items-center ml-auto " },
              [_c("TopNavbarLanguageSwitcher")],
              1
            ),
            _c("TopNabarProfile")
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }