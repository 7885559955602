<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${ticketsRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
            <i class="fa fa-arrow-left"></i>
          </router-link>
          <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm"  v-can:add="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList"/>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td>
              <div class="media align-items-center">
                <a v-if="getImageThumbnail(item)" href="javascript:;" class="avatar rounded-circle">
                  <img alt="Image placeholder" :src="getImageThumbnail(item)">
                </a>
                <div class="media-body">
                  <span class="name mb-0 text-sm"></span>
                </div>
              </div>
            </td>
            <td style="  white-space: normal; word-wrap: break-word; overflow-wrap: break-word;"> {{ item.name }} </td>
            <td> {{ $t(`${transPrefix}.lists.type.${item.type}`) }} </td>
            <td> {{ $t(`lists.language_code.${item.languageCode}`) }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <table-item-actions :actions="tableItemActions" :params="{'id':item.id}"/>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import {mapGetters} from "vuex";
  import { TICKETS_CATEGORIES_LIST as CRUD_ACTION_LIST, TICKETS_CATEGORIES_UPDATE_STATUS as CRUD_ACTION_UPDATE_STATUS, TICKETS_CATEGORIES_DELETE as CRUD_ACTION_DELETE  } from 'actions/tickets';
  import search from "./partials/search";
  const TICKETS_CODE="tickets";
  const CRUD_CODE=`${TICKETS_CODE}.categories`;
  const CRUD_PERMISSION_CODE=`${TICKETS_CODE}`;

  export default {
    name: "TicketCategoryIndex",
    components:{
      search,
    },
    data(){

      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        ticketsRoutePrefix: TICKETS_CODE,
        ticketsTransPrefix: TICKETS_CODE,
        tableHeaders:[
          {
            'title':`fields.icon`
          },
          {
            'title':`fields.name`
          },
          {
            'title':`fields.type`
          },
          {
            'title':`fields.language_code`
          },
          {
            'title':`fields.created_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        tableItemActions:[
          {
            code:'show',
            route_name:`${CRUD_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
          {
            code:'edit',
            route_name:`${CRUD_CODE}.edit`,
            can:`${CRUD_PERMISSION_CODE}_can_edit`,
          },
          {
            code:'delete',
            can:`${CRUD_PERMISSION_CODE}_can_delete`,
            action:CRUD_ACTION_DELETE,
            successCallback:this.getList
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    computed:{
      ...mapGetters([
        "getSearchFilters","getSearchFiltersIfExists","getSearchFiltersSensitized"
      ])
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          pageId: this.getCurrentPage()-1,
          ...this.getSearchFiltersSensitized
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        })
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page,
              ...this.getSearchFiltersIfExists
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
      changeStatus(event, params){
        this.$store.dispatch(CRUD_ACTION_UPDATE_STATUS, {
          id: params.id,
          value: params.value,
          data: {}
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
        })
        .catch(error => {
          let message="";
          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors && responseData.errors.error){
              message = responseData.errors.error;
            }
            else{
              message = this.$i18n.t("messages.update_error");
            }
          }
          if(message){
            swal({
              icon: 'error',
              text: message,
              buttons:false,
              timer: 5000
            });
          }
        })
      },
      getImageThumbnail(item){
        let image='';
        if(item.icon){
          image=item.icon.url;
        }
        return image;
      }
    }
  };
</script>
