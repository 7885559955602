var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.defaultOptions.fullLayout
    ? _c("div", { class: [_vm.defaultOptions.containerClass] }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            ["checkbox", "checkbox-custom"].includes(_vm.defaultOptions.type)
              ? [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-8" },
                      [
                        _vm.getLabel()
                          ? _c(
                              "label",
                              { attrs: { for: _vm.defaultOptions.id } },
                              [_vm._v(_vm._s(_vm.$t(_vm.getLabel())))]
                            )
                          : _vm._e(),
                        _vm._t("hint"),
                        !_vm.defaultOptions.disabled && _vm.defaultOptions.error
                          ? _c(
                              "span",
                              {
                                staticClass: "invalid-feedback d-block",
                                attrs: { role: "alert" }
                              },
                              [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.defaultOptions.error))
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4 text-right" },
                      [
                        _c("abstract-input", {
                          attrs: {
                            options: Object.assign(
                              {},
                              _vm.defaultOptions,
                              _vm.options
                            )
                          },
                          model: {
                            value: _vm.selectedValue,
                            callback: function($$v) {
                              _vm.selectedValue = $$v
                            },
                            expression: "selectedValue"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              : [
                  _vm.getLabel()
                    ? _c("label", { attrs: { for: _vm.defaultOptions.id } }, [
                        _vm._v(_vm._s(_vm.$t(_vm.getLabel())))
                      ])
                    : _vm._e(),
                  _vm._t("hint"),
                  _c("abstract-input", {
                    attrs: {
                      options: Object.assign(
                        {},
                        _vm.defaultOptions,
                        _vm.options
                      )
                    },
                    model: {
                      value: _vm.selectedValue,
                      callback: function($$v) {
                        _vm.selectedValue = $$v
                      },
                      expression: "selectedValue"
                    }
                  }),
                  !_vm.defaultOptions.disabled && _vm.defaultOptions.error
                    ? _c(
                        "span",
                        {
                          staticClass: "invalid-feedback",
                          attrs: { role: "alert" }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.defaultOptions.error))
                          ])
                        ]
                      )
                    : _vm._e()
                ]
          ],
          2
        )
      ])
    : _c("abstract-input", {
        attrs: { options: Object.assign({}, _vm.defaultOptions, _vm.options) },
        model: {
          value: _vm.selectedValue,
          callback: function($$v) {
            _vm.selectedValue = $$v
          },
          expression: "selectedValue"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }