var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isBreadShow
    ? _c("div", { staticClass: "header bg-primary pb-6" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "header-body" }, [
            _c("div", { staticClass: "row align-items-center py-4" }, [
              _c("div", { staticClass: "col-lg-9 col-9" }, [
                _c("h6", { staticClass: "h2 text-white d-inline-block mb-0" }, [
                  _vm._v(_vm._s(_vm.$t(_vm.getBreadTitle)))
                ]),
                _c(
                  "nav",
                  {
                    staticClass: "d-none d-md-inline-block ml-md-4",
                    attrs: { "aria-label": "breadcrumb" }
                  },
                  [
                    _c(
                      "ol",
                      {
                        staticClass:
                          "breadcrumb breadcrumb-links breadcrumb-dark"
                      },
                      [
                        _c(
                          "li",
                          { staticClass: "breadcrumb-item" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: { name: "dashboard.index" } } },
                              [_c("i", { staticClass: "fas fa-home" })]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "breadcrumb-item" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: { to: { name: _vm.getBreadIndexRoute } }
                              },
                              [_vm._v(_vm._s(_vm.$t(_vm.getBreadTitle)))]
                            )
                          ],
                          1
                        ),
                        _vm._l(_vm.getBreadItems, function(item) {
                          return [
                            _c(
                              "li",
                              {
                                staticClass: "breadcrumb-item",
                                class: { active: !item.link },
                                attrs: { "aria-current": "page" }
                              },
                              [_vm._v(_vm._s(_vm.$t(item.title)))]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }