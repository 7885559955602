<template>
  <div>
    <div v-if="editable" class="row align-items-center">
      <div class="col"><hr /></div>
      <div class="col-auto">
        <a href="javascript;" class="btn btn-outline-info btn-sm" @click.prevent="addSubcategory(0)"><i class="fa fa-plus"></i></a>
      </div>
    </div>
    <div v-for="(subcategory) in subcategories" :key="subcategory.subcategoryIndex">
      <div class="row align-items-center">
        <h3 class="mx-3">{{ $t('tickets.categories.subcategories.title', [subcategory.subcategoryIndex + 1]) }} #{{subcategory.subcategoryIndex + 1}}</h3>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <div class="row">
            <input-text v-model="subcategory.name" placeholder="" :options="{ id: 'name', transPrefix: `tickets.categories.subcategories.fields`, label: 'name', disabled: !editable, error: fieldErrors[`subcategories[${subcategory.subcategoryIndex}].name`] }"/>
          </div>
          <div class="row">
            <input-checkbox v-model="subcategory.comment" :options="{ id: 'comment', transPrefix: `tickets.categories.subcategories.fields`, label: 'comment', disabled: true, error: fieldErrors[`subcategories[${subcategory.subcategoryIndex}].comment`] }"/>
            <input-checkbox v-model="subcategory.enabled" :options="{ id: 'enabled', transPrefix: `tickets.categories.subcategories.fields`, label: 'enabled', disabled: !editable || ( subcategories.filter(c => c.enabled).length <= 1 && subcategory.enabled), error: fieldErrors[`subcategories[${subcategory.subcategoryIndex}].enabled`] }"/>
          </div>
        </div>
        <div v-if="editable" class="col-auto">
          <a href="javascript;" class="btn btn-outline-danger btn-sm" v-bind:class="{ 'disabled': subcategories.length <= 1 }" @click.prevent="removeSubcategory(subcategory.subcategoryIndex)"><i class="fa fa-trash"></i></a>
        </div>
      </div>
      <div v-if="editable" class="row align-items-center">
        <div class="col"><hr/></div>
        <div class="col-auto">
          <a href="javascript;" class="btn btn-outline-info btn-sm" @click.prevent="addSubcategory(subcategory.subcategoryIndex + 1)"><i class="fa fa-plus"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CategorySubcategoriesList",
  props: {
    value: {},
    editable: {
      type: Boolean,
      default: false
    },
    fieldErrors: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      subcategories: this.value
    };
  },
  watch: {
    value: function(newVal) {
      this.subcategories = newVal;
    },
    subcategories: function(newVal) {
      this.$emit("input", newVal);
    }
  },
  methods: {
    addSubcategory(index) {
      const newSubcategory = {
        name: '',
        comment: true,
        enabled: true,
        subcategoryIndex: index
      };
      this.updateSubcategoryIndexes(index, false)
      this.subcategories.splice(index, 0, newSubcategory);
    },
    removeSubcategory(index){
      swal({
        title: this.$i18n.t("labels.delete_item"),
        text: this.subcategories[index].id === undefined ? this.$i18n.t("messages.delete_item") : this.$i18n.t("messages.delete_subcategory_with_relations"),
        icon: "warning",
        buttons: [
          this.$i18n.t("labels.cancel"),
          this.$i18n.t("labels.yes")
        ],
        dangerMode: true,
      })
          .then((willDelete) => {
            if (willDelete) {
              this.updateSubcategoryIndexes(index, true)
              this.subcategories.splice(index, 1);
            }
          });
    },
    updateSubcategoryIndexes(indexFrom, deleted) {
      if(deleted)
        this.subcategories.filter((subCat) => subCat.subcategoryIndex > indexFrom - 1)
            .map((subCat) => subCat.subcategoryIndex--);
      else
        this.subcategories.filter((subCat) => subCat.subcategoryIndex > indexFrom - 1)
            .map((subCat) => subCat.subcategoryIndex++);
    }
  }
};
</script>

<style scoped>
</style>