var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c(
            "card-header",
            { attrs: { title: _vm.$router.currentRoute.meta.title } },
            [
              !_vm.serverError
                ? _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:edit",
                          value: _vm.permissionPrefix,
                          expression: "permissionPrefix",
                          arg: "edit"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm",
                      attrs: {
                        to: {
                          name: _vm.routePrefix + ".edit",
                          params: { id: _vm.id }
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            !_vm.serverError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("select-device-types", {
                      attrs: {
                        options: {
                          id: "device_type",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "device_type",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.device_type,
                        callback: function($$v) {
                          _vm.device_type = $$v
                        },
                        expression: "device_type"
                      }
                    }),
                    _c("input-number", {
                      attrs: {
                        options: {
                          id: "build_number",
                          step: 1,
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "build_number",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.build_number,
                        callback: function($$v) {
                          _vm.build_number = $$v
                        },
                        expression: "build_number"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "build_version",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "build_version",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.build_version,
                        callback: function($$v) {
                          _vm.build_version = $$v
                        },
                        expression: "build_version"
                      }
                    }),
                    _c("input-text", {
                      attrs: {
                        options: {
                          id: "store_url",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "store_url",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.store_url,
                        callback: function($$v) {
                          _vm.store_url = $$v
                        },
                        expression: "store_url"
                      }
                    }),
                    _c("select-update-actions", {
                      attrs: {
                        options: {
                          id: "update_action",
                          transPrefix: _vm.transPrefix + ".fields",
                          label: "update_action",
                          disabled: true
                        }
                      },
                      model: {
                        value: _vm.update_action,
                        callback: function($$v) {
                          _vm.update_action = $$v
                        },
                        expression: "update_action"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }