var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade show active",
      attrs: {
        "aria-labelledby": "general-tab",
        id: "general",
        role: "tabpanel"
      }
    },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("input-text", {
            attrs: {
              options: {
                id: "backend_id",
                transPrefix: _vm.transPrefix + ".fields",
                label: "backend_id",
                disabled: true
              }
            },
            model: {
              value: _vm.fields.backend_id,
              callback: function($$v) {
                _vm.$set(_vm.fields, "backend_id", $$v)
              },
              expression: "fields.backend_id"
            }
          }),
          _vm.fields.dispatched_by
            ? _c("input-text", {
                attrs: {
                  options: {
                    id: "dispatched_by",
                    label: "dispatched_by",
                    disabled: true
                  }
                },
                model: {
                  value: _vm.fields.dispatched_by,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "dispatched_by", $$v)
                  },
                  expression: "fields.dispatched_by"
                }
              })
            : _vm._e(),
          _c("select-order-status", {
            attrs: {
              options: { id: "order_status", label: "status", disabled: true }
            },
            model: {
              value: _vm.fields.order_status,
              callback: function($$v) {
                _vm.$set(_vm.fields, "order_status", $$v)
              },
              expression: "fields.order_status"
            }
          }),
          _c("select-payment-types", {
            attrs: {
              options: {
                id: "payment_type",
                transPrefix: _vm.transPrefix + ".fields",
                label: "payment_type",
                disabled: true
              }
            },
            model: {
              value: _vm.fields.payment_type,
              callback: function($$v) {
                _vm.$set(_vm.fields, "payment_type", $$v)
              },
              expression: "fields.payment_type"
            }
          }),
          _vm.fields.city
            ? _c("input-text", {
                attrs: {
                  options: { id: "city", label: "city", disabled: true }
                },
                model: {
                  value: _vm.fields.city,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "city", $$v)
                  },
                  expression: "fields.city"
                }
              })
            : _vm._e(),
          _vm.fields.type
            ? _c("input-text", {
                attrs: {
                  options: { id: "type", label: "type", disabled: true }
                },
                model: {
                  value: _vm.fields.type,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "type", $$v)
                  },
                  expression: "fields.type"
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }