var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c(
              "card-header",
              { attrs: { title: _vm.$router.currentRoute.meta.title } },
              [
                _vm.allocation_window_id
                  ? _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "can",
                            rawName: "v-can:view_history",
                            value: _vm.permissionPrefix,
                            expression: "permissionPrefix",
                            arg: "view_history"
                          }
                        ],
                        staticClass: "btn btn-primary btn-sm",
                        attrs: {
                          to: {
                            name: _vm.routePrefix + ".allocation_windows.index",
                            params: {
                              id: _vm.id,
                              allocation_window_id: _vm.allocation_window_id
                            }
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "d-none d-lg-inline mr-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                _vm.routePrefix + ".allocation_windows.title"
                              )
                            )
                          )
                        ]),
                        _c("span", [
                          _c("i", { staticClass: "fa fa-map-marker" })
                        ])
                      ]
                    )
                  : _vm._e(),
                !_vm.serverError && _vm.platform_order_url
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary btn-sm",
                        attrs: {
                          href: _vm.platform_order_url,
                          target: "_blank"
                        }
                      },
                      [
                        _c("span", { staticClass: "d-none d-lg-inline mr-2" }, [
                          _vm._v(
                            _vm._s(_vm.$t("labels.show_on")) +
                              " " +
                              _vm._s(
                                _vm.$i18n.locale === "ar"
                                  ? _vm.platform_ar_name
                                  : _vm.platform_en_name
                              )
                          )
                        ]),
                        _c("span", [_c("i", { staticClass: "fa fa-eye" })])
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "ul",
              { staticClass: "nav nav-tabs px-2", attrs: { role: "tablist" } },
              [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link active",
                      attrs: {
                        href: "#general",
                        id: "general-tab",
                        "aria-controls": "general",
                        "aria-selected": "true",
                        "data-toggle": "tab",
                        role: "tab"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("tabs.general")) + " ")]
                  )
                ]),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        href: "#tasks",
                        id: "tasks-tab",
                        "aria-controls": "tasks",
                        "aria-selected": "false",
                        "data-toggle": "tab",
                        role: "tab"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("tabs.tasks")) + " ")]
                  )
                ]),
                _vm.captain.id
                  ? _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "#captain",
                            id: "captain-tab",
                            "aria-controls": "captain",
                            "aria-selected": "false",
                            "data-toggle": "tab",
                            role: "tab"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("tabs.captain")) + " ")]
                      )
                    ])
                  : _vm._e(),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        href: "#map",
                        id: "map-tab",
                        "aria-controls": "map",
                        "aria-selected": "false",
                        "data-toggle": "tab",
                        role: "tab"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("tabs.map")) + " ")]
                  )
                ]),
                _vm.tracking_url
                  ? _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "#tracking",
                            id: "tracking-tab",
                            "aria-controls": "tracking",
                            "aria-selected": "false",
                            "data-toggle": "tab",
                            role: "tab"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("tabs.tracking")) + " ")]
                      )
                    ])
                  : _vm._e(),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        href: "#meta_data",
                        "aria-controls": "meta_data",
                        "aria-selected": "false",
                        "data-toggle": "tab",
                        id: "meta_data-tab",
                        role: "tab"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("tabs.meta_data")) + " ")]
                  )
                ]),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        href: "#platform",
                        id: "platform-tab",
                        "aria-controls": "platform",
                        "aria-selected": "false",
                        "data-toggle": "tab",
                        role: "tab"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("tabs.platform")) + " ")]
                  )
                ]),
                _vm.customers && _vm.customers.length > 0
                  ? _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "#customers",
                            id: "customers-tab",
                            "aria-controls": "customers",
                            "aria-selected": "false",
                            "data-toggle": "tab",
                            role: "tab"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("tabs.customers")) + " ")]
                      )
                    ])
                  : _vm._e(),
                _vm.timelines && _vm.timelines.length > 0
                  ? _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "#order_timeline",
                            "aria-controls": "order_timeline",
                            "aria-selected": "false",
                            "data-toggle": "tab",
                            id: "order_timeline-tab",
                            role: "tab"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("tabs.timeline")) + " ")]
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _vm.serverError
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                        ]),
                        _vm._v(" " + _vm._s(_vm.serverError) + " ")
                      ])
                    : _vm._e()
                ])
              ]),
              !_vm.serverError && _vm.isItemFound
                ? _c(
                    "div",
                    { staticClass: "tab-content" },
                    [
                      _c("general-tab", {
                        attrs: {
                          fields: _vm.getGeneralTabFields(),
                          transPrefix: _vm.transPrefix
                        }
                      }),
                      _c("platform-tab", {
                        attrs: {
                          fields: _vm.getPlatformTabFields(),
                          transPrefix: _vm.transPrefix
                        }
                      }),
                      _c("meta-data-tab", {
                        attrs: {
                          items: _vm.metaDataItems,
                          transPrefix: _vm.transPrefix
                        }
                      }),
                      _c("tasks-tab", {
                        attrs: {
                          items: _vm.tasksItems,
                          transPrefix: _vm.transPrefix,
                          permissionPrefix: _vm.permissionPrefix,
                          updateTaskItemHandler: _vm.updateTaskItemHandler
                        }
                      }),
                      _c("map-tab", { attrs: { items: _vm.tasksItems } }),
                      _c("tracking-tab", { attrs: { url: this.tracking_url } }),
                      _c("captain-tab", {
                        attrs: {
                          fields: _vm.getCaptainTabFields(),
                          transPrefix: _vm.transPrefix,
                          permissionPrefix: _vm.permissionPrefix
                        }
                      }),
                      _c("customers-tab", {
                        attrs: {
                          items: _vm.customers,
                          notes: _vm.notes,
                          transPrefix: _vm.transPrefix
                        }
                      }),
                      _c("timeline-tab", {
                        attrs: {
                          items: _vm.timelines,
                          transPrefix: _vm.transPrefix
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "card-footer" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col text-right" },
                  [
                    _vm.can_manually_assign_order
                      ? _c("manual-assign-action", {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can:manually_assign",
                              value: _vm.permissionPrefix,
                              expression: "permissionPrefix",
                              arg: "manually_assign"
                            }
                          ],
                          attrs: { itemID: _vm.id },
                          model: {
                            value: _vm.can_manually_assign_order,
                            callback: function($$v) {
                              _vm.can_manually_assign_order = $$v
                            },
                            expression: "can_manually_assign_order"
                          }
                        })
                      : _vm._e(),
                    _vm.can_re_assign_order
                      ? _c("re-assign-action", {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can:re_assign",
                              value: _vm.permissionPrefix,
                              expression: "permissionPrefix",
                              arg: "re_assign"
                            }
                          ],
                          attrs: { itemID: _vm.id },
                          model: {
                            value: _vm.can_re_assign_order,
                            callback: function($$v) {
                              _vm.can_re_assign_order = $$v
                            },
                            expression: "can_re_assign_order"
                          }
                        })
                      : _vm._e(),
                    _vm.can_cancel_order
                      ? _c("cancel-action", {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can:cancel",
                              value: _vm.permissionPrefix,
                              expression: "permissionPrefix",
                              arg: "cancel"
                            }
                          ],
                          attrs: { itemID: _vm.id }
                        })
                      : _vm._e(),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-warning btn-sm",
                        attrs: {
                          to: {
                            name: "tickets.create",
                            query: { order_id: this.id }
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "d-none d-lg-inline mr-2" }, [
                          _vm._v(_vm._s(_vm.$t("labels.new_ticket")))
                        ]),
                        _c("span", [
                          _c("i", { staticClass: "fa fa-ticket-alt" })
                        ])
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-neutral btn-sm",
                        attrs: { to: { name: _vm.routePrefix + ".index" } }
                      },
                      [_c("i", { staticClass: "fa fa-arrow-left" })]
                    ),
                    _vm.cancellationReasons.length > 0
                      ? _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                value: { animation: false },
                                expression: "{ animation: false}",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "btn btn-danger btn-sm",
                            attrs: {
                              "data-target": "#modal-cancellation-reasons",
                              "data-toggle": "modal",
                              href: "javascript:;",
                              title: _vm.$t("labels.cancellation_reasons")
                            }
                          },
                          [_c("i", { staticClass: "fas fa-ban" })]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            "aria-hidden": "true",
            "aria-labelledby": "modal-cancellation-reasons",
            id: "modal-cancellation-reasons",
            role: "dialog",
            tabindex: "-1"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered modal-lg",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "h6",
                    {
                      staticClass: "modal-title",
                      attrs: { id: "modal-title-default" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("labels.cancellation_reasons")) +
                          " "
                      )
                    ]
                  ),
                  _vm._m(0)
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "ul",
                      { staticClass: "list" },
                      _vm._l(_vm.cancellationReasons, function(
                        cancellationReason,
                        index
                      ) {
                        return _c("li", { key: index }, [
                          _vm._v(
                            " " + _vm._s(cancellationReason.message) + " "
                          ),
                          cancellationReason.otherReason
                            ? _c(
                                "p",
                                {
                                  staticStyle: {
                                    color: "#1496ba",
                                    "font-size": "12px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " -" +
                                      _vm._s(cancellationReason.otherReason)
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ml-auto",
                      attrs: { "data-dismiss": "modal", type: "button" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("labels.close")) + " ")]
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _vm.can_cancel_order
        ? _c("cancel-action-model", {
            directives: [
              {
                name: "can",
                rawName: "v-can:cancel",
                value: _vm.permissionPrefix,
                expression: "permissionPrefix",
                arg: "cancel"
              }
            ],
            attrs: { activeParams: { activeId: _vm.id } },
            model: {
              value: _vm.can_cancel_order,
              callback: function($$v) {
                _vm.can_cancel_order = $$v
              },
              expression: "can_cancel_order"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }