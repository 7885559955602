var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("searchContainer", {
    attrs: { searchHandler: _vm.search, resetHandler: _vm.reset },
    scopedSlots: _vm._u([
      {
        key: "items",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("select-analysis-types", {
                  attrs: {
                    options: {
                      id: "analysis-type",
                      label: "range",
                      placeholder: _vm.filters.range,
                      allowEmpty: false
                    }
                  },
                  model: {
                    value: _vm.filters.range,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "range", $$v)
                    },
                    expression: "filters.range"
                  }
                }),
                _c("select-platforms", {
                  attrs: {
                    options: {
                      id: "platform_id",
                      label: "platform",
                      multiple: true,
                      canSelectAll: true,
                      error: _vm.fieldErrors.platformId
                    }
                  },
                  model: {
                    value: _vm.filters.platform_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "platform_id", $$v)
                    },
                    expression: "filters.platform_id"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }