var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "counters-container opened" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-auto px-1" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                value: { animation: false },
                expression: "{ animation: false }",
                modifiers: { hover: true }
              }
            ],
            staticClass: "alert bg-light text-dark p-2",
            attrs: { title: _vm.$t(_vm.transPrefix + ".lists.counter.total") }
          },
          [
            _c("i", { staticClass: "fas fa-users mx-1" }),
            _vm._v(" " + _vm._s(_vm.totalCaptains) + " ")
          ]
        )
      ]),
      _c("div", { staticClass: "col-auto px-1" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                value: { animation: false },
                expression: "{ animation: false }",
                modifiers: { hover: true }
              }
            ],
            staticClass: "alert bg-light text-dark p-2",
            attrs: {
              title: _vm.$t(_vm.transPrefix + ".lists.counter.ready_to_work")
            }
          },
          [
            _c("i", { staticClass: "fas fa-globe mx-1" }),
            _vm._v(" " + _vm._s(_vm.totalReadyToWorkCaptains) + " ")
          ]
        )
      ]),
      _c("div", { staticClass: "col-auto px-1" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                value: { animation: false },
                expression: "{ animation: false }",
                modifiers: { hover: true }
              }
            ],
            staticClass: "alert bg-light text-dark p-2",
            attrs: {
              title: _vm.$t(_vm.transPrefix + ".lists.counter.connected")
            }
          },
          [
            _c("i", { staticClass: "fas fa-wifi mx-1" }),
            _vm._v(" " + _vm._s(_vm.totalConnectedCaptains) + " ")
          ]
        )
      ]),
      _c("div", { staticClass: "col-auto px-1" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                value: { animation: false },
                expression: "{ animation: false }",
                modifiers: { hover: true }
              }
            ],
            staticClass: "alert bg-red text-dark p-2",
            attrs: {
              title: _vm.$t(_vm.transPrefix + ".lists.counter.not_connected")
            }
          },
          [
            _c("i", { staticClass: "fas fa-ban mx-1" }),
            _vm._v(" " + _vm._s(_vm.totalNotConnectedCaptains) + " ")
          ]
        )
      ]),
      _c("div", { staticClass: "col-auto px-1" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                value: { animation: false },
                expression: "{ animation: false }",
                modifiers: { hover: true }
              }
            ],
            staticClass: "alert bg-light text-dark p-2",
            attrs: {
              title: _vm.$t(_vm.transPrefix + ".lists.counter.location_unknown")
            }
          },
          [
            _c("i", { staticClass: "fas fa-question-circle mx-1" }),
            _vm._v(" " + _vm._s(_vm.totalLocationUnknownCaptains) + " ")
          ]
        )
      ]),
      _c("div", { staticClass: "col-auto px-1" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                value: { animation: false },
                expression: "{ animation: false }",
                modifiers: { hover: true }
              }
            ],
            staticClass: "alert bg-green text-dark p-2",
            attrs: { title: _vm.$t(_vm.transPrefix + ".lists.counter.free") }
          },
          [
            _c("i", { staticClass: "fas fa-taxi mx-1" }),
            _vm._v(" " + _vm._s(_vm.totalFreeCaptains) + " ")
          ]
        )
      ]),
      _c("div", { staticClass: "col-auto px-1" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                value: { animation: false },
                expression: "{ animation: false }",
                modifiers: { hover: true }
              }
            ],
            staticClass: "alert bg-yellow text-dark p-2",
            attrs: {
              title: _vm.$t(
                _vm.transPrefix + ".lists.counter.busy_captains_with_one_order"
              )
            }
          },
          [
            _c("i", { staticClass: "fas fa-truck mx-1" }),
            _vm._v(" " + _vm._s(_vm.totalBusyCaptains) + " ")
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }