var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return ["textarea"].includes(_vm.defaultOptions.type)
    ? _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedValue,
            expression: "selectedValue"
          }
        ],
        class: [
          { "is-invalid": _vm.defaultOptions.error },
          _vm.defaultOptions.inputClass
        ],
        attrs: {
          id: _vm.defaultOptions.id,
          disabled: _vm.defaultOptions.disabled,
          readonly: _vm.defaultOptions.readonly,
          autocomplete: _vm.defaultOptions.autocomplete ? "on" : "off",
          maxlength: _vm.defaultOptions.maxLength,
          rows: _vm.defaultOptions.rows,
          type: _vm.defaultOptions.type,
          step: _vm.defaultOptions.step,
          min: _vm.defaultOptions.min,
          max: _vm.defaultOptions.max,
          name: _vm.defaultOptions.id,
          placeholder: _vm.getPlaceholder()
        },
        domProps: { value: _vm.selectedValue },
        on: {
          keydown: [
            _vm.onKeydownHandler,
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar"
                ])
              ) {
                return null
              }
              return (function(event) {
                return _vm.defaultOptions.preventSpace
                  ? event.preventDefault()
                  : false
              })($event)
            }
          ],
          change: _vm.onChangeHandler,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.selectedValue = $event.target.value
          }
        }
      })
    : ["checkbox-custom"].includes(_vm.defaultOptions.type)
    ? _c(
        "label",
        { staticClass: "custom-toggle" },
        [
          _c("abstract-input", {
            attrs: {
              options: Object.assign({}, _vm.defaultOptions, _vm.options, {
                type: "checkbox"
              })
            },
            model: {
              value: _vm.selectedValue,
              callback: function($$v) {
                _vm.selectedValue = $$v
              },
              expression: "selectedValue"
            }
          }),
          _c("span", {
            staticClass: "custom-toggle-slider rounded-circle",
            attrs: {
              "data-label-off": _vm.$t(_vm.defaultOptions.offLabel),
              "data-label-on": _vm.$t(_vm.defaultOptions.onLabel)
            }
          })
        ],
        1
      )
    : _vm.defaultOptions.type === "checkbox"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedValue,
            expression: "selectedValue"
          }
        ],
        class: [
          { "is-invalid": _vm.defaultOptions.error },
          _vm.defaultOptions.inputClass
        ],
        attrs: {
          id: _vm.defaultOptions.id,
          disabled: _vm.defaultOptions.disabled,
          readonly: _vm.defaultOptions.readonly,
          autocomplete: _vm.defaultOptions.autocomplete ? "on" : "off",
          maxlength: _vm.defaultOptions.maxLength,
          step: _vm.defaultOptions.step,
          min: _vm.defaultOptions.min,
          max: _vm.defaultOptions.max,
          name: _vm.defaultOptions.id,
          accept: _vm.defaultOptions.accept,
          placeholder: _vm.getPlaceholder(),
          type: "checkbox"
        },
        domProps: {
          checked: Array.isArray(_vm.selectedValue)
            ? _vm._i(_vm.selectedValue, null) > -1
            : _vm.selectedValue
        },
        on: {
          keydown: [
            _vm.onKeydownHandler,
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar"
                ])
              ) {
                return null
              }
              return (function(event) {
                return _vm.defaultOptions.preventSpace
                  ? event.preventDefault()
                  : false
              })($event)
            }
          ],
          change: [
            function($event) {
              var $$a = _vm.selectedValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.selectedValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.selectedValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.selectedValue = $$c
              }
            },
            _vm.onChangeHandler
          ]
        }
      })
    : _vm.defaultOptions.type === "radio"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedValue,
            expression: "selectedValue"
          }
        ],
        class: [
          { "is-invalid": _vm.defaultOptions.error },
          _vm.defaultOptions.inputClass
        ],
        attrs: {
          id: _vm.defaultOptions.id,
          disabled: _vm.defaultOptions.disabled,
          readonly: _vm.defaultOptions.readonly,
          autocomplete: _vm.defaultOptions.autocomplete ? "on" : "off",
          maxlength: _vm.defaultOptions.maxLength,
          step: _vm.defaultOptions.step,
          min: _vm.defaultOptions.min,
          max: _vm.defaultOptions.max,
          name: _vm.defaultOptions.id,
          accept: _vm.defaultOptions.accept,
          placeholder: _vm.getPlaceholder(),
          type: "radio"
        },
        domProps: { checked: _vm._q(_vm.selectedValue, null) },
        on: {
          keydown: [
            _vm.onKeydownHandler,
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar"
                ])
              ) {
                return null
              }
              return (function(event) {
                return _vm.defaultOptions.preventSpace
                  ? event.preventDefault()
                  : false
              })($event)
            }
          ],
          change: [
            function($event) {
              _vm.selectedValue = null
            },
            _vm.onChangeHandler
          ]
        }
      })
    : _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedValue,
            expression: "selectedValue"
          }
        ],
        class: [
          { "is-invalid": _vm.defaultOptions.error },
          _vm.defaultOptions.inputClass
        ],
        attrs: {
          id: _vm.defaultOptions.id,
          disabled: _vm.defaultOptions.disabled,
          readonly: _vm.defaultOptions.readonly,
          autocomplete: _vm.defaultOptions.autocomplete ? "on" : "off",
          maxlength: _vm.defaultOptions.maxLength,
          step: _vm.defaultOptions.step,
          min: _vm.defaultOptions.min,
          max: _vm.defaultOptions.max,
          name: _vm.defaultOptions.id,
          accept: _vm.defaultOptions.accept,
          placeholder: _vm.getPlaceholder(),
          type: _vm.defaultOptions.type
        },
        domProps: { value: _vm.selectedValue },
        on: {
          keydown: [
            _vm.onKeydownHandler,
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar"
                ])
              ) {
                return null
              }
              return (function(event) {
                return _vm.defaultOptions.preventSpace
                  ? event.preventDefault()
                  : false
              })($event)
            }
          ],
          change: _vm.onChangeHandler,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.selectedValue = $event.target.value
          }
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }