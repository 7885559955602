var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c(
            "ul",
            { staticClass: "nav nav-tabs px-2", attrs: { role: "tablist" } },
            [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link active",
                    attrs: {
                      href: "#general",
                      id: "general-tab",
                      "aria-controls": "general",
                      "aria-selected": "true",
                      "data-toggle": "tab",
                      role: "tab"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("tabs.general")) + " ")]
                )
              ]),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      href: "#history",
                      id: "history-tab",
                      "aria-controls": "history",
                      "aria-selected": "false",
                      "data-toggle": "tab",
                      role: "tab"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("tabs.history")) + " ")]
                )
              ]),
              _vm.transactions
                ? _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          href: "#transactions",
                          id: "transactions-tab",
                          "aria-controls": "transactions",
                          "aria-selected": "false",
                          "data-toggle": "tab",
                          role: "tab"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("tabs.transactions")) + " ")]
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm.serverError
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.serverError) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            !_vm.serverError && _vm.isItemFound
              ? _c(
                  "div",
                  { staticClass: "tab-content" },
                  [
                    _c("general-tab", {
                      attrs: {
                        fields: _vm.getGeneralTabFields(),
                        transPrefix: _vm.transPrefix
                      }
                    }),
                    _c("history-tab", {
                      attrs: {
                        items: _vm.history,
                        transPrefix: _vm.transPrefix
                      }
                    }),
                    _c("transactions-tab", {
                      attrs: {
                        transactions: _vm.transactions,
                        transPrefix: _vm.transPrefix
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }