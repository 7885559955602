var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container captain-card" }, [
    _c("div", { staticClass: "row mt-2" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "router-link",
            {
              directives: [
                {
                  name: "can",
                  rawName: "v-can:view",
                  value: _vm.captainPermissionPrefix,
                  expression: "captainPermissionPrefix",
                  arg: "view"
                }
              ],
              attrs: {
                to: {
                  name: this.captainRoutePrefix + ".show",
                  params: { id: this.captain.id }
                },
                target: "_blank"
              }
            },
            [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("fields.id")) + ": "),
                _c("u", [_vm._v(_vm._s(_vm.id))])
              ])
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c(
          "span",
          {
            staticClass: "badge",
            class: {
              "bg-green text-white": _vm.workingStatus === "FREE",
              "bg-yellow": _vm.workingStatus === "BUSY"
            },
            staticStyle: { "font-size": "0.75rem" }
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.workingStatus === "FREE"
                    ? _vm.$t(
                        _vm.captainTransPrefix + ".lists.working_status.FREE"
                      )
                    : _vm.$t(
                        _vm.captainTransPrefix + ".lists.working_status.BUSY"
                      )
                ) +
                " "
            )
          ]
        )
      ])
    ]),
    _c("div", { staticClass: "row align-items-baseline" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("fields.name")))])
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "name",
                  disabled: true,
                  containerClass: "col-12"
                }
              },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("span", [_vm._v(" " + _vm._s(_vm.$t("fields.mobile_number")) + " ")])
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "phone",
                  disabled: true,
                  containerClass: "col-12"
                }
              },
              model: {
                value: _vm.phone,
                callback: function($$v) {
                  _vm.phone = $$v
                },
                expression: "phone"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.$t(_vm.captainTransPrefix + ".fields.supplier")))
        ])
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "supplier",
                  disabled: true,
                  containerClass: "col-12"
                }
              },
              model: {
                value: _vm.supplier,
                callback: function($$v) {
                  _vm.supplier = $$v
                },
                expression: "supplier"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t(_vm.captainTransPrefix + ".fields.shift_start_time"))
          )
        ])
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "shift_start_time",
                  disabled: true,
                  containerClass: "col-12"
                }
              },
              model: {
                value: _vm.shiftStartTime,
                callback: function($$v) {
                  _vm.shiftStartTime = $$v
                },
                expression: "shiftStartTime"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t(_vm.captainTransPrefix + ".fields.today_deliveries"))
          )
        ])
      ]),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("input-text", {
              attrs: {
                options: {
                  id: "today_deliveries",
                  disabled: true,
                  containerClass: "col-12"
                }
              },
              model: {
                value: _vm.todayDeliveries,
                callback: function($$v) {
                  _vm.todayDeliveries = $$v
                },
                expression: "todayDeliveries"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "row align-items-baseline mt-2" },
      [
        _c("input-checkbox", {
          attrs: {
            options: {
              id: "ready_to_work",
              transPrefix: _vm.captainTransPrefix + ".fields",
              label: "ready_to_work",
              disabled: true
            }
          },
          model: {
            value: _vm.getReadyToWork,
            callback: function($$v) {
              _vm.getReadyToWork = $$v
            },
            expression: "getReadyToWork"
          }
        }),
        _c("input-checkbox", {
          attrs: {
            options: {
              id: "connected",
              transPrefix: _vm.captainTransPrefix + ".fields",
              label: "connected",
              disabled: true
            }
          },
          model: {
            value: _vm.getConnected,
            callback: function($$v) {
              _vm.getConnected = $$v
            },
            expression: "getConnected"
          }
        }),
        _c("input-checkbox", {
          attrs: {
            options: {
              id: "suspended",
              transPrefix: _vm.captainTransPrefix + ".fields",
              label: "suspended",
              disabled: true
            }
          },
          model: {
            value: _vm.suspended,
            callback: function($$v) {
              _vm.suspended = $$v
            },
            expression: "suspended"
          }
        }),
        _c("input-checkbox", {
          attrs: {
            options: {
              id: "deleted",
              transPrefix: _vm.captainTransPrefix + ".fields",
              label: "deleted",
              disabled: true
            }
          },
          model: {
            value: _vm.deleted,
            callback: function($$v) {
              _vm.deleted = $$v
            },
            expression: "deleted"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }