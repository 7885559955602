var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("searchContainer", {
    attrs: { searchHandler: _vm.search, resetHandler: _vm.reset },
    scopedSlots: _vm._u([
      {
        key: "items",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("input-text", {
                  attrs: { options: { id: "name", label: "name" } },
                  model: {
                    value: _vm.filters.name,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "name", $$v)
                    },
                    expression: "filters.name"
                  }
                }),
                _c("select-ticket-root-language-codes", {
                  attrs: {
                    options: {
                      id: "language_code",
                      label: "language_code",
                      placeholder: "placeholders.all"
                    }
                  },
                  model: {
                    value: _vm.filters.language_code,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "language_code", $$v)
                    },
                    expression: "filters.language_code"
                  }
                }),
                _c("select-ticket-root-types", {
                  attrs: {
                    options: {
                      id: "type",
                      label: "type",
                      placeholder: "placeholders.all"
                    }
                  },
                  model: {
                    value: _vm.filters.type,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "type", $$v)
                    },
                    expression: "filters.type"
                  }
                }),
                _c("select-yes-no", {
                  attrs: {
                    options: {
                      id: "enabled",
                      label: "enabled",
                      placeholder: "placeholders.all"
                    }
                  },
                  model: {
                    value: _vm.filters.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "enabled", $$v)
                    },
                    expression: "filters.enabled"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "row" })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }