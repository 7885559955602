<template>
  <div>
    <div :id="id" class="tracking-map"></div>
  </div>
</template>

<script>
import Stomp from "webstomp-client";
import {
  TRACKING_ADD_CAPTAINS,
  TRACKING_ADD_UPDATE_CAPTAIN,
  TRACKING_ADD_ORDERS,
  TRACKING_ADD_UPDATE_ORDER,
  TRACKING_CAPTAIN_SHOW,
  TRACKING_CAPTAINS_LIST,
  TRACKING_DELETE_CAPTAIN,
  TRACKING_DELETE_ORDER,
  TRACKING_ORDERS_LIST,
  TRACKING_RESET_CAPTAINS,
  TRACKING_RESET_ORDERS,
  TRACKING_SET_ACTIVE_ORDER,
} from "actions/sockets/tracking";
import { mapGetters } from "vuex";
import { OperationsTrackingMap } from "@/util/map/operations/tracking";

export default {
  name: "FieldsMapsTracking",
  data() {
    return {
      id: "map",
      centerPosition: { lat: 24.7253981, lng: 46.3398977 },
      zoom: 6,
      trackingMap: null,
      stompClient: null,
      stompOptions: {
        debug: false,
        heartbeat: {
          outgoing: 0,
          incoming: 20000
        }
      },
      trackingPaths: [],
      previousCenter: this.centerPosition,
      previousZoom: this.zoom
    };
  },
  mounted() {
    this.$store.commit(TRACKING_RESET_CAPTAINS);
    this.$store.commit(TRACKING_RESET_ORDERS);
    this.initMap();
    document.addEventListener("visibilitychange", this.onVisibilityChange);
  },
  watch: {
    isStompLoaded: function(newVal) {
      if (newVal) {
        this.connect();
      }
    },
    getActiveCaptain: function(newVal) {
      if (newVal.id) {
        const captain = this.getCaptains[newVal.id];
        if (captain) {
          this.trackingMap.setMapCenter({ lat: captain.latitude, lng: captain.longitude }, 15);
        }
      }
    },
    getActiveOrder: function(newVal) {
      this.trackingMap.deleteTaskMarkers();
      this.trackingMap.deleteAllocationCircles();
      this.trackingMap.closeCaptainDetailsInfoWindow();

      if (newVal.id) {
        const tasks = newVal.tasks;
        const pickUpTask = newVal.tasks.find(task => ["PICK_UP", "ON_DEMAND_PICK_UP"].includes(task.taskType));
        const centerPosition = { lat: pickUpTask.latitude, lng: pickUpTask.longitude };

        this.trackingMap.addTaskMarkers(tasks);
        this.trackingMap.setMapCenter(centerPosition, 13);

        if (newVal.action === "SHOW_DETAILS" && newVal.awaitingAssign) {
          this.trackingMap.showCaptainMarkers();
          this.trackingMap.addAllocationCircles(centerPosition);
        } else if (newVal.action === "SHOW_DETAILS" && newVal.inProgress) {
          this.trackingMap.hideCaptainMarkers();
          this.trackingMap.showCaptainMarker(newVal.captainId);
        } else if (["MANUALLY_ASSIGN", "RE_ASSIGN"].includes(newVal.action)) {
          this.trackingMap.showCaptainMarkers();
        }
      } else {
        this.trackingMap.showCaptainMarkers();
        this.trackingMap.resetMapCenter();
      }
    },
    getHighlightedCandidate: function(newVal) {
      if (newVal.isHighlighted) {
        const orders = newVal.orders.map(orderId => this.getOrders[orderId]);
        this.trackingMap.addCaptainPaths(newVal, orders);
      } else {
        this.trackingMap.deleteCaptainPaths(newVal);
      }
    }
  },
  computed: {
    ...mapGetters([
      "isStompLoaded",
      "getStompConnectionUrl",
      "getStompConnectionsTopic",
      "getStompLocationsTopic",
      "getStompReadyToWorkTopic",
      "getStompOrdersTopic",
      "getAuthorizationHeader",
      "getCaptains",
      "getActiveOrder",
      "getActiveCaptain",
      "getHighlightedCandidate",
      "getOrders"
    ])
  },
  beforeDestroy() {
    if (this.isConnected()) {
      this.stompClient.disconnect();
    }
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
  },
  methods: {
    initMap() {
      this.trackingMap = new OperationsTrackingMap(this);
      this.trackingMap
        .init()
        .then(() => this.trackingMap.registerCaptainDetailsFunction(captainId => this.getCaptainById(captainId)))
        .then(() => this.isStompLoaded && this.connect())
        .then(() => this.initCaptains())
        .then(() => this.initOrders())
        .then(() => this.initCaptainMarkers())
        .catch(error => console.log(error));
    },
    getCaptainById(id) {
      return this.$store.dispatch(TRACKING_CAPTAIN_SHOW, { id: id })
          .catch(() => {
            swal({ icon: "error", buttons: false, timer: 5000, text: "Failed to get captain info" });
            return Promise.reject();
          });
    },
    initCaptains() {
      return this.$store.dispatch(TRACKING_CAPTAINS_LIST)
          .then(response => {
            const captains = {};
            response.data.forEach(captain => {
              captains[captain.id] = this.buildCaptainFromResponse(captain);
            });
            this.$store.commit(TRACKING_ADD_CAPTAINS, captains);
          });
    },
    initOrders() {
      return this.$store.dispatch(TRACKING_ORDERS_LIST)
          .then(response => {
            const orders = {};
            const captains = {};
            response.data.forEach(order => {
              orders[order.id] = this.buildOrderFromResponse(order);
              const captainId = orders[order.id].captainId;
              if (captainId) {
                if (!captains[captainId]) {
                  captains[captainId] = this.buildCaptainFromResponse(order.captain);
                }
                captains[captainId].orders.push(order.id);
              }
            });
            this.$store.commit(TRACKING_ADD_ORDERS, orders);
            this.$store.commit(TRACKING_ADD_CAPTAINS, captains);
          });
    },
    initCaptainMarkers() {
      Object.values(this.getCaptains).forEach(captain => this.trackingMap.addUpdateCaptainMarker(captain));
    },
    buildOrderFromResponse(order) {
      const { captain, ...orderInfo } = order;
      return { ...orderInfo, captainId: captain?.id };
    },
    buildCaptainFromResponse(captain) {
      if (captain?.id) {
        const { lastKnownLocation, ...captainInfo } = captain;
        return { ...lastKnownLocation, ...captainInfo, orders: [] };
      }
      return null;
    },
    isConnected() {
      return this.stompClient && this.stompClient.connected;
    },
    connect() {
      this.stompClient = Stomp.client(this.getStompConnectionUrl, this.stompOptions);
      this.stompClient.connect(
        { "X-Authorization": this.getAuthorizationHeader },
        () => {
          this.stompClient.subscribe(this.getStompReadyToWorkTopic, tick => this.processReadyToWork(tick));
          this.stompClient.subscribe(this.getStompConnectionsTopic, tick => this.processConnection(tick));
          this.stompClient.subscribe(this.getStompLocationsTopic, tick => this.processLocation(tick));
          this.stompClient.subscribe(this.getStompOrdersTopic, tick => this.processOrder(tick));
        },
        error => {
          console.error(error);
        }
      );
    },
    processReadyToWork(tick) {
      const response = JSON.parse(tick.body);
      const captain = { ...response.lastKnownLocation, id: response.captainId, readyToWork: response.readyToWork };
      captain.orders = this.getCaptains[captain.id]?.orders
          || Object.values(this.getOrders).filter(order => order.captainId === captain.id).map(order => order.id);

      if (captain.readyToWork === false && captain.orders.length === 0) {
        this.$store.commit(TRACKING_DELETE_CAPTAIN, { ...captain });
        this.trackingMap.deleteCaptainMarker(captain);
      } else {
        this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN, { ...captain });
        this.trackingMap.addUpdateCaptainMarker(this.getCaptains[captain.id]);
      }
    },
    processConnection(tick) {
      const response = JSON.parse(tick.body);
      const captain = { id: response.captainId, connected: response.connected };

      if (this.getCaptains[captain.id]) {
        this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN, { ...captain });
        this.trackingMap.updateCaptainMarkerIcon(this.getCaptains[captain.id]);
      }
    },
    processLocation(tick) {
      const response = JSON.parse(tick.body);
      const captain = { ...response, id: response.captainId, connected: true };

      if (this.getCaptains[captain.id]) {
        this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN, { ...captain });
        this.trackingMap.addUpdateCaptainMarker(this.getCaptains[captain.id]);
      }
    },
    processOrder(tick) {
      const newOrder = this.buildOrderFromResponse(JSON.parse(tick.body));
      const orderId = newOrder.id;
      const oldOrder = this.getOrders[orderId];

      if (newOrder.inProgress || newOrder.awaitingAssign) {
        this.$store.commit(TRACKING_ADD_UPDATE_ORDER, newOrder);
        if (newOrder.captainId && oldOrder?.captainId !== newOrder.captainId) {
          this.addOrderToCaptain(newOrder.captainId, orderId);
        }
        if (oldOrder?.captainId && oldOrder.captainId !== newOrder.captainId) {
          this.removeOrderFromCaptain(oldOrder.captainId, orderId);
        }
      } else {
        this.$store.commit(TRACKING_DELETE_ORDER, newOrder);
        if (oldOrder?.captainId) {
          this.removeOrderFromCaptain(oldOrder.captainId, orderId);
        }
      }
      this.refreshActiveOrder();
    },
    addOrderToCaptain(captainId, orderId) {
      const captain = { ...this.getCaptains[captainId] };
      captain.orders = [...captain.orders, orderId];
      this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN, captain);
      this.trackingMap.updateCaptainMarkerIcon(captain);
    },
    removeOrderFromCaptain(captainId, orderId) {
      const captain = { ...this.getCaptains[captainId] };
      captain.orders = captain.orders.filter(id => id !== orderId);
      if (captain.orders.length > 0) {
        this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN, captain);
        this.trackingMap.updateCaptainMarkerIcon(captain);
      } else {
        if (!captain.readyToWork) {
          this.$store.commit(TRACKING_DELETE_CAPTAIN, captain);
          this.trackingMap.deleteCaptainMarker(captain);
        } else {
          this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN, captain);
          this.trackingMap.updateCaptainMarkerIcon(captain);
        }
      }
    },
    refreshActiveOrder() {
      if (this.getActiveOrder?.id) {
        const updatedActiveOrder = this.getOrders[this.getActiveOrder.id];
        if (updatedActiveOrder) {
          if (this.getActiveOrder.captainId !== updatedActiveOrder.captainId) {
            if (["MANUALLY_ASSIGN", "RE_ASSIGN"].includes(this.getActiveOrder.action)) {
              this.showOrderChangedPopup("tracking.order_assigned_by_someone_else");
            } else {
              this.showOrderChangedPopup("tracking.order_assigned");
            }
            this.$store.commit(TRACKING_SET_ACTIVE_ORDER, {});
          }
        } else {
          this.showOrderChangedPopup("tracking.order_is_not_in_progress_anymore");
          this.$store.commit(TRACKING_SET_ACTIVE_ORDER, {});
        }
      }
    },
    showOrderChangedPopup(message) {
      swal({
        icon: "info",
        text: this.$i18n.t(message),
        buttons: false,
        timer: 5000
      });
    },
    onVisibilityChange() {
      if (document.visibilityState === "visible" && this.isStompLoaded && !this.isConnected()) {
        this.$store.commit(TRACKING_RESET_CAPTAINS);
        this.$store.commit(TRACKING_RESET_ORDERS);
        this.trackingMap.deleteCaptainMarkers();
        this.connect();
        this.initCaptains()
          .then(() => this.initOrders())
          .then(() => this.initCaptainMarkers())
          .then(() => this.refreshActiveOrder())
          .catch(error => console.log(error));
      }
    }
  }
};
</script>

<style scoped></style>
