<template>
  <div class="tickets-view-container">
    <!-- Tickets Body -->
    <div class="tickets-body">
      <div
        class="ticket"
        v-for="(ticket, index) in filteredTickets"
        :key="index"
        @click="onTicketClicked(ticket)"
      >
        <!-- Ticket Details -->
        <div class="ticket-details">
          <div class="ticket-main-info">Ticket ID: {{ ticket.id }}</div>
          <div class="ticket-name">ID/Captain’s Name</div>
          <div class="ticket-main-info">#{{ ticket.number }}</div>
        </div>

        <!-- Ticket Status and Location -->
        <div class="ticket-status">
          <div class="status-active">{{ ticket.status }}</div>
          <div class="ticket-name">{{ ticket.name }}</div>
          <div
            class="ticket-location"
            :class="getLocationClass(ticket.location)"
          >
            {{ ticket.location }}
          </div>
        </div>
      </div>
    </div>

    <!-- Footer Controls -->
    <div class="tickets-footer">
      <button
        class="btn-rounded"
        :class="{ focus: currentFilter === 'MY_ACTIVE' }"
        @click="setFilter('MY_ACTIVE')"
      >
        My Active
      </button>
      <button
        class="btn-rounded"
        :class="{ focus: currentFilter === 'ALL' }"
        @click="setFilter('ALL')"
      >
        All
      </button>
    </div>
  </div>
</template>

<script>

import { TICKETS_LIST as CRUD_ACTION_LIST} from 'actions/tickets';
import { ORDERS_SHOW as CRUD_ACTION_SHOW } from "actions/orders";
import {  TICKETS_CLOSE as CRUD_ACTION_CLOSE, TICKETS_REASSIGN as CRUD_ACTION_REASSIGN } from 'actions/tickets';
import { TICKETS_COMMENTS_LIST, TICKETS_COMMENTS_CREATE } from "actions/tickets";
import Stomp from "webstomp-client";
import $ from "jquery";
import {mapGetters} from "vuex";
import { TRACKING_SET_SELECTED_TICKET } from "actions/sockets/tracking";

const TICKET_CODE = `tickets`;
const CRUD_CODE = `tickets`;
const TICKET_PERMISSION_CODE = `${TICKET_CODE}`;

const TICKETS_GROUP = {
  MY_ACTIVE: 'MY_ACTIVE',
  ALL: 'ALL',
}

export default {
  name: "TicketsList",
  data() {
    return {
      tickets: [],
      currentFilter: 'MY_ACTIVE',
    };
  },
  computed: {
    ...mapGetters(["getCaptains", "getMapConfig", "getProfile", 
                   "getSearchFilters", "getSearchFiltersIfExists", "getSearchFiltersSensitized",
                   "getAuthorizationHeader", "getStompConnectionUrl", "getProfile", "getSelectedTicket"]),
    filteredTickets() {
      return this.tickets
    },
  },
  watch: {
    selectedTicket: {
      deep: true,
      handler: function(newVal) {
        this.$store.commit(TRACKING_SET_SELECTED_TICKET, { ...this.getSelectedTicket, ...newVal });
      }
    },
  },
  methods: {
    onTicketClicked(ticket) {
      this.selectedTicket = ticket;
      this.$store.commit(TRACKING_SET_SELECTED_TICKET, { ...this.getSelectedTicket, ...ticket });
    },
    setFilter(filter) {
      this.currentFilter = filter;
      this.getTickets()
    },
    getTickets() {
      this.loading = true;
      if( this.currentFilter == 'MY_ACTIVE' ) {
        this.$store.dispatch(CRUD_ACTION_LIST,{
            pageId: this.getCurrentPage()-1,
            status: 'ACTIVE',
            assigneeId: this.getProfile.id,
          }).then(async response => {
            this.tickets=response.data.data;
            this.perPage=response.data.perPage;
            this.totalElements=response.data.totalElements;
            this.lastPage=response.data.pageCount;
            await this.getRelatedEntities()
            this.$forceUpdate();
            this.loading = false;
          })
      } else {
        this.$store.dispatch(CRUD_ACTION_LIST,{
            pageId: this.getCurrentPage()-1,
            status: 'ACTIVE',
          }).then(async response=>{
            this.tickets=response.data.data;
            this.perPage=response.data.perPage;
            this.totalElements=response.data.totalElements;
            this.lastPage=response.data.pageCount;
            await this.$store.dispatch(CRUD_ACTION_LIST,{
              pageId: this.getCurrentPage()-1,
              status: 'OPEN',
              assignee: undefined,
            }).then(async response=>{
              this.tickets.push(response.data.data);
              this.perPage=response.data.perPage;
              this.totalElements=response.data.totalElements;
              this.lastPage=response.data.pageCount;
              await this.getRelatedEntities()
              this.$forceUpdate();
              this.loading = false;
            })
          })
      }
    },
    getLocationClass(status) {
      switch (status) {
        case "ACTIVE":
          return "location-active";
        case "AT_PICK_UP":
          return "location-completed";
        case "ON_THE_WAY":
          return "location-on-the-way";
        case "COLLECTING_ORDER":
          return "location-pending";
        default:
          return "location-default";
      }
    },
    getCurrentPage(){
        return 1
    },
    async getRelatedEntities() {
      this.tickets.forEach(async ticket => {
        if(ticket.relatedToType == "ORDER") {
          await this.$store.dispatch(CRUD_ACTION_SHOW, {
            id: ticket.relatedToId,
          })
          .then((response) => {
            ticket.order = {
              backend_id: response.data.backendId,
              brand: response.data.brand,
              dispatched_by: response.data.manuallyDispatched ? response.data.manuallyDispatched.dispatchedBy : "",
              city: response.data.city,
              order_status: response.data.status,
              captain: response.data.captain?response.data.captain:{},
              payment_type: response.data.paymentType,
              earnings: response.data.earnings,
              totalDistance: response.data.totalDistance,
              allocation_window_id: response.data.allocationWindowId,
              type: response.data.deliveryType,
              createdAt: response.data.createdAt,
              tracking_url: response.data.trackingUrl,
            }
          })
          .catch((error) => {
            this.loading = false;
          });
        }
      });
    },
  },
};
</script>

<style scoped>
/* Tickets Container */
.tickets-view-container {
  background-color: #ced4dade;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 190px;
  height: 192px;
  position: fixed;
  bottom: 0;
  right: 504px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tickets-body {
  flex: 1;
  overflow-y: auto;
  padding-right: 7px;
  padding-left: 3px;
  padding-top: 9px;
}

.ticket {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: #f4f4f4;
  border-radius: 3px;
  margin-bottom: 2px;
}

.ticket-main-info {
  font-size: 8px;
  font-weight: 600;
}
.ticket-details {
  font-size: 8px;
  color: #32325d;
}

.ticket-status {
  text-align: right;
  font-size: 8px;
  font-weight: 600;
  color: #32325d;
}

.tickets-footer {
  display: flex;
  justify-content: center;
  padding: 5px;
  gap: 8px;
}

.location-active {
  color: green;
}

.location-completed {
  color: #acce82;
}

.location-on-the-way {
  color: #acce82;
}

.location-pending {
  color: red;
}

.location-default {
  color: #ff7c7c;
}

.status-active {
  color: #acce82;
}

.ticket-name {
  font-weight: 400;
}
</style>
