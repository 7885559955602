var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-footer py-4" },
    [
      _c("nav", { staticClass: "row", attrs: { "aria-label": "..." } }, [
        _vm.totalElements
          ? _c("div", { staticClass: "col-12 col-md-6" }, [
              _c("h5", { staticClass: "text-primary" }, [
                _vm._v(_vm._s(_vm.$t("fields.total")) + ": "),
                _c("strong", [_vm._v(_vm._s(_vm.totalElements))])
              ])
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-12 col-md-6" },
          [
            _vm.lastPage >= 2 || _vm.currentPage !== 1
              ? _c("paginate", {
                  attrs: {
                    "page-count": _vm.lastPage,
                    "force-page": _vm.currentPage,
                    "click-handler": _vm.clickHandler,
                    "prev-text": "<i class='fas fa-angle-left'></i>",
                    "next-text": "<i class='fas fa-angle-right'></i>",
                    "container-class": "pagination justify-content-end mb-0",
                    "page-class": "page-item",
                    "prev-class": "page-item",
                    "next-class": "page-item",
                    "prev-link-class": "page-link",
                    "next-link-class": "page-link",
                    "page-link-class": "page-link"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "navbar p-0 pt-md-0 pt-1" },
        [_vm._t("default")],
        2
      ),
      _vm._t("bulk-actions")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }