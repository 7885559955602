var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header border-0" }, [
            _c(
              "div",
              { staticClass: "row justify-content-end" },
              [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:report",
                        value: _vm.permissionPrefix,
                        expression: "permissionPrefix",
                        arg: "report"
                      }
                    ],
                    staticClass: "btn btn-primary btn-sm",
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.generateTodayReport()
                      }
                    }
                  },
                  [
                    _vm.loading
                      ? _c("span", {
                          staticClass: "spinner-border spinner-border-sm"
                        })
                      : _vm._e(),
                    _c("span", [
                      _c("i", { staticClass: "fa fa-file" }),
                      _vm._v(" " + _vm._s(_vm.$t("labels.today_report")))
                    ])
                  ]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:report",
                        value: _vm.permissionPrefix,
                        expression: "permissionPrefix",
                        arg: "report"
                      }
                    ],
                    staticClass: "btn btn-primary btn-sm",
                    attrs: { to: { name: _vm.routePrefix + ".report" } }
                  },
                  [
                    _c("span", [
                      _c("i", { staticClass: "fa fa-file-export" }),
                      _vm._v(" " + _vm._s(_vm.$t("labels.custom_report")))
                    ])
                  ]
                ),
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:report",
                        value: _vm.permissionPrefix,
                        expression: "permissionPrefix",
                        arg: "report"
                      }
                    ],
                    staticClass: "btn btn-primary btn-sm",
                    attrs: {
                      to: {
                        name: _vm.routePrefix + ".assignment_methods_report"
                      }
                    }
                  },
                  [
                    _c("span", [
                      _c("i", { staticClass: "fa fa-file-export" }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("labels.assignment_methods_report"))
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("select-countries", {
                  attrs: {
                    options: {
                      id: "country_id",
                      label: "country",
                      placeholder: "placeholders.all",
                      error: _vm.fieldErrors.countryId
                    }
                  },
                  model: {
                    value: _vm.countryId,
                    callback: function($$v) {
                      _vm.countryId = $$v
                    },
                    expression: "countryId"
                  }
                }),
                _c("select-cities", {
                  attrs: {
                    country_id: _vm.countryId,
                    options: {
                      id: "city_id",
                      label: "city",
                      placeholder: "placeholders.all",
                      disabled: !_vm.countryId,
                      error: _vm.fieldErrors.cityId
                    }
                  },
                  model: {
                    value: _vm.cityId,
                    callback: function($$v) {
                      _vm.cityId = $$v
                    },
                    expression: "cityId"
                  }
                }),
                _c("select-platforms", {
                  attrs: {
                    options: {
                      id: "platform_id",
                      label: "platform",
                      placeholder: "placeholders.all",
                      error: _vm.fieldErrors.platformId
                    }
                  },
                  model: {
                    value: _vm.platformId,
                    callback: function($$v) {
                      _vm.platformId = $$v
                    },
                    expression: "platformId"
                  }
                }),
                _c("select-platform-brands", {
                  attrs: {
                    platform_id: _vm.platformId,
                    options: {
                      id: "brands",
                      label: "brands",
                      placeholder: "placeholders.all",
                      error: _vm.fieldErrors.brandIds,
                      disabled: !_vm.platformId,
                      multiple: true
                    }
                  },
                  model: {
                    value: _vm.brandIds,
                    callback: function($$v) {
                      _vm.brandIds = $$v
                    },
                    expression: "brandIds"
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center text-white h3" }, [
        _vm._v(_vm._s(_vm.$t(_vm.transPrefix + ".today_statistics")))
      ])
    ]),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("MonitoringCard", {
          attrs: {
            onClickHandler: _vm.openOrdersIndex({
              order_status: ["CANCELED", "FAILED_TO_ASSIGN", "RETURNED"]
            }),
            title: _vm.$t(_vm.transPrefix + ".cancelled_orders_count"),
            value: _vm.cancelledOrdersCount,
            icon: "fas fa-ban",
            color: "bg-red"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            onClickHandler: _vm.openOrdersIndex(),
            title: _vm.$t(_vm.transPrefix + ".total_orders_count"),
            value: _vm.totalOrdersCount,
            icon: "fas fa-box",
            color: "bg-blue"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            title: _vm.$t(_vm.transPrefix + ".average_delivery_time"),
            value: _vm.averageDeliveryTime,
            icon: "fas fa-clock",
            color: "bg-purple"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            title: _vm.$t(_vm.transPrefix + ".acceptance_rate"),
            value: _vm.acceptanceRate,
            icon: "fas fa-handshake",
            color: "bg-gray"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("MonitoringCard", {
          attrs: {
            onClickHandler: _vm.openOrdersIndex({
              assignment_method: "ASSIGNED"
            }),
            title: _vm.$t(_vm.transPrefix + ".auto_assigned_orders"),
            value: _vm.autoAssignedOrdersCount,
            icon: "fas fa-cogs",
            color: "bg-blue"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            onClickHandler: _vm.openOrdersIndex({
              assignment_method: "FORCEFULLY_ASSIGNED"
            }),
            title: _vm.$t(_vm.transPrefix + ".forcefully_assigned_orders"),
            value: _vm.forcefullyAssignedOrdersCount,
            icon: "fas fa-cogs",
            color: "bg-yellow"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            onClickHandler: _vm.openOrdersIndex({
              assignment_method: "MANUALLY_ASSIGNED"
            }),
            title: _vm.$t(_vm.transPrefix + ".manually_assigned_orders"),
            value: _vm.manuallyAssignedOrdersCount,
            icon: "fas fa-random",
            color: "bg-info"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            onClickHandler: _vm.openOrdersIndex({
              assignment_method: "REASSIGNED_TO"
            }),
            title: _vm.$t(_vm.transPrefix + ".reassigned_orders"),
            value: _vm.reAssignedOrdersCount,
            icon: "fas fa-arrows-alt-h",
            color: "bg-warning"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row align-items-center" }, [
      _c(
        "div",
        { staticClass: "col-3 p-0" },
        [
          _c("MonitoringCard", {
            attrs: {
              title: _vm.$t(_vm.transPrefix + ".clubbed_orders"),
              value: _vm.clubbedOrdersCount,
              icon: "fas fa-cubes",
              color: "bg-warning"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-3 p-0" },
        [
          _c("MonitoringCard", {
            attrs: {
              onClickHandler: _vm.openOrdersIndex({
                order_timeline_status: "LATE"
              }),
              title: _vm.$t(_vm.transPrefix + ".late_orders"),
              value: _vm.lateOrdersCount,
              icon: "fas fa-exclamation",
              color: "bg-danger"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center text-white h3" }, [
        _vm._v(_vm._s(_vm.$t(_vm.transPrefix + ".captains")))
      ])
    ]),
    _c(
      "div",
      { staticClass: "row align-items-center" },
      [
        _c("MonitoringCard", {
          attrs: {
            title: _vm.$t(_vm.transPrefix + ".total_captains"),
            value: _vm.totalCaptainsCount,
            icon: "fas fa-users",
            color: "bg-pink"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            title: _vm.$t(_vm.transPrefix + ".ready_to_work_captains"),
            value: _vm.readyToWorkCaptainsCount,
            icon: "fas fa-globe",
            color: "bg-blue"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            title: _vm.$t(_vm.transPrefix + ".not_ready_to_work_captains"),
            value: _vm.notReadyToWorkCaptainsCount,
            icon: "fas fa-times-circle",
            color: "bg-red"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            title: _vm.$t(_vm.transPrefix + ".connected_captains"),
            value: _vm.connectedCaptainsCount,
            icon: "fas fa-wifi",
            color: "bg-green"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row align-items-center" },
      [
        _c("MonitoringCard", {
          attrs: {
            title: _vm.$t(_vm.transPrefix + ".not_connected"),
            value: _vm.notConnectedCaptainsCount,
            icon: "fas fa-ban",
            color: "bg-red"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            title: _vm.$t(_vm.transPrefix + ".free_captains"),
            value: _vm.freeCaptainsCount,
            icon: "fas fa-taxi",
            color: "bg-green"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            title: _vm.$t(_vm.transPrefix + ".idle_captains"),
            value: _vm.idleCaptainsCount,
            icon: "fas fa-clock",
            color: "bg-blue"
          }
        }),
        _c("MonitoringCard", {
          attrs: {
            title: _vm.$t(_vm.transPrefix + ".busy_captains_with_one_order"),
            value: _vm.busyCaptainsWithOneOrderCount,
            icon: "fas fa-truck",
            color: "bg-yellow"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "row align-items-center" }, [
      _c(
        "div",
        { staticClass: "col-3 p-0" },
        [
          _c("MonitoringCard", {
            attrs: {
              title: _vm.$t(
                _vm.transPrefix + ".busy_captains_with_more_than_one_order"
              ),
              value: _vm.busyCaptainsWithMoreThanOneOrderCount,
              icon: "fas fa-truck-moving",
              color: "bg-orange"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }