var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "keep-alive",
    {
      attrs: {
        include: _vm.includePattern,
        exclude: _vm.excludeItems,
        max: "1"
      }
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }