var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("card-container", {
          attrs: { title: _vm.$router.currentRoute.meta.title },
          scopedSlots: _vm._u([
            {
              key: "card-header",
              fn: function() {
                return undefined
              },
              proxy: true
            },
            {
              key: "card-header-search",
              fn: function() {
                return [
                  _c("search", {
                    attrs: {
                      listHandler: _vm.getList,
                      resetHandler: _vm.resetCallback,
                      fieldErrors: _vm.fieldErrors
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "items-container",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "row m-0 px-3" },
                    _vm._l(_vm.getItemSortedIndex, function(item) {
                      return _c(
                        "div",
                        {
                          class: {
                            "col-xl-12": _vm.isMapType(item.type),
                            "col-xl-4 col-md-6": !_vm.isMapType(item.type)
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card chart-item card-stats" },
                            [
                              _c("div", { staticClass: "card-body" }, [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _vm.isChartType(item.type)
                                      ? [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "card-title text-uppercase text-muted mb-0 text-custom-dashboard"
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            [
                                              _c("GChart", {
                                                attrs: {
                                                  type: _vm.getGoogleChartTypeBy(
                                                    item.type
                                                  ),
                                                  data: _vm.prepareChartData(
                                                    item.data
                                                  ),
                                                  options: _vm.getGoogleChartOptionsByType(
                                                    item.type,
                                                    item
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm.isMapType(item.type)
                                      ? [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "card-title text-uppercase text-muted mb-2 text-center text-custom-dashboard"
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            [
                                              _vm.is_loaded
                                                ? _c("maps-heat", {
                                                    attrs: {
                                                      height: 400,
                                                      points: _vm.prepareMapData(
                                                        item.data
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      : [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "card-title text-uppercase text-muted mb-0 text-custom-dashboard"
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-12 text-center mb-sm-9 mb-md-auto avg-value-container"
                                            },
                                            _vm._l(item.data, function(
                                              dataItem
                                            ) {
                                              return _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "h2 font-weight-bold mb-0 text-indigo avg-value"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      dataItem.value
                                                        ? dataItem.value
                                                        : "-"
                                                    )
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                          _c("div", {
                                            staticClass: "col-12 avg-chart"
                                          })
                                        ]
                                  ],
                                  2
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              },
              proxy: true
            },
            {
              key: "card-footer",
              fn: function() {
                return [
                  _c("export-action", {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:report",
                        value: _vm.permissionPrefix,
                        expression: "permissionPrefix",
                        arg: "report"
                      }
                    ],
                    attrs: { crudCode: _vm.routePrefix }
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }