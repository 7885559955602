<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <input-number v-model="filters.id" :options="{ id: 'id', step: 1, label: 'id' }"/>
                <select-ticket-statuses v-model="filters.status" :options="{ id: 'status', label: 'status' }"></select-ticket-statuses>
                <select-ticket-reference-types v-model="filters.reference_type" :options="{ id: 'ticket_reference_type', label: 'reference_type', placeholder: 'placeholders.all' }"/>
                <input-number v-model="filters.owner_id" :options="{ id: 'owner_id', step: 1, label: 'owner_id' }"/>
                <input-text v-model="filters.owner_name" :options="{ id: 'owner_name', label: 'owner_name' }"></input-text>
                <select-ticket-owner-types v-model="filters.owner_type" :options="{ id: 'ticket_owner_type', label: 'owner_type', placeholder: 'placeholders.all' }"/>
                <input-text v-model="filters.assignee_name" :options="{ id: 'assignee_name', label: 'assignee_name' }"></input-text>
                <input-number v-model="filters.related_id" :options="{ id: 'related_id', step: 1, label: 'related_id' }"/>
                <select-ticket-related-types v-model="filters.related_type" :options="{ id: 'ticket_related_type', label: 'related_type', placeholder: 'placeholders.all' }"/>
            </div>
            <div class="row">
                <input-date v-model="filters.created_from" :options="{ id: 'created_from', label: 'created_from' }"/>
                <input-date v-model="filters.created_to" :options="{ id: 'created_to', label: 'created_to' }"/>
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";

    const CRUD_CODE = "tickets";
    export default {
        name: "TicketSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                    id: this.getFilterByKey('id'),
                    status: this.getFilterByKey('status'),
                    owner_name: this.getFilterByKey('owner_name'),
                    assignee_name: this.getFilterByKey('assignee_name'),
                    owner_id: this.getFilterByKey('owner_id'),
                    owner_type: this.getFilterByKey('owner_type'),
                    related_id: this.getFilterByKey('related_id'),
                    related_type: this.getFilterByKey('related_type'),
                    reference_type: this.getFilterByKey('reference_type'),
                    created_from: this.getFilterByKey('created_from'),
                    created_to: this.getFilterByKey('created_to'),
                },
                filtersMap: {
                    id: 'id',
                    status: 'status',
                    owner_id: 'ownerId',
                    owner_name: 'ownerName',
                    owner_type: 'ownerType',
                    assignee_name: 'assigneeName',
                    related_id: 'relatedId',
                    related_type: 'relatedType',
                    reference_type: 'referenceType',
                    created_from: 'startDate',
                    created_to: 'endDate',
                }
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ])
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
        }
    };
</script>
