var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.defaultOptions.fullLayout
    ? _c("div", { class: [_vm.defaultOptions.containerClass] }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _vm.getLabel()
              ? _c("label", { attrs: { for: _vm.defaultOptions.id } }, [
                  _vm._v(_vm._s(_vm.$t(_vm.getLabel())))
                ])
              : _vm._e(),
            _vm._t("hint-top"),
            _vm.defaultOptions.type === "dropdown"
              ? _c("abstract-dropdown", {
                  attrs: {
                    items: _vm.items,
                    options: Object.assign({}, _vm.defaultOptions, _vm.options)
                  },
                  model: {
                    value: _vm.selectedValue,
                    callback: function($$v) {
                      _vm.selectedValue = $$v
                    },
                    expression: "selectedValue"
                  }
                })
              : _vm._e(),
            _vm.defaultOptions.type === "dropdown_api"
              ? _c("abstract-dropdown-api", {
                  attrs: {
                    "no-api-items": _vm.items,
                    options: Object.assign({}, _vm.defaultOptions, _vm.options)
                  },
                  model: {
                    value: _vm.selectedValue,
                    callback: function($$v) {
                      _vm.selectedValue = $$v
                    },
                    expression: "selectedValue"
                  }
                })
              : _vm._e(),
            _vm._t("hint-bottom"),
            _vm.defaultOptions.error
              ? _c(
                  "span",
                  {
                    staticClass: "invalid-feedback d-block",
                    attrs: { role: "alert" }
                  },
                  [_c("strong", [_vm._v(_vm._s(_vm.defaultOptions.error))])]
                )
              : _vm._e()
          ],
          2
        )
      ])
    : _vm.defaultOptions.type === "dropdown"
    ? _c("abstract-dropdown", {
        attrs: {
          items: _vm.items,
          options: Object.assign({}, _vm.defaultOptions, _vm.options)
        },
        model: {
          value: _vm.selectedValue,
          callback: function($$v) {
            _vm.selectedValue = $$v
          },
          expression: "selectedValue"
        }
      })
    : _vm.defaultOptions.type === "dropdown_api"
    ? _c("abstract-dropdown-api", {
        attrs: {
          "no-api-items": _vm.items,
          options: Object.assign({}, _vm.defaultOptions, _vm.options)
        },
        model: {
          value: _vm.selectedValue,
          callback: function($$v) {
            _vm.selectedValue = $$v
          },
          expression: "selectedValue"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }