var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      attrs: {
        "aria-labelledby": "tracking-tab",
        id: "tracking",
        role: "tabpanel"
      }
    },
    [_c("iframe", { attrs: { src: _vm.url, width: "100%", height: "500" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }