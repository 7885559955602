var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isStompLoaded
    ? _c("li", { staticClass: "nav-item dropdown" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            class: { "notification-disabled fa": _vm.isNotificationDisabled },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.disableEnable()
              }
            }
          },
          [_c("i", { staticClass: "ni ni-bell-55" })]
        ),
        _vm._m(0),
        _vm._m(1)
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link d-none",
        attrs: {
          href: "#",
          role: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "ni ni-bell-55" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "dropdown-menu dropdown-menu-xl  dropdown-menu-right  py-0 overflow-hidden d-none"
      },
      [
        _c("div", { staticClass: "px-3 py-3" }, [
          _c("h6", { staticClass: "text-sm text-muted m-0" }, [
            _vm._v("You have "),
            _c("strong", { staticClass: "text-primary" }, [_vm._v("13")]),
            _vm._v(" notifications.")
          ])
        ]),
        _c(
          "a",
          {
            staticClass:
              "dropdown-item text-center text-primary font-weight-bold py-3",
            attrs: { href: "#!" }
          },
          [_vm._v("View all")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }