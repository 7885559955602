var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "alert alert-secondary" }, [
                    _vm._v(" " + _vm._s(_vm.getConfigStatus()) + " ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _vm.serverError
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                        ]),
                        _vm._v(" " + _vm._s(_vm.serverError) + " ")
                      ])
                    : _vm._e()
                ])
              ]),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("input-text", {
                    attrs: {
                      options: { id: "name", label: "name", disabled: true }
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  }),
                  _c("select-allocation-config-types", {
                    attrs: {
                      options: { id: "type", label: "type", disabled: true }
                    },
                    model: {
                      value: _vm.type,
                      callback: function($$v) {
                        _vm.type = $$v
                      },
                      expression: "type"
                    }
                  }),
                  ["SUPPLIER", "SUPPLIER_CITY"].includes(_vm.type)
                    ? _c("select-suppliers", {
                        attrs: {
                          includeFreelancers: false,
                          options: {
                            id: "supplier_id",
                            label: "supplier",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.supplier_id,
                          callback: function($$v) {
                            _vm.supplier_id = $$v
                          },
                          expression: "supplier_id"
                        }
                      })
                    : _vm._e(),
                  ["COUNTRY", "CITY", "ZONE", "SUPPLIER_CITY"].includes(
                    _vm.type
                  )
                    ? _c("select-countries", {
                        attrs: {
                          options: {
                            id: "country_id",
                            label: "country",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.country_id,
                          callback: function($$v) {
                            _vm.country_id = $$v
                          },
                          expression: "country_id"
                        }
                      })
                    : _vm._e(),
                  ["CITY", "ZONE", "SUPPLIER_CITY"].includes(_vm.type)
                    ? _c("select-cities", {
                        attrs: {
                          country_id: _vm.country_id,
                          options: {
                            id: "city_id",
                            label: "city",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.city_id,
                          callback: function($$v) {
                            _vm.city_id = $$v
                          },
                          expression: "city_id"
                        }
                      })
                    : _vm._e(),
                  ["ZONE"].includes(_vm.type)
                    ? _c("select-zones", {
                        attrs: {
                          city_id: _vm.city_id,
                          options: {
                            id: "zone_id",
                            label: "zone",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.zone_id,
                          callback: function($$v) {
                            _vm.zone_id = $$v
                          },
                          expression: "zone_id"
                        }
                      })
                    : _vm._e(),
                  ["PLATFORM"].includes(_vm.type)
                    ? _c("select-platforms", {
                        attrs: {
                          options: {
                            id: "platform_id",
                            label: "platform",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.platform_id,
                          callback: function($$v) {
                            _vm.platform_id = $$v
                          },
                          expression: "platform_id"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              !_vm.serverError
                ? _c("allocation-config-tiers-list", {
                    attrs: { editable: false },
                    model: {
                      value: _vm.tiers,
                      callback: function($$v) {
                        _vm.tiers = $$v
                      },
                      expression: "tiers"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "card-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-neutral btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".index" } }
                    },
                    [_c("i", { staticClass: "fa fa-arrow-left" })]
                  ),
                  _vm.isItemFound() && this.versionStatus === "future"
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              value: { animation: false },
                              expression: "{ animation: false}",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "btn btn-primary btn-sm",
                          attrs: {
                            disabled: _vm.loading,
                            title: _vm.getCancelActionHint()
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.cancel($event)
                            }
                          }
                        },
                        [
                          _vm.loading
                            ? _c("span", {
                                staticClass: "spinner-border spinner-border-sm"
                              })
                            : _vm._e(),
                          _c("i", { staticClass: "fa fa-reply" })
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }