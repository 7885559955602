var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("card-container", {
          attrs: {
            title: _vm.$router.currentRoute.meta.title,
            headers: _vm.tableHeaders
          },
          scopedSlots: _vm._u([
            {
              key: "card-header",
              fn: function() {
                return [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:edit",
                          value: _vm.permissionPrefix,
                          expression: "permissionPrefix",
                          arg: "edit"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".create" } }
                    },
                    [_c("i", { staticClass: "fa fa-plus" })]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "table-rows",
              fn: function() {
                return [
                  _vm.futureConfigs.length
                    ? _c("tr", { staticClass: "text-center" }, [
                        _c("td", { attrs: { colspan: "999" } }, [
                          _c("h3", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "allocation_configs.future_configs_title"
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._l(_vm.futureConfigs, function(item) {
                    return _c(
                      "tr",
                      {
                        key: item.id,
                        class: {
                          "future-config-bg-create": item.action === "CREATE",
                          "future-config-bg-update": item.action === "UPDATE",
                          "future-config-bg-delete": item.action === "DELETE"
                        }
                      },
                      [
                        _c("td", [_vm._v(" " + _vm._s(item.name) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.type) + " ")]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.version) + " "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                value: { animation: false },
                                expression: "{ animation: false }",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "fa mx-2 fa-info-circle",
                            attrs: { title: _vm.getFutureConfigHint(item) }
                          })
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(item.country) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.city) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.zone) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.platform) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.supplier) + " ")]),
                        _c(
                          "td",
                          [
                            _c("input-checkbox", {
                              attrs: {
                                options: {
                                  id: "status",
                                  onChangeHandler: _vm.changeStatus,
                                  onChangeParams: {
                                    id: item.id,
                                    value: !item.enabled,
                                    versionStatus: "future"
                                  },
                                  fullLayout: false,
                                  disabled:
                                    !_vm.can(_vm.permissionPrefix, "edit") ||
                                    ["DEFAULT"].includes(item.type)
                                }
                              },
                              model: {
                                value: item.enabled,
                                callback: function($$v) {
                                  _vm.$set(item, "enabled", $$v)
                                },
                                expression: "item.enabled"
                              }
                            })
                          ],
                          1
                        ),
                        _c("td", { staticClass: "text-direction-ltr" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  item.appliedAt,
                                  "Y-MM-DD hh:mm:ss A"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("table-item-actions", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "additional-items",
                                fn: function() {
                                  return [
                                    item.action !== "DELETE"
                                      ? _c("table-item-actions-show", {
                                          attrs: {
                                            options: {
                                              route_name:
                                                "allocation_configs.show",
                                              can: "settings_can_view"
                                            },
                                            params: {
                                              id: item.id,
                                              versionStatus: "future"
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    item.action !== "DELETE"
                                      ? _c("table-item-actions-edit", {
                                          attrs: {
                                            options: {
                                              route_name:
                                                "allocation_configs.edit",
                                              can: "settings_can_edit"
                                            },
                                            params: {
                                              id: item.id,
                                              versionStatus: "future"
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _c("cancel-future-allocation-config", {
                                      directives: [
                                        {
                                          name: "can",
                                          rawName: "v-can:edit",
                                          value: _vm.permissionPrefix,
                                          expression: "permissionPrefix",
                                          arg: "edit"
                                        }
                                      ],
                                      attrs: {
                                        id: item.id,
                                        "success-callback": _vm.getList,
                                        title: _vm.getCancelActionHint(item)
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  }),
                  _c("tr", { staticClass: "text-center" }, [
                    _c("td", { attrs: { colspan: "999" } }, [
                      _c("h3", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("allocation_configs.current_configs_title")
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._l(_vm.currentConfigs, function(item) {
                    return _c(
                      "tr",
                      { key: item.id },
                      [
                        _c("td", [_vm._v(" " + _vm._s(item.name) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.type) + " ")]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.version) + " "),
                          _c("i", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                value: { animation: false },
                                expression: "{ animation: false }",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "fa mx-2",
                            class: {
                              "fa-clock": _vm.getFutureConfig(item.id),
                              "fa-check text-success": !_vm.getFutureConfig(
                                item.id
                              )
                            },
                            attrs: { title: _vm.getConfigHint(item) }
                          })
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(item.country) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.city) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.zone) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.platform) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.supplier) + " ")]),
                        _c(
                          "td",
                          [
                            _c("input-checkbox", {
                              attrs: {
                                options: {
                                  id: "status",
                                  onChangeHandler: _vm.changeStatus,
                                  onChangeParams: {
                                    id: item.id,
                                    value: !item.enabled,
                                    versionStatus: "current"
                                  },
                                  fullLayout: false,
                                  disabled:
                                    !_vm.can(_vm.permissionPrefix, "edit") ||
                                    _vm.getFutureConfig(item.id) ||
                                    ["DEFAULT"].includes(item.type)
                                }
                              },
                              model: {
                                value: item.enabled,
                                callback: function($$v) {
                                  _vm.$set(item, "enabled", $$v)
                                },
                                expression: "item.enabled"
                              }
                            })
                          ],
                          1
                        ),
                        _c("td", { staticClass: "text-direction-ltr" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  item.appliedAt,
                                  "Y-MM-DD hh:mm:ss A"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("table-item-actions", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "additional-items",
                                fn: function() {
                                  return [
                                    _c("table-item-actions-show", {
                                      attrs: {
                                        options: {
                                          route_name: "allocation_configs.show",
                                          can: "settings_can_view"
                                        },
                                        params: {
                                          id: item.id,
                                          versionStatus: "current"
                                        }
                                      }
                                    }),
                                    !_vm.getFutureConfig(item.id)
                                      ? _c("table-item-actions-edit", {
                                          attrs: {
                                            options: {
                                              route_name:
                                                "allocation_configs.edit",
                                              can: "settings_can_edit"
                                            },
                                            params: {
                                              id: item.id,
                                              versionStatus: "current"
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    !_vm.getFutureConfig(item.id) &&
                                    !["DEFAULT"].includes(item.type)
                                      ? _c("table-item-actions-delete", {
                                          attrs: {
                                            options: {
                                              route_name:
                                                "allocation_configs.edit",
                                              can: "settings_can_edit",
                                              action: _vm.deleteAction,
                                              successCallback: _vm.getList
                                            },
                                            params: {
                                              id: item.id,
                                              versionStatus: "current"
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  }),
                  !_vm.currentConfigs.length
                    ? _c("tr", { staticClass: "text-center" }, [
                        _c("td", { attrs: { colspan: "999" } }, [
                          _vm._v(_vm._s(_vm.$t("messages.no_result_found")))
                        ])
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }