import Index from "@/views/tickets/index";
import Show from "@/views/tickets/show";
import Create from "@/views/tickets/create";
import CategoriesIndex from "@/views/tickets/categories/index";
import CategoriesCreate from "@/views/tickets/categories/create";
import CategoriesShow from "@/views/tickets/categories/show";
import CategoriesEdit from "@/views/tickets/categories/edit";

import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='tickets';
const PERMISSION_PREFIX=`${CODE}`;
const CATEGORIES_CODE='categories';
const CATEGORIES_PERMISSION_PREFIX=`${PERMISSION_PREFIX}`;

const routes = [
  {
    path: `${CODE}/${CATEGORIES_CODE}`,
    name:`${CODE}.${CATEGORIES_CODE}.index`,
    component: CategoriesIndex,
    meta: {
      'can': [`${CATEGORIES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${CATEGORIES_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${CATEGORIES_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${CATEGORIES_CODE}/create`,
    name:`${CODE}.${CATEGORIES_CODE}.create`,
    component: CategoriesCreate,
    meta: {
      'can': [`${CATEGORIES_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${CATEGORIES_CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${CATEGORIES_CODE}.title`,
          },
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${CATEGORIES_CODE}/:id`,
    name:`${CODE}.${CATEGORIES_CODE}.show`,
    component: CategoriesShow,
    meta: {
      'can': [`${CATEGORIES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${CATEGORIES_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${CATEGORIES_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${CATEGORIES_CODE}/:id/edit`,
    name:`${CODE}.${CATEGORIES_CODE}.edit`,
    component: CategoriesEdit,
    meta: {
      'can': [`${CATEGORIES_PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${CATEGORIES_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${CATEGORIES_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/create`,
    name:`${CODE}.create`,
    component: Create,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_create`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id`,
    name:`${CODE}.show`,
    component: Show,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  }
];

export default routes;
