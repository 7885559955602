var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("card-header", {
            attrs: { title: _vm.$router.currentRoute.meta.title }
          }),
          _c(
            "form",
            {
              attrs: { role: "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.generateReport($event)
                }
              }
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _vm.serverError
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.serverError) + " ")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("input-datetime", {
                      attrs: {
                        options: {
                          id: "from_date",
                          label: "from_date",
                          error: _vm.fieldErrors.fromDate
                        }
                      },
                      model: {
                        value: _vm.fromDate,
                        callback: function($$v) {
                          _vm.fromDate = $$v
                        },
                        expression: "fromDate"
                      }
                    }),
                    _c("input-datetime", {
                      attrs: {
                        options: {
                          id: "to_date",
                          label: "to_date",
                          error: _vm.fieldErrors.toDate
                        }
                      },
                      model: {
                        value: _vm.toDate,
                        callback: function($$v) {
                          _vm.toDate = $$v
                        },
                        expression: "toDate"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "card-footer" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col text-right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-neutral btn-sm",
                          attrs: { to: { name: _vm.routePrefix + ".index" } }
                        },
                        [_c("i", { staticClass: "fa fa-arrow-left" })]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { type: "submit", disabled: _vm.loading }
                        },
                        [
                          _vm.loading
                            ? _c("span", {
                                staticClass: "spinner-border spinner-border-sm"
                              })
                            : _vm._e(),
                          _c("i", { staticClass: "fa fa-save" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }