var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      attrs: {
        "aria-labelledby": "platform-tab",
        id: "platform",
        role: "tabpanel"
      }
    },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("files-image", {
            attrs: {
              options: { id: "image", transPrefix: "", disabled: true }
            },
            model: {
              value: _vm.fields.platform_image,
              callback: function($$v) {
                _vm.$set(_vm.fields, "platform_image", $$v)
              },
              expression: "fields.platform_image"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("input-text", {
            attrs: {
              options: {
                id: "platform_en_name",
                label: "en_name",
                disabled: true
              }
            },
            model: {
              value: _vm.fields.platform_en_name,
              callback: function($$v) {
                _vm.$set(_vm.fields, "platform_en_name", $$v)
              },
              expression: "fields.platform_en_name"
            }
          }),
          _c("input-text", {
            attrs: {
              options: {
                id: "platform_ar_name",
                label: "ar_name",
                disabled: true
              }
            },
            model: {
              value: _vm.fields.platform_ar_name,
              callback: function($$v) {
                _vm.$set(_vm.fields, "platform_ar_name", $$v)
              },
              expression: "fields.platform_ar_name"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }