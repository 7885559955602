var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      attrs: {
        "aria-labelledby": "meta_data-tab",
        id: "meta_data",
        role: "tabpanel"
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "table-container",
              { attrs: { headers: _vm.headers } },
              [
                _vm._l(_vm.items, function(item) {
                  return _c("tr", { key: item.id }, [
                    _c("td", [_vm._v(_vm._s(item.data))]),
                    _c("td", [_vm._v(_vm._s(item.label))]),
                    _c("td", [_vm._v(_vm._s(item.languageCode))])
                  ])
                }),
                !_vm.items.length
                  ? _c("tr", { staticClass: "text-center" }, [
                      _c("td", { attrs: { colspan: "999" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("messages.no_result_found")) + " "
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }