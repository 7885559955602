<template>
  <div aria-labelledby="comments-tab" class="tab-pane fade" id="comments" role="tabpanel">
    <div class="overflow-auto px-3" style="height: 400px">
      <div class="row scrollbar-inner" id="scrollable-container">
        <div v-for="item in items" v-bind:key="item.id" class="col-12">
          <div class="row my-2" v-bind:class="{
            'text-right flex-row': item.userId === getProfile.id,
            'text-left flex-row-reverse': item.userId !== getProfile.id
          }">
            <div class="col-12">
              <span>{{ item.userName }}</span>
            </div>
            <div class="col align-self-center text-direction-ltr">
              <span>{{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }}</span>
            </div>
            <div class="col-auto">
              <div class="alert alert-primary m-0 p-2" v-bind:class="{
                'alert-primary': item.userId === getProfile.id,
                'alert-secondary': item.userId !== getProfile.id
              }">
                <span>{{ item.text }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="items.length === 0" class="col-12 text-center">
          <h2>{{ $t("messages.no_result_found") }}</h2>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <input-text
            v-model="nextMessage"
            :options="{ id: 'next-message', fullLayout: false, error: fieldErrors.message, disabled: isTicketClosed, placeholder: 'placeholders.enter_your_message' }"
        />
      </div>
      <div class="col-auto">
        <button class="btn btn-primary" @click.prevent="sendMessage()" :disabled="isTicketClosed">Send</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { TICKETS_COMMENTS_LIST, TICKETS_COMMENTS_CREATE } from "actions/tickets";
import Stomp from "webstomp-client";
import $ from "jquery";
const CRUD_CODE = "tickets";
const CRUD_PERMISSION_CODE = `${CRUD_CODE}`;

export default {
  name: "TicketTabsComments",
  props: {
    fields: {
      type: Object,
      default: () => {}
    },
    isActiveTab: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_PERMISSION_CODE,
      ticketId: this.$router.currentRoute.params.id,
      fieldErrors: {},
      items: [],
      nextMessage: null,
      stompClient: null,
      isTicketClosed: false,
      stompOptions: {
        debug: false,
        heartbeat: {
          outgoing: 0,
          incoming: 20000
        }
      },
      isScrollBottomRequired: true
    }
  },
  mounted() {
    $('.scrollbar-rail,.scrollbar-macosx,.scrollbar-light,.scrollbar-inner,.scrollbar-outer,.scrollbar-dynamic,.scrollbar-chrome').scrollbar().scrollLock();
    
    if (this.isActiveTab) {
      this.getList();
      this.connect();
    }
  },
  updated() {
    if (this.isScrollBottomRequired) {
      this.scrollBottom();
    }
  },
  beforeDestroy() {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect();
    }
  },
  computed: {
    ...mapGetters(["getSearchFilters", "getSearchFiltersIfExists", "getSearchFiltersSensitized",
      "getAuthorizationHeader", "getStompConnectionUrl", "getProfile"])
  },
  watch: {
    isActiveTab: function(newVal) {
      if (newVal) {
        this.isTicketClosed = this.fields.status != "OPEN" && this.fields.status != "ACTIVE"
        console.log(this.fields.status)
        this.getList();
        this.connect();
      } else {
        if (this.stompClient && this.stompClient.connected) {
          this.stompClient.disconnect();
        }
      }
    }
  },
  methods: {
    getList() {
      this.$store
          .dispatch(TICKETS_COMMENTS_LIST, {
            ticketId: this.ticketId
          })
          .then(response => (this.items = response.data || []));
    },
    sendMessage() {
      this.$store
          .dispatch(TICKETS_COMMENTS_CREATE, {
            ticketId: this.ticketId,
            text: this.nextMessage
          })
    },
    connect() {
      this.stompClient = Stomp.client(this.getStompConnectionUrl, this.stompOptions);
      this.stompClient.connect(
          { "X-Authorization": this.getAuthorizationHeader },
          () => {
            this.stompClient.subscribe("/user/topic/ticket-" + this.ticketId + "-comments", tick => this.processComment(tick));
            this.stompClient.subscribe("/user/topic/ticket-" + this.ticketId, ticket => this.processTicketUpdate(ticket));
          },
          error => {
            console.error(error);
          }
      );
    },
    processTicketUpdate(ticket) {
      ticket = JSON.parse(ticket.body)
      if(ticket.status === "CLOSED" || ticket.status === "CLOSED_BY_SYSTEM") {
        this.isTicketClosed = true
      }
    },
    processComment(tick) {
      const response = JSON.parse(tick.body);
      this.items.push(response);
      this.isScrollBottomRequired = true;
    },
    scrollBottom() {
      const height = $("#scrollable-container")[0].scrollHeight;
      $("#scrollable-container").animate({ scrollTop: height }, 1000);
      this.isScrollBottomRequired = false;
    }
  }
};
</script>

<style type="text/css"  scoped>
.scrollbar-inner .scroll-element.scroll-y{
  background-color: #e5e5e594 !important;
  width: 8px !important;
  opacity: 1 !important;
}
.scrollbar-inner > .scroll-element .scroll-element_track, .scrollbar-inner > .scroll-element .scroll-bar{
  opacity: unset!important;
}
</style>