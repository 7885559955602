var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("tracking"),
      _c("active-orders"),
      _c("awaitng-assign-orders"),
      _c("assignment-panel"),
      _c("counters"),
      _c("controls"),
      _c("ticketsView"),
      _c("chatView")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }