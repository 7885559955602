var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "header bg-gradient-primary py-5 pb-6" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "header-body text-center mb-7" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-xl-5 col-lg-6 col-md-8 px-5 text-center" },
              [
                _c("h1", { staticClass: "text-white" }, [
                  _vm._v(_vm._s(_vm.$t("labels.welcome")))
                ]),
                _c("p", { staticClass: "text-lead text-white" }, [
                  _vm._v(" " + _vm._s(_vm.$t("auth.login.welcome")) + " ")
                ])
              ]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "separator separator-bottom separator-skew zindex-100" },
        [
          _c(
            "svg",
            {
              attrs: {
                x: "0",
                y: "0",
                viewBox: "0 0 2560 100",
                preserveAspectRatio: "none",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("polygon", {
                staticClass: "fill-default",
                attrs: { points: "2560 0 2560 100 0 100" }
              })
            ]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "container mt--8 pb-5" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-lg-5 col-md-7" }, [
          _c("div", { staticClass: "card bg-secondary border-0 mb-0" }, [
            _c("div", { staticClass: "card-body px-lg-5 py-lg-5" }, [
              _c("div", { staticClass: "text-center text-muted mb-4" }, [
                _c("small", [
                  _vm._v(_vm._s(_vm.$t("auth.login.with_credentials")))
                ])
              ]),
              _c(
                "form",
                {
                  attrs: { role: "form" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.login($event)
                    }
                  }
                },
                [
                  _vm.serverError
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                        ]),
                        _vm._v(" " + _vm._s(_vm.serverError) + " ")
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "form-group mb-3" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-group input-group-merge input-group-alternative"
                      },
                      [
                        _vm._m(0),
                        _c("input-text", {
                          attrs: {
                            options: {
                              id: "username",
                              fullLayout: false,
                              placeholder: "fields.username"
                            }
                          },
                          model: {
                            value: _vm.username,
                            callback: function($$v) {
                              _vm.username = $$v
                            },
                            expression: "username"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.fieldErrors.email
                      ? _c(
                          "span",
                          {
                            staticClass: "invalid-feedback d-block",
                            attrs: { role: "alert" }
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.fieldErrors.email))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-group input-group-merge input-group-alternative"
                      },
                      [
                        _vm._m(1),
                        _c("input-password", {
                          attrs: {
                            options: {
                              id: "password",
                              fullLayout: false,
                              placeholder: "fields.password"
                            }
                          },
                          model: {
                            value: _vm.password,
                            callback: function($$v) {
                              _vm.password = $$v
                            },
                            expression: "password"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.fieldErrors.password
                      ? _c(
                          "span",
                          {
                            staticClass: "invalid-feedback d-block",
                            attrs: { role: "alert" }
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.fieldErrors.password))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._m(2),
                  _c("div", { staticClass: "text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary my-4",
                        attrs: { type: "submit", disabled: _vm.loading }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("labels.sign_in")) + " "),
                        _vm.loading
                          ? _c("span", {
                              staticClass: "spinner-border spinner-border-sm"
                            })
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              )
            ])
          ]),
          _c("div", { staticClass: "row mt-3" }, [
            _c(
              "div",
              { staticClass: "col-6 text-left" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "text-light",
                    attrs: { to: { name: "auth.password.forget" } }
                  },
                  [
                    _c("small", [
                      _vm._v(" " + _vm._s(_vm.$t("labels.forget_password")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._m(3)
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "ni ni-email-83" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "ni ni-lock-circle-open" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "custom-control custom-control-alternative custom-checkbox d-none"
      },
      [
        _c("input", {
          staticClass: "custom-control-input",
          attrs: { id: " customCheckLogin", type: "checkbox" }
        }),
        _c(
          "label",
          {
            staticClass: "custom-control-label",
            attrs: { for: " customCheckLogin" }
          },
          [_c("span", { staticClass: "text-muted" }, [_vm._v("Remember me")])]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 text-right d-none" }, [
      _c("a", { staticClass: "text-light", attrs: { href: "#" } }, [
        _c("small", [_vm._v("Create new account")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }