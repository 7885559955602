var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", class: { "mt-6": !_vm.items.length } },
    _vm._l(_vm.items, function(item) {
      return _c("div", { staticClass: "col-xl-4 col-md-6" }, [
        _c("div", { staticClass: "card card-stats" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "h5",
                  {
                    staticClass:
                      "card-title text-uppercase text-muted mb-0 text-custom-dashboard"
                  },
                  [_vm._v(_vm._s(item.label))]
                ),
                _c("span", { staticClass: "h2 font-weight-bold mb-0" }, [
                  _vm._v(_vm._s(item.value))
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "icon icon-shape text-white rounded-circle shadow",
                    class: [item.color]
                  },
                  [_c("i", { class: [item.icon] })]
                )
              ])
            ])
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }