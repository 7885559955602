<template>
  <div class="chat-container">
    <!-- Header Section -->
    <div class="chat-header">
      <div class="status">
        <span class="timer">{{ticket.createdAt}}</span>
        <span class="active">{{ticket.status}}</span>
      </div>
      <div class="info">
        <span class="message-id">{{ticket.id}}</span>
        <span class="name">{{ticket.assigneeName}}</span>
      </div>
    </div>

    <!-- Messages Section -->
    <div class="chat-body" id="scrollable-container">
      <div
        v-for="(message, index) in messages"
        :key="index"
        :class="['message', message.sender === 'You' ? 'you' : 'other']"
      >
        <div class="sender">
          <span v-if="message.sender !== 'You'">
            {{ message.userName }}
            <span class="message-id">{{ message.id }}</span>
          </span>
          <span v-else>{{ message.userName }}</span>
        </div>
        <div class="message-row">
          <div
            class="text"
            :class="{
              'text-you': message.userId === getProfile.id,
              'text-other': message.userId !== getProfile.id,
            }"
          >
            {{ message.text }}
          </div>
          <div class="timestamp">{{ message.createdAt }}</div>
        </div>
      </div>
    </div>

    <!-- Input Section -->
    <div class="chat-input">
      <input
        type="text"
        placeholder="Type your message here."
        v-model="newMessage"
        @keyup.enter="sendMessage"
        class="input"
      />
      <button @click="sendMessage" class="chat-send-button">✔</button>
    </div>
  </div>
</template>

<script>

import { TICKETS_LIST as CRUD_ACTION_LIST} from 'actions/tickets';
import { ORDERS_SHOW as CRUD_ACTION_SHOW } from "actions/orders";
import {  TICKETS_CLOSE as CRUD_ACTION_CLOSE, TICKETS_REASSIGN as CRUD_ACTION_REASSIGN } from 'actions/tickets';
import { TICKETS_COMMENTS_LIST, TICKETS_COMMENTS_CREATE } from "actions/tickets";
import Stomp from "webstomp-client";
import $ from "jquery";
import {mapGetters} from "vuex";
import { watch } from 'vue';

const TICKET_CODE = `tickets`;
const CRUD_CODE = `tickets`;
const TICKET_PERMISSION_CODE = `${TICKET_CODE}`;

const TICKETS_GROUP = {
  MY_ACTIVE: 'MY_ACTIVE',
  ALL: 'ALL',
}

export default {
  name: "ChatComponent",
  data() {
    return {
      newMessage: "",
      messages: [],
      ticket: {},
    };
  },
  computed: {
    ...mapGetters(["getCaptains", "getMapConfig", "getProfile", 
                   "getSearchFilters", "getSearchFiltersIfExists", "getSearchFiltersSensitized",
                   "getAuthorizationHeader", "getStompConnectionUrl", "getProfile", "getSelectedTicket"]),
  },
  watch: {
    getSelectedTicket: function(ticket) {
      this.ticket = ticket
      this.getList()
      this.connect()
    }
  },
  updated() {
    if (this.isScrollBottomRequired) {
      this.scrollBottom();
    }
  },
  methods: {
    scrollBottom() {
      const height = $("#scrollable-container")[0].scrollHeight;
      $("#scrollable-container").animate({ scrollTop: height }, 1000);
      this.isScrollBottomRequired = false;
    },
    closeTicket() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CLOSE,{
          id: this.ticket.id
        }).then(response => {
          this.loading = false;
        }).catch(error => {
          this.loading = false;
        });
      },
    getList() {
      this.$store
          .dispatch(TICKETS_COMMENTS_LIST, {
            ticketId: this.ticket.id
          })
          .then(response => (this.messages = response.data || []));
    },
    sendMessage() {
      this.$store
          .dispatch(TICKETS_COMMENTS_CREATE, {
            ticketId: this.ticket.id,
            text: this.newMessage
          })
    },
    processTicketUpdate(ticket) {
      ticket = JSON.parse(ticket.body)
      this.ticket = ticket
    },
    processComment(tick) {
      const response = JSON.parse(tick.body);
      this.messages.push(response);
      this.isScrollBottomRequired = true;
    },
    connect() {
      if (this.stompClient) this.stompClient.disconnect();

      this.stompClient = Stomp.client(this.getStompConnectionUrl, this.stompOptions);
      this.stompClient.connect(
          { "X-Authorization": this.getAuthorizationHeader },
          () => {
            this.stompClient.subscribe("/user/topic/ticket-" + this.ticket.id + "-comments", tick => this.processComment(tick));
            this.stompClient.subscribe("/user/topic/ticket-" + this.ticket.id, ticket => this.processTicketUpdate(ticket));
          },
          error => {
            console.error(error);
          }
      );
    },
  },
};
</script>

<style scoped>
/* Main Chat Container */
.chat-container {
  background-color: #ced4dade;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding-left: 22px;
  padding-right: 4px;
  width: 244px; /* Fixed width */
  height: 192px; /* Fixed height */
  position: fixed;
  z-index: 10;
  bottom: 0;
  right: 258px; /* Adjusted positioning */
  display: flex;
  flex-direction: column;
}

.chat-header .status {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 3px;
  justify-content: flex-end;
  margin-right: 9px;
}

.chat-header .info {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 3px;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.name {
  color: #5e72e4;
  font-size: 9px;
  text-decoration: underline;
}

.timer {
  color: #db1c1c;
  font-style: italic;
  font-size: 9px;
}

.active {
  color: #525f7f;
  font-weight: 700;
  font-size: 8px;
}

/* Messages Section */
.chat-body {
  flex: 1;
  overflow-y: auto;
  font-size: 9px;
}

.message {
  margin-bottom: 8px;
}

.message-row {
  flex-direction: row;
  display: flex; /* Enables flexbox */
  justify-content: space-between; /* Space between text and timestamp */
  align-items: center; /* Align items vertically in the center */
  margin-bottom: 4px; /* Add spacing between rows */
}

.text {
  padding: 5px;
  color: #adadad;
  border-width: 1px;
  border-color: #dee2e6;
  background-color: white;
  max-width: 185px;
  border-radius: 3px;
}

/* Background color for messages sent by "You" */
.text-you {
  background-color: white; /* White background for "You" */
  border: 1px solid #dee2e6;
  color: #525f7f;
}

/* Background color for messages sent by others */
.text-other {
  background-color: #f6f9fc; /* Light gray background for others */
  border: 1px solid #e0e0e0;
  color: #525f7f;
}

.sender {
  margin-bottom: 2px;
  font-weight: 700;
  color: #525f7f;
  font-size: 9px;
}

.message-id {
  font-weight: 600;
  color: #525f7f;
  text-decoration: underline;
  font-size: 9px;
}

.timestamp {
  font-size: 5px;
  color: #525f7f;
  text-align: right;
  width: 34px;
}

/* Input Section */
.chat-input {
  display: flex;
  gap: 4px;
  margin-bottom: 1px;
}

.input {
  flex: 1;
  border-width: 1px;
  border-color: #dee2e6;
  background-color: white;
  color: #adadad;
  font-size: 9px;
  border-radius: 3px;
  width: 188px;

  &:focus {
    outline: none;
  }
}

.chat-send-button {
  background-color: black;
  border-width: 1px;
  border-color: #dee2e6;
  border-radius: 3px;
  color: white;
}
</style>
