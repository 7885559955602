var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.editable
        ? _c("div", { staticClass: "row align-items-center" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-auto" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-outline-info btn-sm",
                  attrs: { href: "javascript;" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.addTier(0)
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-plus" })]
              )
            ])
          ])
        : _vm._e(),
      _vm._l(_vm.tiers, function(tier, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("h3", { staticClass: "mx-3" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("allocation_configs.allocation_tier_title", [
                    index + 1
                  ])
                )
              )
            ])
          ]),
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("input-number", {
                    attrs: {
                      options: {
                        id: "initial_earning",
                        transPrefix: "allocation_configs.fields",
                        label: "initial_earning",
                        disabled: !_vm.editable,
                        error:
                          _vm.fieldErrors["tiers[" + index + "].initialEarning"]
                      }
                    },
                    model: {
                      value: tier.initialEarning,
                      callback: function($$v) {
                        _vm.$set(tier, "initialEarning", $$v)
                      },
                      expression: "tier.initialEarning"
                    }
                  }),
                  _c("input-number", {
                    attrs: {
                      options: {
                        id: "per_km",
                        transPrefix: "allocation_configs.fields",
                        label: "per_km",
                        disabled: !_vm.editable,
                        error: _vm.fieldErrors["tiers[" + index + "].perKm"]
                      }
                    },
                    model: {
                      value: tier.perKm,
                      callback: function($$v) {
                        _vm.$set(tier, "perKm", $$v)
                      },
                      expression: "tier.perKm"
                    }
                  }),
                  _c("input-number", {
                    attrs: {
                      options: {
                        id: "max_earning",
                        transPrefix: "allocation_configs.fields",
                        label: "max_earning",
                        disabled: !_vm.editable,
                        error:
                          _vm.fieldErrors["tiers[" + index + "].maxEarning"]
                      }
                    },
                    model: {
                      value: tier.maxEarning,
                      callback: function($$v) {
                        _vm.$set(tier, "maxEarning", $$v)
                      },
                      expression: "tier.maxEarning"
                    }
                  }),
                  _c("input-number", {
                    attrs: {
                      options: {
                        id: "km_start_after",
                        transPrefix: "allocation_configs.fields",
                        label: "km_start_after",
                        disabled: !_vm.editable,
                        error:
                          _vm.fieldErrors["tiers[" + index + "].kmStartsAfter"]
                      }
                    },
                    model: {
                      value: tier.kmStartsAfter,
                      callback: function($$v) {
                        _vm.$set(tier, "kmStartsAfter", $$v)
                      },
                      expression: "tier.kmStartsAfter"
                    }
                  }),
                  _c("input-number", {
                    attrs: {
                      options: {
                        id: "tier_completion_orders_count",
                        transPrefix: "allocation_configs.fields",
                        label: "tier_completion_orders_count",
                        disabled:
                          index + 1 === _vm.tiers.length || !_vm.editable,
                        error:
                          _vm.fieldErrors[
                            "tiers[" + index + "].tierCompletionOrdersCount"
                          ]
                      }
                    },
                    model: {
                      value: tier.tierCompletionOrdersCount,
                      callback: function($$v) {
                        _vm.$set(tier, "tierCompletionOrdersCount", $$v)
                      },
                      expression: "tier.tierCompletionOrdersCount"
                    }
                  }),
                  _c("input-number", {
                    attrs: {
                      options: {
                        id: "tier_completion_bonus",
                        transPrefix: "allocation_configs.fields",
                        label: "tier_completion_bonus",
                        disabled:
                          index + 1 === _vm.tiers.length || !_vm.editable,
                        error:
                          _vm.fieldErrors[
                            "tiers[" + index + "].tierCompletionBonus"
                          ]
                      }
                    },
                    model: {
                      value: tier.tierCompletionBonus,
                      callback: function($$v) {
                        _vm.$set(tier, "tierCompletionBonus", $$v)
                      },
                      expression: "tier.tierCompletionBonus"
                    }
                  })
                ],
                1
              )
            ]),
            _vm.editable
              ? _c("div", { staticClass: "col-auto" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-outline-danger btn-sm",
                      class: { disabled: _vm.tiers.length <= 1 },
                      attrs: { href: "javascript;" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.removeTier(index)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-trash" })]
                  )
                ])
              : _vm._e()
          ]),
          _vm.editable
            ? _c("div", { staticClass: "row align-items-center" }, [
                _vm._m(1, true),
                _c("div", { staticClass: "col-auto" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-outline-info btn-sm",
                      attrs: { href: "javascript;" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.addTier(index + 1)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-plus" })]
                  )
                ])
              ])
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }