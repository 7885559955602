var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("searchContainer", {
    attrs: { searchHandler: _vm.search, resetHandler: _vm.reset },
    scopedSlots: _vm._u([
      {
        key: "items",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("input-number", {
                  attrs: {
                    options: {
                      id: "user_id",
                      transPrefix: _vm.transPrefix + ".fields",
                      label: "user_id"
                    }
                  },
                  model: {
                    value: _vm.filters.user_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "user_id", $$v)
                    },
                    expression: "filters.user_id"
                  }
                }),
                _c("select-audit-logs-item-types", {
                  attrs: {
                    options: {
                      id: "item_types",
                      transPrefix: _vm.transPrefix + ".fields",
                      label: "item_types",
                      placeholder: "placeholders.all",
                      multiple: true
                    }
                  },
                  model: {
                    value: _vm.filters.item_types,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "item_types", $$v)
                    },
                    expression: "filters.item_types"
                  }
                }),
                _c("input-number", {
                  attrs: {
                    options: {
                      id: "item_id",
                      transPrefix: _vm.transPrefix + ".fields",
                      label: "item_id"
                    }
                  },
                  model: {
                    value: _vm.filters.item_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "item_id", $$v)
                    },
                    expression: "filters.item_id"
                  }
                }),
                _c("select-audit-logs-event-types", {
                  attrs: {
                    options: {
                      id: "event_types",
                      transPrefix: _vm.transPrefix + ".fields",
                      label: "event_types",
                      placeholder: "placeholders.all",
                      multiple: true
                    }
                  },
                  model: {
                    value: _vm.filters.event_types,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "event_types", $$v)
                    },
                    expression: "filters.event_types"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("input-date", {
                  attrs: {
                    options: { id: "event_date_from", label: "event_date_from" }
                  },
                  model: {
                    value: _vm.filters.event_date_from,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "event_date_from", $$v)
                    },
                    expression: "filters.event_date_from"
                  }
                }),
                _c("input-date", {
                  attrs: {
                    options: { id: "event_date_to", label: "event_date_to" }
                  },
                  model: {
                    value: _vm.filters.event_date_to,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "event_date_to", $$v)
                    },
                    expression: "filters.event_date_to"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }