var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      attrs: {
        "aria-labelledby": "history-tab",
        id: "history",
        role: "tabpanel"
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          _vm._l(_vm.getItemSortedByDate, function(item) {
            return _c(
              "div",
              { staticClass: "vertical-timeline vertical-timeline--animate" },
              [
                _c("div", { staticClass: "vertical-timeline-element" }, [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "vertical-timeline-element-icon bounce-in",
                        style: { color: item.statusColor }
                      },
                      [_c("i", { staticClass: "fas fa-dot-circle" })]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vertical-timeline-element-content bounce-in"
                      },
                      [
                        _c("h4", { staticClass: "timeline-title" }, [
                          _vm._v(_vm._s(item.statusDisplayName))
                        ]),
                        _c("h6", { staticClass: "timeline-message" }, [
                          _vm._v(_vm._s(item.note))
                        ]),
                        _c("p"),
                        _c(
                          "span",
                          {
                            staticClass:
                              "vertical-timeline-element-date text-direction-ltr"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  item.createdAt,
                                  "Y-MM-DD hh:mm:ss A"
                                )
                              )
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }