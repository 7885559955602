var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        "aria-hidden": "true",
        "aria-labelledby": "modal-user-roles",
        id: "modal-user-roles",
        role: "dialog",
        tabindex: "-1"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h6",
                {
                  staticClass: "modal-title",
                  attrs: { id: "modal-title-default" }
                },
                [_vm._v(_vm._s(_vm.$t("fields.roles")))]
              ),
              _vm._m(0)
            ]),
            _c(
              "form",
              {
                attrs: { role: "form" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.changeStatus($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm.serverError
                        ? _c("div", { staticClass: "alert alert-danger" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                            ]),
                            _vm._v(" " + _vm._s(_vm.serverError) + " ")
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("select-roles", {
                        attrs: {
                          value: _vm.rolesIds,
                          options: {
                            id: "roles",
                            params: Object.assign(
                              {},
                              _vm.activeParams,
                              _vm.additionalParams
                            ),
                            multiple: true,
                            disabled: true,
                            containerClass: "col-12"
                          }
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger btn-sm ml-auto",
                      attrs: { "data-dismiss": "modal", type: "button" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("labels.close")) + " ")]
                  )
                ])
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }