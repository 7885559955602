var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tab-pane fade",
      attrs: { "aria-labelledby": "tasks-tab", id: "tasks", role: "tabpanel" }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "table-container",
              { attrs: { headers: _vm.headers } },
              [
                _vm._l(_vm.items, function(item) {
                  return _c("tr", { key: item.id }, [
                    _c("td", [_vm._v(_vm._s(item.name))]),
                    _c("td", [_vm._v(_vm._s(item.description))]),
                    _c("td", [_vm._v(_vm._s(item.rank))]),
                    _c("td", [_vm._v(_vm._s(item.taskStatus))]),
                    _c("td", [_vm._v(_vm._s(item.taskType))]),
                    _c("td", { staticClass: "text-direction-ltr" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("moment")(
                              item.createdAt,
                              "Y-MM-DD hh:mm:ss A"
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("td", { staticClass: "text-direction-ltr" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("moment")(
                              item.arrivedAt,
                              "Y-MM-DD hh:mm:ss A"
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("td", { staticClass: "text-direction-ltr" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("moment")(
                              item.completedAt,
                              "Y-MM-DD hh:mm:ss A"
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("td", [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              value: { animation: false },
                              expression: "{ animation: false}",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "text-info px-1",
                          attrs: {
                            "data-target": "#modal-additional-information",
                            title: _vm.$t("crud.show"),
                            "data-toggle": "modal",
                            href: "javascript:;"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setActiveTask(item)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-eye" })]
                      ),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              value: { animation: false },
                              expression: "{ animation: false}",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "text-gray px-1",
                          attrs: {
                            "data-target": "#modal-timeline",
                            title: _vm.$t("labels.timeline"),
                            "data-toggle": "modal",
                            href: "javascript:;"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.getTaskTimeline(item.id)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-sort-numeric-down" })]
                      )
                    ])
                  ])
                }),
                !_vm.items.length
                  ? _c("tr", { staticClass: "text-center" }, [
                      _c("td", { attrs: { colspan: "999" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("messages.no_result_found")) + " "
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            "aria-hidden": "true",
            id: "modal-additional-information",
            "aria-labelledby": "modal-additional-information",
            role: "dialog",
            tabindex: "-1"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered modal-lg",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "h6",
                    {
                      staticClass: "modal-title",
                      attrs: { id: "modal-title-default" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("labels.additional_information")) +
                          " "
                      )
                    ]
                  ),
                  _vm._m(0)
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("input-text", {
                        attrs: {
                          options: {
                            id: "collect_at_delivery",
                            transPrefix: _vm.transPrefix + ".fields",
                            label: "collect_at_delivery",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.activeTask.collectAtDelivery,
                          callback: function($$v) {
                            _vm.$set(_vm.activeTask, "collectAtDelivery", $$v)
                          },
                          expression: "activeTask.collectAtDelivery"
                        }
                      }),
                      _c("input-text", {
                        attrs: {
                          options: {
                            id: "pay_at_pickup",
                            transPrefix: _vm.transPrefix + ".fields",
                            label: "pay_at_pickup",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.activeTask.payAtPickup,
                          callback: function($$v) {
                            _vm.$set(_vm.activeTask, "payAtPickup", $$v)
                          },
                          expression: "activeTask.payAtPickup"
                        }
                      }),
                      _c("input-text", {
                        attrs: {
                          options: {
                            id: "address_description",
                            transPrefix: _vm.transPrefix + ".fields",
                            label: "address_description",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.activeTaskAddress.description,
                          callback: function($$v) {
                            _vm.$set(_vm.activeTaskAddress, "description", $$v)
                          },
                          expression: "activeTaskAddress.description"
                        }
                      }),
                      _c("input-text", {
                        attrs: {
                          options: {
                            id: "address_email",
                            transPrefix: _vm.transPrefix + ".fields",
                            label: "address_email",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.activeTaskAddress.email,
                          callback: function($$v) {
                            _vm.$set(_vm.activeTaskAddress, "email", $$v)
                          },
                          expression: "activeTaskAddress.email"
                        }
                      }),
                      _c("input-text", {
                        attrs: {
                          options: {
                            id: "address_phone",
                            transPrefix: _vm.transPrefix + ".fields",
                            label: "address_phone",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.activeTaskAddress.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.activeTaskAddress, "phone", $$v)
                          },
                          expression: "activeTaskAddress.phone"
                        }
                      }),
                      _c("input-text", {
                        attrs: {
                          options: {
                            id: "address_latitude",
                            transPrefix: _vm.transPrefix + ".fields",
                            label: "address_latitude",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.activeTaskAddress.latitude,
                          callback: function($$v) {
                            _vm.$set(_vm.activeTaskAddress, "latitude", $$v)
                          },
                          expression: "activeTaskAddress.latitude"
                        }
                      }),
                      _c("input-text", {
                        attrs: {
                          options: {
                            id: "address_longitude",
                            transPrefix: _vm.transPrefix + ".fields",
                            label: "address_longitude",
                            disabled: true
                          }
                        },
                        model: {
                          value: _vm.activeTaskAddress.longitude,
                          callback: function($$v) {
                            _vm.$set(_vm.activeTaskAddress, "longitude", $$v)
                          },
                          expression: "activeTaskAddress.longitude"
                        }
                      }),
                      _vm.activeTask.deliveryConfirmationCode
                        ? _c("input-text", {
                            attrs: {
                              options: {
                                id: "delivery_confirmation_code",
                                transPrefix: _vm.transPrefix + ".fields",
                                label: "delivery_confirmation_code",
                                disabled: true
                              }
                            },
                            model: {
                              value: _vm.activeTask.deliveryConfirmationCode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeTask,
                                  "deliveryConfirmationCode",
                                  $$v
                                )
                              },
                              expression: "activeTask.deliveryConfirmationCode"
                            }
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "collect_at_delivery" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    _vm.transPrefix +
                                      ".fields.collect_at_delivery"
                                  )
                                )
                              )
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.activeTask.collectAtDelivery,
                                expression: "activeTask.collectAtDelivery"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "collect_at_delivery",
                              name: "collect_at_delivery",
                              type: "text",
                              disabled: ""
                            },
                            domProps: {
                              value: _vm.activeTask.collectAtDelivery
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.activeTask,
                                  "collectAtDelivery",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "pay_at_pickup" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.transPrefix + ".fields.pay_at_pickup"
                                )
                              )
                            )
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.activeTask.payAtPickup,
                                expression: "activeTask.payAtPickup"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "pay_at_pickup",
                              name: "pay_at_pickup",
                              type: "text",
                              disabled: ""
                            },
                            domProps: { value: _vm.activeTask.payAtPickup },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.activeTask,
                                  "payAtPickup",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "address_description" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    _vm.transPrefix +
                                      ".fields.address_description"
                                  )
                                )
                              )
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.activeTaskAddress.description,
                                expression: "activeTaskAddress.description"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "address_description",
                              name: "address_description",
                              type: "text",
                              disabled: ""
                            },
                            domProps: {
                              value: _vm.activeTaskAddress.description
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.activeTaskAddress,
                                  "description",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "address_email" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.transPrefix + ".fields.address_email"
                                )
                              )
                            )
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.activeTaskAddress.email,
                                expression: "activeTaskAddress.email"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "address_email",
                              name: "address_email",
                              type: "text",
                              disabled: ""
                            },
                            domProps: { value: _vm.activeTaskAddress.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.activeTaskAddress,
                                  "email",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "address_phone" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.transPrefix + ".fields.address_phone"
                                )
                              )
                            )
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.activeTaskAddress.phone,
                                expression: "activeTaskAddress.phone"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "address_phone",
                              name: "address_phone",
                              type: "text",
                              disabled: ""
                            },
                            domProps: { value: _vm.activeTaskAddress.phone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.activeTaskAddress,
                                  "phone",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "address_latitude" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.transPrefix + ".fields.address_latitude"
                                )
                              )
                            )
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.activeTaskAddress.latitude,
                                expression: "activeTaskAddress.latitude"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "address_latitude",
                              name: "address_latitude",
                              type: "text",
                              disabled: ""
                            },
                            domProps: { value: _vm.activeTaskAddress.latitude },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.activeTaskAddress,
                                  "latitude",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "address_longitude" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.transPrefix + ".fields.address_longitude"
                                )
                              )
                            )
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.activeTaskAddress.longitude,
                                expression: "activeTaskAddress.longitude"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "address_longitude",
                              name: "address_longitude",
                              type: "text",
                              disabled: ""
                            },
                            domProps: {
                              value: _vm.activeTaskAddress.longitude
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.activeTaskAddress,
                                  "longitude",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _vm.activeTask.deliveryConfirmationCode
                        ? _c("div", { staticClass: "col-12 col-md-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  attrs: { for: "delivery_confirmation_code" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        _vm.transPrefix +
                                          ".fields.delivery_confirmation_code"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.activeTask.deliveryConfirmationCode,
                                    expression:
                                      "activeTask.deliveryConfirmationCode"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "delivery_confirmation_code",
                                  name: "delivery_confirmation_code",
                                  type: "text",
                                  disabled: ""
                                },
                                domProps: {
                                  value: _vm.activeTask.deliveryConfirmationCode
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.activeTask,
                                      "deliveryConfirmationCode",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        : _vm._e(),
                      _vm.activeTask.receipt
                        ? _c("files-image", {
                            attrs: {
                              options: {
                                id: "receipt",
                                transPrefix: _vm.transPrefix + ".fields",
                                label: "receipt",
                                disabled: true
                              }
                            },
                            model: {
                              value: _vm.activeTask.receipt,
                              callback: function($$v) {
                                _vm.$set(_vm.activeTask, "receipt", $$v)
                              },
                              expression: "activeTask.receipt"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("h6", { staticClass: "modal-title mb-3" }, [
                          _vm._v(" " + _vm._s(_vm.$t("tabs.items")) + " ")
                        ]),
                        _c(
                          "table-container",
                          { attrs: { headers: _vm.taskItemsTableHeaders } },
                          [
                            _vm._l(_vm.activeTaskItems, function(item) {
                              return _c("tr", { key: item.id }, [
                                _c("td", [_vm._v(_vm._s(item.name))]),
                                _c("td", [_vm._v(_vm._s(item.description))]),
                                _c("td", [_vm._v(_vm._s(item.price))]),
                                _c("td", [_vm._v(_vm._s(item.quantity))])
                              ])
                            }),
                            !_vm.activeTaskItems.length
                              ? _c("tr", { staticClass: "text-center" }, [
                                  _c("td", { attrs: { colspan: "999" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("messages.no_result_found")
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ml-auto",
                      attrs: { "data-dismiss": "modal", type: "button" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("labels.close")) + " ")]
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            "aria-hidden": "true",
            id: "modal-timeline",
            "aria-labelledby": "modal-timeline",
            role: "dialog",
            tabindex: "-1"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered modal-md",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "h6",
                    {
                      staticClass: "modal-title",
                      attrs: { id: "modal-title-timeline" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("labels.timeline")) + " ")]
                  ),
                  _vm._m(1)
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      _vm._l(_vm.getItemSortedByDate, function(item) {
                        return _c(
                          "div",
                          {
                            staticClass:
                              "vertical-timeline vertical-timeline--animate"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "vertical-timeline-element" },
                              [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "vertical-timeline-element-icon bounce-in",
                                      style: { color: item.statusColor }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-dot-circle"
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vertical-timeline-element-content bounce-in"
                                    },
                                    [
                                      _c(
                                        "h4",
                                        { staticClass: "timeline-title" },
                                        [_vm._v(_vm._s(item.statusDisplayName))]
                                      ),
                                      item.captainId
                                        ? _c(
                                            "h6",
                                            { staticClass: "timeline-message" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("fields.captain_id")
                                                ) +
                                                  " : " +
                                                  _vm._s(item.captainId)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("p"),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "vertical-timeline-element-date text-direction-ltr"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("moment")(
                                                item.createdAt,
                                                "Y-MM-DD hh:mm:ss A"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ml-auto",
                      attrs: { "data-dismiss": "modal", type: "button" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("labels.close")) + " ")]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }