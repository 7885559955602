var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "btn btn-danger btn-sm",
      attrs: {
        href: "javascript:;",
        "data-target": _vm.modalSelector,
        "data-toggle": "modal"
      },
      on: {
        click: function($event) {
          return _vm.setActiveParams(_vm.index, _vm.itemID)
        }
      }
    },
    [
      _c("span", { staticClass: "d-none d-lg-inline mr-2" }, [
        _vm._v(" " + _vm._s(_vm.$t("labels.cancel")) + " ")
      ]),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "fas fa-stop" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }