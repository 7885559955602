var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.editable
        ? _c("div", { staticClass: "row align-items-center" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-auto" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-outline-info btn-sm",
                  attrs: { href: "javascript;" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.addSubcategory(0)
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-plus" })]
              )
            ])
          ])
        : _vm._e(),
      _vm._l(_vm.subcategories, function(subcategory) {
        return _c("div", { key: subcategory.subcategoryIndex }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("h3", { staticClass: "mx-3" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("tickets.categories.subcategories.title", [
                    subcategory.subcategoryIndex + 1
                  ])
                ) +
                  " #" +
                  _vm._s(subcategory.subcategoryIndex + 1)
              )
            ])
          ]),
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("input-text", {
                    attrs: {
                      placeholder: "",
                      options: {
                        id: "name",
                        transPrefix: "tickets.categories.subcategories.fields",
                        label: "name",
                        disabled: !_vm.editable,
                        error:
                          _vm.fieldErrors[
                            "subcategories[" +
                              subcategory.subcategoryIndex +
                              "].name"
                          ]
                      }
                    },
                    model: {
                      value: subcategory.name,
                      callback: function($$v) {
                        _vm.$set(subcategory, "name", $$v)
                      },
                      expression: "subcategory.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("input-checkbox", {
                    attrs: {
                      options: {
                        id: "comment",
                        transPrefix: "tickets.categories.subcategories.fields",
                        label: "comment",
                        disabled: true,
                        error:
                          _vm.fieldErrors[
                            "subcategories[" +
                              subcategory.subcategoryIndex +
                              "].comment"
                          ]
                      }
                    },
                    model: {
                      value: subcategory.comment,
                      callback: function($$v) {
                        _vm.$set(subcategory, "comment", $$v)
                      },
                      expression: "subcategory.comment"
                    }
                  }),
                  _c("input-checkbox", {
                    attrs: {
                      options: {
                        id: "enabled",
                        transPrefix: "tickets.categories.subcategories.fields",
                        label: "enabled",
                        disabled:
                          !_vm.editable ||
                          (_vm.subcategories.filter(function(c) {
                            return c.enabled
                          }).length <= 1 &&
                            subcategory.enabled),
                        error:
                          _vm.fieldErrors[
                            "subcategories[" +
                              subcategory.subcategoryIndex +
                              "].enabled"
                          ]
                      }
                    },
                    model: {
                      value: subcategory.enabled,
                      callback: function($$v) {
                        _vm.$set(subcategory, "enabled", $$v)
                      },
                      expression: "subcategory.enabled"
                    }
                  })
                ],
                1
              )
            ]),
            _vm.editable
              ? _c("div", { staticClass: "col-auto" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-outline-danger btn-sm",
                      class: { disabled: _vm.subcategories.length <= 1 },
                      attrs: { href: "javascript;" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.removeSubcategory(
                            subcategory.subcategoryIndex
                          )
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-trash" })]
                  )
                ])
              : _vm._e()
          ]),
          _vm.editable
            ? _c("div", { staticClass: "row align-items-center" }, [
                _vm._m(1, true),
                _c("div", { staticClass: "col-auto" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-outline-info btn-sm",
                      attrs: { href: "javascript;" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.addSubcategory(
                            subcategory.subcategoryIndex + 1
                          )
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-plus" })]
                  )
                ])
              ])
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }