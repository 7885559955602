var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("card-container", {
          attrs: {
            title: _vm.$router.currentRoute.meta.title,
            headers: _vm.tableHeaders,
            lastPage: _vm.lastPage,
            totalElements: _vm.totalElements,
            currentPage: _vm.getCurrentPage(),
            clickHandler: _vm.pagination
          },
          scopedSlots: _vm._u([
            {
              key: "card-header",
              fn: function() {
                return [
                  _c(
                    "router-link",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can:add",
                          value: _vm.permissionPrefix,
                          expression: "permissionPrefix",
                          arg: "add"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm",
                      attrs: { to: { name: _vm.routePrefix + ".create" } }
                    },
                    [_c("i", { staticClass: "fa fa-plus" })]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "card-header-search",
              fn: function() {
                return [
                  _c("search", {
                    staticStyle: { "margin-top": "30px" },
                    attrs: { listHandler: _vm.getList },
                    model: {
                      value: _vm.lastPage,
                      callback: function($$v) {
                        _vm.lastPage = $$v
                      },
                      expression: "lastPage"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "table-rows",
              fn: function() {
                return [
                  _vm._l(_vm.items, function(item, index) {
                    return _c(
                      "tr",
                      { key: item.id },
                      [
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "media align-items-center" },
                            [
                              _vm.getImageThumbnail(item)
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "avatar rounded-circle",
                                      attrs: { href: "javascript:;" }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          alt: "Image placeholder",
                                          src: _vm.getImageThumbnail(item)
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "media-body" }, [
                                _c("span", { staticClass: "name mb-0 text-sm" })
                              ])
                            ]
                          )
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(item.id) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.user.name) + " ")]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.supplierName) + " ")
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(item.city.name) + " ")]),
                        _c("td", [_vm._v(" " + _vm._s(item.user.email) + " ")]),
                        _c(
                          "td",
                          [
                            _c("input-checkbox", {
                              attrs: {
                                options: {
                                  id: "suspended",
                                  onChangeHandler: _vm.changeSuspendedStatus,
                                  onChangeParams: {
                                    index: index,
                                    id: item.id,
                                    isSuspended: !item.suspended
                                  },
                                  fullLayout: false,
                                  disabled: !_vm.can(
                                    _vm.permissionPrefix,
                                    "suspend"
                                  )
                                }
                              },
                              model: {
                                value: item.suspended,
                                callback: function($$v) {
                                  _vm.$set(item, "suspended", $$v)
                                },
                                expression: "item.suspended"
                              }
                            })
                          ],
                          1
                        ),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.deleted
                                  ? _vm.$t("labels.yes")
                                  : _vm.$t("labels.no")
                              ) +
                              " "
                          )
                        ]),
                        _c("td", { staticClass: "text-direction-ltr" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  item.createdAt,
                                  "Y-MM-DD hh:mm:ss A"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("table-item-actions", {
                          attrs: {
                            actions: _vm.tableItemActions,
                            params: { id: item.id }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "additional-items",
                                fn: function() {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            value: { animation: false },
                                            expression: "{ animation: false}",
                                            modifiers: { hover: true }
                                          },
                                          {
                                            name: "can",
                                            rawName: "v-can:add",
                                            value: "captain_envelopes",
                                            expression: "`captain_envelopes`",
                                            arg: "add"
                                          }
                                        ],
                                        staticClass: "text-success px-1",
                                        attrs: {
                                          to: {
                                            name: "envelopes.create",
                                            query: { captain_id: item.id }
                                          },
                                          title: _vm.$t(
                                            "envelopes.create_title"
                                          )
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-envelope"
                                        })
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  }),
                  !_vm.items.length
                    ? _c("tr", { staticClass: "text-center" }, [
                        _c("td", { attrs: { colspan: "999" } }, [
                          _vm._v(_vm._s(_vm.$t("messages.no_result_found")))
                        ])
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "card-footer",
              fn: function() {
                return [
                  _c("export-action", {
                    directives: [
                      {
                        name: "can",
                        rawName: "v-can:report",
                        value: _vm.permissionPrefix,
                        expression: "permissionPrefix",
                        arg: "report"
                      }
                    ],
                    attrs: { crudCode: _vm.routePrefix }
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }