import { render, staticRenderFns } from "./reject.vue?vue&type=template&id=ec5935fe&scoped=true&"
import script from "./reject.vue?vue&type=script&lang=js&"
export * from "./reject.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec5935fe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/brisk-frontend/brisk-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ec5935fe')) {
      api.createRecord('ec5935fe', component.options)
    } else {
      api.reload('ec5935fe', component.options)
    }
    module.hot.accept("./reject.vue?vue&type=template&id=ec5935fe&scoped=true&", function () {
      api.rerender('ec5935fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/withdraw_requests/actions/reject.vue"
export default component.exports