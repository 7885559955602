var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        "aria-hidden": "true",
        "aria-labelledby": "modal-countdown",
        id: "modal-countdown",
        role: "dialog",
        tabindex: "-1"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered modal-lg",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h6",
                {
                  staticClass: "modal-title",
                  attrs: { id: "modal-title-default" }
                },
                [_vm._v(_vm._s(_vm.$t("messages.countdown_title")))]
              ),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm.serverError
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                        ]),
                        _vm._v(" " + _vm._s(_vm.serverError) + " ")
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "col-12 text-center" }, [
                  _vm.loading
                    ? _c("span", { staticClass: "spinner-border" })
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "row justify-content-center" }, [
                !_vm.loading
                  ? _c("div", { staticClass: "col-12 col-lg-10" }, [
                      _c("h3", { staticClass: "text-blue mb-3" }, [
                        _vm._v(
                          _vm._s(_vm.$t("fields.total_wallet_balances")) + " : "
                        ),
                        _c("strong", [
                          _vm._v(" " + _vm._s(_vm.totalWalletBalances) + " ")
                        ])
                      ])
                    ])
                  : _vm._e(),
                !_vm.loading
                  ? _c("div", { staticClass: "col-12 col-lg-10" }, [
                      _c(
                        "div",
                        { staticClass: "alert bg-gradient-dark text-center" },
                        [
                          _c("h2", { staticClass: "text-white mb-0" }, [
                            _vm._v(
                              _vm._s(_vm.$t("fields.next_round_balance")) +
                                " : "
                            ),
                            _c("strong", [
                              _vm._v(" " + _vm._s(_vm.roundBalance) + " ")
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    !_vm.loading
                      ? _c("countdown", {
                          attrs: { time: _vm.getDiffDateInMilliseconds() },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var days = ref.days
                                  var hours = ref.hours
                                  var minutes = ref.minutes
                                  var seconds = ref.seconds
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "container flip-clock" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "flip-clock__piece",
                                            attrs: { id: "day" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flip-clock__card flip-card"
                                              },
                                              [
                                                _c(
                                                  "b",
                                                  {
                                                    staticClass:
                                                      "flip-card__top"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("twoDigits")(
                                                          days
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__bottom",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )(days)
                                                  }
                                                }),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__back",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )(days + 1)
                                                  }
                                                }),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__back-bottom",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )(days + 1)
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "flip-clock__slot"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("labels.days"))
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "flip-clock__piece",
                                            attrs: { id: "hours" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flip-clock__card flip-card"
                                              },
                                              [
                                                _c(
                                                  "b",
                                                  {
                                                    staticClass:
                                                      "flip-card__top"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("twoDigits")(
                                                          hours
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__bottom",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )(hours)
                                                  }
                                                }),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__back",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )((hours + 1) % 24)
                                                  }
                                                }),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__back-bottom",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )((hours + 1) % 24)
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "flip-clock__slot"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("labels.hours"))
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "flip-clock__piece",
                                            attrs: { id: "minutes" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flip-clock__card flip-card"
                                              },
                                              [
                                                _c(
                                                  "b",
                                                  {
                                                    staticClass:
                                                      "flip-card__top"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("twoDigits")(
                                                          minutes
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__bottom",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )(minutes)
                                                  }
                                                }),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__back",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )((minutes + 1) % 60)
                                                  }
                                                }),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__back-bottom",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )((minutes + 1) % 60)
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "flip-clock__slot"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("labels.minutes")
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "flip-clock__piece",
                                            attrs: { id: "seconds" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flip-clock__card flip-card"
                                              },
                                              [
                                                _c(
                                                  "b",
                                                  {
                                                    staticClass:
                                                      "flip-card__top"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("twoDigits")(
                                                          seconds
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__bottom",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )(seconds)
                                                  }
                                                }),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__back",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )(seconds + 1)
                                                  }
                                                }),
                                                _c("b", {
                                                  staticClass:
                                                    "flip-card__back-bottom",
                                                  attrs: {
                                                    "data-value": _vm._f(
                                                      "twoDigits"
                                                    )(seconds + 1)
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "flip-clock__slot"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("labels.seconds")
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1432449950
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-sm ml-auto",
                  attrs: { "data-dismiss": "modal", type: "button" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("labels.close")) + " ")]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }