var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        "aria-hidden": "true",
        "aria-labelledby": "modal-cancel-order",
        id: "modal-cancel-order",
        role: "dialog",
        tabindex: "-1"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h6",
                {
                  staticClass: "modal-title",
                  attrs: { id: "modal-title-default" }
                },
                [_vm._v(_vm._s(_vm.$t("messages.cancel_item")))]
              ),
              _vm._m(0)
            ]),
            _c(
              "form",
              {
                attrs: { role: "form" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.cancel($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm.serverError
                        ? _c("div", { staticClass: "alert alert-danger" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("labels.error")) + " : ")
                            ]),
                            _vm._v(" " + _vm._s(_vm.serverError) + " ")
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("select-order-cancellation-reasons", {
                        attrs: {
                          options: {
                            id: "order_cancellation_reason",
                            label: "reason",
                            params: Object.assign(
                              {},
                              _vm.activeParams,
                              _vm.additionalParams
                            ),
                            error: _vm.fieldErrors.reason,
                            containerClass: "col-12"
                          }
                        },
                        model: {
                          value: _vm.reason,
                          callback: function($$v) {
                            _vm.reason = $$v
                          },
                          expression: "reason"
                        }
                      }),
                      _vm.reasonNeedExplain()
                        ? _c("input-textarea", {
                            attrs: {
                              options: {
                                id: "explain",
                                transPrefix: "labels",
                                label: "explain",
                                error: _vm.fieldErrors.otherReason,
                                containerClass: "col-12"
                              }
                            },
                            model: {
                              value: _vm.explain,
                              callback: function($$v) {
                                _vm.explain = $$v
                              },
                              expression: "explain"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger btn-sm ml-auto",
                      attrs: { "data-dismiss": "modal", type: "button" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("labels.close")) + " ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      attrs: { type: "submit", disabled: _vm.loading }
                    },
                    [
                      _vm.loading
                        ? _c("span", {
                            staticClass: "spinner-border spinner-border-sm"
                          })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.$t("labels.submit")) + " ")
                    ]
                  )
                ])
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          "aria-label": "Close",
          "data-dismiss": "modal",
          type: "button"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }