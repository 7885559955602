var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-3" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("input-checkbox", {
                  attrs: {
                    options: {
                      id: "isTreemapView",
                      label: "modes.treemap",
                      transPrefix: "" + _vm.transPrefix,
                      containerClass: "col-12 bg-white rounded pt-2"
                    }
                  },
                  model: {
                    value: _vm.isTreemapView,
                    callback: function($$v) {
                      _vm.isTreemapView = $$v
                    },
                    expression: "isTreemapView"
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm.isTreemapView ? _c("TreemapMonitoringView") : _vm._e(),
      !_vm.isTreemapView ? _c("ClassicMonitoringView") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }